import React, { useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { translate as translateHOC } from 'react-admin';
import { Menu, MenuItem, Tooltip, IconButton } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import PropTypes from 'prop-types';
import packageJson from '../../package.json';

const MenuItemsDisconnected = ({ translate, userDetails, onClose }) => {
  const versionNb = packageJson.version;

  if (userDetails) {
    const { firstName, lastName } = userDetails;

    return (
      <>
        <MenuItem onClick={onClose}>{`${firstName} ${lastName}`}</MenuItem>
        <MenuItem onClick={onClose}>Version v{versionNb}</MenuItem>
        <MenuItem onClick={onClose} component={Link} to="/preferences">
          {translate('common.preferences')}
        </MenuItem>
      </>
    );
  }
  return <MenuItem>{versionNb}</MenuItem>;
};
MenuItemsDisconnected.propTypes = {
  userDetails: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  translate: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

const mapStateToProps = state => ({
  userDetails: state.currentUser.payload,
});

const MenuItems = compose(
  connect(mapStateToProps),
  translateHOC
)(MenuItemsDisconnected);

const VulogUserMenu = ({ translate, label, icon, logout, ...other }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = e => setAnchorEl(e.currentTarget);
  const open = Boolean(anchorEl);
  const handleClose = () => setAnchorEl(null);

  return (
    <div {...other}>
      <Tooltip title={translate(label)}>
        <IconButton
          aria-label={translate(label)}
          aria-owns={open ? 'menu-appbar' : null}
          aria-haspopup
          color="inherit"
          onClick={handleMenu}
        >
          {icon}
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItems onClose={handleClose} />
        {logout}
      </Menu>
    </div>
  );
};
VulogUserMenu.propTypes = {
  translate: PropTypes.func.isRequired,
  label: PropTypes.string,
  logout: PropTypes.node,
  icon: PropTypes.node,
};
VulogUserMenu.defaultProps = {
  label: 'ra.auth.user_menu',
  icon: <AccountCircle />,
};

export default translateHOC(VulogUserMenu);
