import React from 'react';
import PropTypes from 'prop-types';
import { TextField, SimpleShowLayout } from 'react-admin';
import Header from '../../../shared/header';

export const BillingAddress = ({ resource, record, classes }) => (
  <SimpleShowLayout resource={resource} record={record}>
    <Header label="common.null" />
    <TextField
      source="billingAddress.streetName"
      className={classes.field}
      classes={{ root: classes.fieldContent }}
      id="Selenium-businessDetails-Billing-Address-streetName"
    />
    <TextField
      source="billingAddress.postalCode"
      className={classes.field}
      classes={{ root: classes.fieldContent }}
      id="Selenium-businessDetails-Billing-Address-postalCode"
    />
    <TextField
      source="billingAddress.city"
      className={classes.field}
      classes={{ root: classes.fieldContent }}
      id="Selenium-businessDetails-Billing-Address-City"
    />
    <TextField
      source="billingAddress.country"
      className={classes.field}
      classes={{ root: classes.fieldContent }}
      id="Selenium-businessDetails-Billing-Address-Country"
    />
  </SimpleShowLayout>
);

BillingAddress.propTypes = {
  record: PropTypes.shape({}),
  classes: PropTypes.shape({
    field: PropTypes.string,
    fieldContent: PropTypes.string,
  }),
  resource: PropTypes.string,
};
