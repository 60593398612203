import { GET_LIST, UPDATE, GET_MANY, UPDATE_MANY, GET_ONE, CREATE } from 'react-admin';
import utils from '../../shared/utils';

const dataBusinessUser = (instance, type, params) => {
  const opt = {};
  switch (type) {
    case GET_MANY: {
      const { id } = utils.getParameters().business;
      const { userId } = utils.getParameters().businessTrip;
      const query = new URLSearchParams();
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${id}/user/${userId}`;
      opt.method = 'GET';
      opt.params = query;
      break;
    }
    case GET_LIST: {
      const { filter } = params;
      const { businessId } = filter;
      const query = new URLSearchParams();
      if (params.pagination) {
        query.append('page', params.pagination.page - 1);
        query.append('size', params.pagination.perPage);
      }
      query.append('role', params.filter.userRole ? params.filter.userRole : '');
      query.append('sort', params.sort ? params.sort.field + (params.sort.order === 'ASC' ? ',asc' : ',desc') : null);
      if (!(params.filter.status === '' || params.filter.status === undefined)) {
        query.append('status', params.filter.status.toUpperCase());
      }

      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${businessId}/user`;
      opt.method = 'GET';
      opt.params = query;
      break;
    }
    case UPDATE: {
      const { id } = utils.getParameters().business;
      const {
        data: { costCenterId, isDelegatedAdmin, profiles, profileStatus },
      } = params;
      const newCostCenter = costCenterId === '' ? null : costCenterId;
      const profile = profiles[0];
      const profileId = profile.id;
      const { emailConsent, email } = profile;
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${id}/user/${
        params.id
      }/profile/${profileId}`;
      opt.method = 'PUT';
      opt.data = { email, emailConsent, status: profileStatus, costCenterId: newCostCenter, isDelegatedAdmin };
      break;
    }
    case UPDATE_MANY: {
      switch (params.data.updateField) {
        case 'status':
          opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${
            params.data.businessId
          }/profile/user/profile/status/${params.data.status}`;
          opt.method = 'PUT';
          opt.data = params.ids;
          break;
        case 'role':
          opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${params.data.businessId}/user/${
            params.ids[0]
          }/delegatedAdmin`;
          opt.method = params.data.action === 'add' ? 'POST' : 'DELETE';
          break;
        default:
          throw new Error(`Unsupported Data Provider request UPDATE field ${params.data.updateField}`);
      }
      break;
    }
    case GET_ONE: {
      const { business } = utils.getParameters();
      if (business) {
        const { id } = utils.getParameters().business;
        opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${id}/user/${params.id}`;
      } else {
        opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${params.businessId}/user/${params.id}`;
      }
      opt.method = 'GET';
      break;
    }
    case CREATE: {
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${params.businessId}/user/${
        params.userId
      }/profile`;
      opt.method = 'POST';
      opt.data = {
        costCenterId: params.data.costCenterId,
        email: params.data.email,
        emailConsent: params.data.emailConsent,
        requestId: params.data.requestId,
      };
      break;
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
  return instance(opt);
};

export default dataBusinessUser;
