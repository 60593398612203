import { SET_CLIENT_SECRET, RESET_CLIENT_SECRET, RESET_STRIPE, RESET_SETUP_INTENT } from './constants';

const initialState = {
  pubKey: '',
  clientSecret: '',
  setupIntentId: '',
};

const stripeReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CLIENT_SECRET: {
      return {
        ...state,
        ...payload,
      };
    }

    case RESET_CLIENT_SECRET: {
      return {
        ...state,
        clientSecret: '',
      };
    }

    case RESET_SETUP_INTENT: {
      return {
        ...state,
        setupIntentId: '',
      };
    }

    case RESET_STRIPE: {
      return {
        ...state,
        clientSecret: initialState.clientSecret,
        pubKey: initialState.pubKey,
      };
    }
    default:
      return state;
  }
};

export default stripeReducer;
