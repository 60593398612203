import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  fetchStart,
  fetchEnd,
  UPDATE,
  Edit,
  refreshView,
  showNotification,
  SimpleForm,
  TextInput,
  translate as translateHOC,
  withDataProvider,
} from 'react-admin';
import { submit, isSubmitting as isSubmittingAction, reset } from 'redux-form';
import { connect } from 'react-redux';
import { withStyles, Dialog, DialogTitle, DialogContent, CircularProgress, DialogActions } from '@material-ui/core';
import IconSave from '@material-ui/icons/Save';
import IconCancel from '@material-ui/icons/Cancel';
import { maxLength, required, name, stringValue } from '../../core/validators';

const styles = {
  suggestionsContainerOpen: {
    zIndex: 2100,
  },
  field: {
    float: 'left',
    width: '28%',
    minWidth: '200px',
    marginRight: '5%',
    height: '90px',
  },
  fieldContent: {
    width: '100%',
    marginRight: '0',
  },
  block: {
    width: 'fit-content',
  },
  actions: {
    float: 'right',
  },
  largeButton: {
    float: 'left',
    width: '40%',
    marginRight: '5%',
  },
  fullWidth: {
    width: '100%',
  },
  commentWidth: {
    width: '70%',
  },
  elevation1: {
    boxShadow: 'none',
  },
  simpleForm: {
    '& > div': {
      padding: '0 !important',
    },
  },
  dialogActionsRoot: {
    marginRight: '20px',
  },
};

const SaveButton = ({ isSubmitting, handleSaveClick, className, disabled }) => (
  <Button
    disabled={!!disabled}
    variant="raised"
    onClick={handleSaveClick}
    label="common.save"
    size="medium"
    color={isSubmitting ? 'default' : 'primary'}
    className={className}
    id="Selenium-Business-CostCenter-Edit-Save-Button"
  >
    {isSubmitting ? <CircularProgress size={25} thickness={2} /> : <IconSave />}
  </Button>
);

SaveButton.propTypes = {
  isSubmitting: PropTypes.bool,
  className: PropTypes.string,
  handleSaveClick: PropTypes.func,
  disabled: PropTypes.bool,
};

const CancelButton = ({ handleCancelClick }) => (
  <Button
    onClick={handleCancelClick}
    label="common.cancel"
    size="medium"
    id="Selenium-Business-CostCenter-Edit-Cancel-Button"
  >
    <IconCancel />
  </Button>
);

const CostCenterEditToolbar = ({ isSubmitting, handleSaveClick, handleCancelClick, classes, invalid }) => (
  <DialogActions classes={{ root: classes.dialogActionsRoot }}>
    <CancelButton handleCancelClick={handleCancelClick} />
    <SaveButton
      disabled={!!invalid}
      isSubmitting={isSubmitting}
      handleSaveClick={handleSaveClick}
      className={classes.saveButton}
    />
  </DialogActions>
);

CostCenterEditToolbar.propTypes = {
  isSubmitting: PropTypes.bool,
  handleCancelClick: PropTypes.func,
  handleSaveClick: PropTypes.func,
  classes: PropTypes.shape({}),
  invalid: PropTypes.bool,
};

const DummyToolbar = ({ invalid, forwardInvalid }) => {
  forwardInvalid(invalid);
  return null;
};

class CostCenterEdit extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      isFormInvalid: true,
    };
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(reset('cost-center-edit'));
  }

  onSubmit = record => {
    const clonedRecord = {
      name: record.name.trim(),
      value: record.value.trim(),
    };
    const { closeDialog, dataProvider, dispatch, id } = this.props;
    dispatch(fetchStart());
    return dataProvider(UPDATE, 'costCenter', { data: { ...clonedRecord, id } })
      .then(() => {
        dispatch(showNotification('notification.costCenter.updateSuccess'));
        closeDialog();
        dispatch(refreshView());
      })
      .catch(err => {
        const errObject = JSON.parse(err.message);

        const { errorCode, httpStatusCode, httpStatus } = errObject;

        console.error(`Error: ${httpStatusCode}`, {
          httpStatusCode,
          httpStatus,
          errorCode,
        });
        dispatch(showNotification(`error.${errorCode}`, 'warning'));
      })
      .finally(dispatch(fetchEnd()));
  };

  edit = () => {
    const { dispatch } = this.props;
    dispatch(submit('cost-center-edit'));
  };

  render() {
    const {
      dispatch,
      translate,
      classes,
      permissions,
      isSubmitting,
      dataProvider,
      isShowDialog,
      closeDialog,
      ...props
    } = this.props;

    const { isFormInvalid } = this.state;

    const closeAndResetDialog = () => {
      dispatch(reset('cost-center-edit'));
      closeDialog();
    };

    return (
      <Dialog maxWidth="sm" fullWidth open={isShowDialog} onClose={closeAndResetDialog}>
        <DialogTitle>{translate('resources.costCenter.edit.title')}</DialogTitle>
        <DialogContent>
          <Edit title=" " classes={{ card: classes.elevation1 }} {...props}>
            <SimpleForm
              className={classes.simpleForm}
              toolbar={<DummyToolbar forwardInvalid={s => this.setState({ isFormInvalid: s })} />}
              form="cost-center-edit"
              onSubmit={this.onSubmit}
            >
              <TextInput
                source="name"
                validate={[required, name, maxLength(64, translate('validation.maxLength', { max: 64 }))]}
                required
                className={classes.fullWidth}
                id="Selenium-Business-CostCenter-Edit-Name-Input"
              />
              <TextInput
                source="value"
                validate={[required, stringValue, maxLength(64, translate('validation.maxLength', { max: 64 }))]}
                required
                className={classes.fullWidth}
                id="Selenium-Business-CostCenter-Edit-Value-Input"
              />
            </SimpleForm>
          </Edit>
        </DialogContent>
        <CostCenterEditToolbar
          handleSaveClick={this.edit}
          handleCancelClick={closeAndResetDialog}
          classes={classes}
          isSubmitting={isSubmitting}
          invalid={isFormInvalid}
        />
      </Dialog>
    );
  }
}

CancelButton.propTypes = {
  handleCancelClick: PropTypes.func,
};

CostCenterEdit.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.shape({}),
  closeDialog: PropTypes.func,
  dataProvider: PropTypes.func,
  dispatch: PropTypes.func,
  history: PropTypes.shape({}),
  id: PropTypes.string,
  isShowDialog: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  permissions: PropTypes.string,
  record: PropTypes.shape({}),
  resource: PropTypes.string,
  translate: PropTypes.func,
};

const mapStateToProps = state => ({
  isSubmitting: isSubmittingAction('cost-center-edit')(state),
});

export default withDataProvider(withStyles(styles)(translateHOC(connect(mapStateToProps)(CostCenterEdit))));
export const CostCenterEditTest = CostCenterEdit;
