import React, { useEffect } from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TextField, EmailField, SimpleShowLayout, FunctionField, translate as translateHOC } from 'react-admin';
import Header from '../../../shared/header';
import { getBusinessConf } from '../../../core/config';
import { loadAllServices } from '../../../core/services';
import ChipsArray from '../../../shared/ChipsArray';
import { associatedServicesSelector } from '../../../core/selectors';
import { isPrivateServicesActive } from '../../../helpers';

const BusinessDetailsUnconnected = ({ resource, privateFleetOptions, record, classes, translate, isAdmin }) => {
  const { config } = getBusinessConf();

  useEffect(() => {
    loadAllServices();
  }, []);

  const taxNumberCountry = config && config.taxNumberEnable && (
    <TextField
      source="taxNumberCountry"
      className={classes.field}
      classes={{ root: classes.fieldContent }}
      id="Selenium-businessDetails-taxNumberCountry"
    />
  );

  const communityTaxNumber = config && config.taxNumberEnable && (
    <TextField
      source="communityTaxNumber"
      className={classes.field}
      classes={{ root: classes.fieldContent }}
      id="Selenium-businessDetails-billingGroup-communityTaxNumber"
    />
  );

  const privateFleets = isAdmin && isPrivateServicesActive(config) && (
    <ChipsArray
      className={classes.field}
      classes={{
        root: classes.chipsArrayRoot,
        chipsContainer: classes.chipsArrayContainer,
      }}
      data={privateFleetOptions}
      label={translate('resources.business.fields.privateFleetsAvailable')}
    />
  );

  return (
    <SimpleShowLayout resource={resource} record={record}>
      <Header label="resources.business.businessDetails" id="Selenium-businessDetails-Title" />
      <TextField
        source="name"
        className={classes.field}
        classes={{ root: classes.fieldContent }}
        id="Selenium-businessDetails-Name"
      />
      <TextField
        source="legalName"
        className={classes.field}
        classes={{ root: classes.fieldContent }}
        id="Selenium-businessDetails-legalName"
      />
      <TextField
        source="phoneNumber"
        className={classes.field}
        classes={{ root: classes.fieldContent }}
        id="Selenium-businessDetails-phoneNumber"
      />
      <TextField
        source="faxNumber"
        className={classes.field}
        classes={{ root: classes.fieldContent }}
        id="Selenium-businessDetails-faxNumber"
      />
      <EmailField
        source="email"
        className={classes.field}
        classes={{ root: classes.fieldContent }}
        id="Selenium-businessDetails-Email"
      />
      <TextField
        source="vat"
        className={classes.field}
        classes={{ root: classes.fieldContent }}
        id="Selenium-businessDetails-Vat"
      />
      <TextField
        source="ein"
        className={classes.field}
        classes={{ root: classes.fieldContent }}
        id="Selenium-businessDetails-Ein"
      />
      <TextField
        source="sdicemCode"
        label="SDI / PEC"
        className={classes.field}
        classes={{ root: classes.fieldContent }}
        id="Selenium-businessDetails-sdicemCode"
      />
      <TextField
        source="billingGroup.name"
        className={classes.field}
        classes={{ root: classes.fieldContent }}
        id="Selenium-businessDetails-billingGroup-Name"
      />
      <TextField
        source="businessOwner.fullName"
        className={classes.field}
        classes={{ root: classes.fieldContent }}
        id="Selenium-businessDetails-businessOwner-fullName"
      />
      <TextField
        source="status"
        className={classes.field}
        classes={{ root: classes.fieldContent }}
        id="Selenium-businessDetails-Status"
      />
      {taxNumberCountry}
      {communityTaxNumber}
      {privateFleets}
      <FunctionField
        label="resources.business.fields.locale"
        className={classes.field}
        id="Selenium-businessDetails-Language"
        render={ref =>
          config.translation.invoiceLanguage &&
          config.translation.invoiceLanguage.find(language => language.code === ref.locale)
            ? config.translation.invoiceLanguage.find(language => language.code === ref.locale).label
            : ref.locale
        }
      />
      <TextField
        source="invoiceFrequency"
        className={classes.field}
        classes={{ root: classes.fieldContent }}
        id="Selenium-businessDetails-invoiceFrequency"
      />
    </SimpleShowLayout>
  );
};

BusinessDetailsUnconnected.propTypes = {
  record: PropTypes.shape({}),
  classes: PropTypes.shape({
    field: PropTypes.string,
    fieldContent: PropTypes.string,
  }),
  resource: PropTypes.string,
  privateFleetOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  translate: PropTypes.func,
  isAdmin: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  privateFleetOptions: associatedServicesSelector(state).reduce((acc, service) => {
    if (service.visibility === 'PRIVATE') {
      acc.push({ name: service.name, id: service.id });
    }
    return acc;
  }, []),
});

export const BusinessDetails = compose(
  connect(mapStateToProps),
  translateHOC
)(BusinessDetailsUnconnected);

export const BusinessDetailsTest = BusinessDetailsUnconnected;
