import React from 'react';
import PropTypes from 'prop-types';
import { Field, propTypes, reduxForm, reset } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { translate as translateHOC, userLogin } from 'ra-core';

const styles = () =>
  createStyles({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    hint: {
      marginTop: '1em',
      display: 'flex',
      justifyContent: 'center',
    },
  });

// see http://redux-form.com/6.4.3/examples/material-ui/
const renderInput = ({
  meta: { touched, error } = { touched: false, error: '' }, // eslint-disable-line react/prop-types
  input: { ...inputProps }, // eslint-disable-line react/prop-types
  ...props
}) => <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />;

const login = (authPayload, dispatch, { redirectTo }) => {
  dispatch(userLogin(authPayload, redirectTo));
};

const checkPinCode = (authPayload, dispatch) => {
  dispatch({ type: 'CHECK_PIN_CODE_2FA', payload: authPayload });
  dispatch(reset('signIn'));
};

const LoginForm = ({ classes, isLoading, handleSubmit, translate, login2FAData, changeAccount }) => (
  <div>
    {!login2FAData.payload && (
      <form onSubmit={handleSubmit(login)}>
        <div className={classes.hint}>{translate('login.welcome')}</div>
        <div className={classes.form}>
          <div className={classes.input}>
            <Field
              autoFocus
              id="Selenium-Login-Username-Input"
              name="username"
              component={renderInput}
              label={translate('login.username')}
              autoComplete="username"
              disabled={isLoading}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div className={classes.input}>
            <Field
              id="Selenium-Login-Password-Input"
              name="password"
              component={renderInput}
              label={translate('login.password')}
              type="password"
              autoComplete="current-password"
              disabled={isLoading}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>
        <CardActions>
          <Button
            variant="raised"
            type="submit"
            color="primary"
            disabled={isLoading}
            className={classes.button}
            id="Selenium-Login-Submit-Button"
          >
            {isLoading && <CircularProgress size={25} thickness={2} />}
            {translate('login.signin')}
          </Button>
        </CardActions>
      </form>
    )}
    {login2FAData && login2FAData.payload && (
      <form onSubmit={handleSubmit(checkPinCode)}>
        <div className={classes.hint}>{translate('login.insertPinCode')}</div>
        <div className={classes.form}>
          <div className={classes.input}>
            <Field
              autoFocus
              id="Selenium-Login-Username-Pincode"
              name="pincode"
              component={renderInput}
              label={translate('login.pincode')}
              disabled={isLoading}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </div>
        <CardActions>
          <Button
            variant="raised"
            type="button"
            color="primary"
            disabled={isLoading}
            className={classes.button}
            id="Selenium-Login-Back-Button"
            onClick={changeAccount}
          >
            {translate('login.back')}
          </Button>
          <Button
            variant="raised"
            type="submit"
            color="primary"
            disabled={isLoading}
            className={classes.button}
            id="Selenium-Login-Submit-Button"
          >
            Submit
          </Button>
        </CardActions>
      </form>
    )}
  </div>
);

LoginForm.propTypes = {
  ...propTypes,
  classes: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  isLoading: state.admin.loading > 0,
  login2FAData: state.login2FA,
});

const mapDispatchToProps = dispatch => ({
  changeAccount: () => dispatch({ type: 'CHANGE_ACCOUNT_2FA' }),
});

const enhance = compose(
  withStyles(styles),
  translateHOC,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  reduxForm({
    form: 'signIn',
    validate: (values, props) => {
      const errors = {};
      const { translate, pincodeLength } = props;
      if (!values.username) errors.username = translate('validation.required');
      if (!values.password) errors.password = translate('validation.required');
      if (values.pincode && (!parseInt(values.pincode, 10) || values.pincode.length !== parseInt(pincodeLength, 10)))
        errors.pincode = translate('login.pincodeValidationMsg', { pincodeLength });
      return errors;
    },
    enableReinitialize: true,
  })
);

export default enhance(LoginForm);
