import { GET_ONE, GET_MANY_REFERENCE } from 'react-admin';

const dataBusinessTrip = (instance, type, params) => {
  const opt = {};
  switch (type) {
    case GET_ONE: {
      const { id } = params;
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/trip/${id}`;
      opt.method = 'GET';
      break;
    }
    case GET_MANY_REFERENCE: {
      const query = new URLSearchParams();
      if (params.pagination) {
        query.append('page', params.pagination.page - 1);
        query.append('size', params.pagination.perPage);
      }
      query.append('business', params.id);
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/trip/ongoing`;
      opt.method = 'GET';
      opt.params = query;
      break;
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
  return instance(opt);
};

export default dataBusinessTrip;
