import {
  number as createNumberValidator,
  email as createEmailValidator,
  maxLength as createMaxLengthValidator,
  minLength as createMinLengthValidator,
} from 'react-admin';
import moment from 'moment';
import XRegExp from 'xregexp';

export const number = createNumberValidator();
export const email = createEmailValidator();
export const maxLength = createMaxLengthValidator;
export const minLength = createMinLengthValidator;

export const positive = (value, allValues, props) => {
  if (value < 0) {
    return props.translate('validation.positive');
  }
  return undefined;
};

export const required = (value, allValues, props) => {
  if (!value || (value && !value.trim())) {
    return props.translate('validation.required');
  }
  return undefined;
};

export const requiredNumber = (value, allValues, props) => {
  if (!value) {
    return props.translate('validation.required');
  }
  return undefined;
};

export const integer = (value, allValues, props) => {
  if (!Number.isInteger(value)) {
    return props.translate('validation.integer');
  }
  return undefined;
};

/**
 * Validates a human name by checking if it matches the specified regular expression pattern.
 *
 * @param {string} value - The value to be validated.
 * @param {object} allValues - An object containing all form values.
 * @param {object} props - Additional properties or options.
 * @returns {string|undefined} - Returns an error message if the name is invalid, otherwise returns undefined.
 *
 * @example
 * // Valid name
 * const validName = humanName("John Doe", {}, {});
 * console.log(validName); // undefined
 *
 * @example
 * // Invalid name
 * const invalidName = humanName("John123", {}, {});
 * console.log(invalidName); // "Please enter a valid name."
 *
 * @see {@link https://xregexp.com/|XRegExp} for more information about the regular expression library used.
 *
 * @description
 * The regular expression used to validate the name allows for names that consist of one or more parts, where each part:
 * - Starts with one or more Unicode letters (any language) represented by the `\\p{L}` character class.
 * - Can be followed by an optional hyphen, apostrophe, or space represented by the `[-'\\s]?` pattern.
 *
 * This regular expression pattern allows for composed names, names with spaces, and names with apostrophes.
 * It does not allow names with numbers or other non-letter characters, except for the allowed hyphen, apostrophe, or space.
 */
export const humanName = (value, allValues, props) => {
  const regex = XRegExp("^(\\p{L}+[-'\\s]?)+$");

  if (value && value !== '' && !regex.test(value)) {
    return props.translate('validation.humanName');
  }
  return undefined;
};

export const name = (value, allValues, props) => {
  const regex = /^[a-zA-Z].*/;
  if (value && value !== '' && !regex.test(value)) {
    return props.translate('validation.name');
  }
  return undefined;
};

export const stringValue = (value, allValues, props) => {
  const regex = /^[a-zA-Z0-9].*/;
  if (value && value !== '' && !regex.test(value)) {
    return props.translate('validation.value');
  }
  return undefined;
};

export const url = (value, allValues, props) => {
  const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/;
  if (value && value !== '' && !regex.test(value)) {
    return props.translate('validation.url');
  }
  return undefined;
};

export const future = (value, allValues, props) => {
  if (moment().isAfter(value)) {
    return props.translate('validation.future');
  }
  return undefined;
};

export const validVat = (value, allValues, props) => {
  const regex = /^[a-zA-Z][a-zA-Z].*/;
  if (value && value !== '' && !regex.test(value)) {
    return props.translate('validation.invalidVat');
  }
  return undefined;
};
