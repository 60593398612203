import React from 'react';
import PropTypes from 'prop-types';
import { FunctionField } from 'react-admin';
import utils from '../utils';

const SimpleMultiTextField = ({ record = {}, sources = [], separator = ' ', ...other }) => {
  const combinedTextArray = [];

  for (let i = 0; i < sources.length; i += 1) {
    const field = sources[i];
    const text = utils.index(record, field);
    if (text || text === 0) {
      combinedTextArray.push(text);
    } else {
      combinedTextArray.push(field);
    }
  }

  const combinedText = combinedTextArray.join(separator);
  return <FunctionField id={`Selenium-multiField-${sources.join('.')}`} render={() => combinedText} {...other} />;
};

SimpleMultiTextField.propTypes = {
  type: PropTypes.string,
  record: PropTypes.shape({}),
  sources: PropTypes.arrayOf(PropTypes.string),
  separator: PropTypes.string,
};

export default SimpleMultiTextField;
