import React from 'react';
import PropTypes from 'prop-types';
import { TextField, SimpleShowLayout } from 'react-admin';
import Header from '../../../shared/header';

export const BusinessAddress = ({ resource, record, classes }) => (
  <SimpleShowLayout resource={resource} record={record}>
    <Header label="resources.business.businessAddress" />
    <TextField
      source="address.streetName"
      className={classes.field}
      classes={{ root: classes.fieldContent }}
      id="Selenium-businessDetails-Business-Address-streetName"
    />
    <TextField
      source="address.postalCode"
      className={classes.field}
      classes={{ root: classes.fieldContent }}
      id="Selenium-businessDetails-Business-Address-postalCode"
    />
    <TextField
      source="address.city"
      className={classes.field}
      classes={{ root: classes.fieldContent }}
      id="Selenium-businessDetails-Business-Address-City"
    />
    <TextField
      source="address.country"
      className={classes.field}
      classes={{ root: classes.fieldContent }}
      id="Selenium-businessDetails-Business-Address-Country"
    />
  </SimpleShowLayout>
);

BusinessAddress.propTypes = {
  record: PropTypes.shape({}),
  classes: PropTypes.shape({
    field: PropTypes.string,
    fieldContent: PropTypes.string,
  }),
  resource: PropTypes.string,
};
