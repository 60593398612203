import { GET_PAYMENT_DETAILS, SET_PAYMENT_DETAILS } from './constants';

const initialState = {};

const paymentDetailsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PAYMENT_DETAILS:
      return {
        ...state,
      };

    case SET_PAYMENT_DETAILS:
      return {
        ...state,
        ...payload,
      };

    default:
      return state;
  }
};

export default paymentDetailsReducer;
