import * as PropTypes from 'prop-types';
import React from 'react';
import { CardActions, CreateButton, List, Pagination } from 'react-admin';
import CustomDatagrid from '../custom-datagrid';

const CustomPagination = ({ rowPerPage, ...props }) => (
  <Pagination rowsPerPageOptions={rowPerPage || [10, 25, 50, 100]} {...props} />
);

CustomPagination.propTypes = {
  rowPerPage: PropTypes.arrayOf(PropTypes.number),
};

const DataActions = ({ basePath, permissions }) => (
  <CardActions>
    {permissions === 'admin' && basePath.toLowerCase() === '/business' ? <CreateButton basePath={basePath} /> : null}
  </CardActions>
);

DataActions.propTypes = {
  basePath: PropTypes.string,
  permissions: PropTypes.string,
};

class CustomList extends React.PureComponent {
  render() {
    const { fields, defaultTab, filterColumn, hasEdit, rowPerPage } = this.props;
    const listProps = { ...this.props };
    const datagridProps = { ...this.props };
    ['defaultTab', 'filterColumn', 'defaultColumns', 'filterTabs'].forEach(propName => {
      delete listProps[propName];
    });

    return (
      <List
        {...listProps}
        fields={fields}
        filterDefaultValues={{ [filterColumn]: defaultTab }}
        actions={<DataActions {...this.props} />}
        exporter={false}
        pagination={<CustomPagination rowPerPage={rowPerPage} />}
        debounce={50}
        bulkActionButtons={false}
      >
        <CustomDatagrid {...datagridProps} rowClick={hasEdit ? 'edit' : null}>
          {fields}
        </CustomDatagrid>
      </List>
    );
  }
}

CustomList.propTypes = {
  title: PropTypes.string,
  defaultTab: PropTypes.string,
  sort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  filterColumn: PropTypes.string,
  hasEdit: PropTypes.bool,
  rowPerPage: PropTypes.arrayOf(PropTypes.number),
};

CustomList.defaultProps = {
  fields: [],
};

export default CustomList;
