import React, { createContext } from 'react';
import PropTypes from 'prop-types';

/**
 * Use the hook useContext(LocaleContext) to retrieve the object
 */
export const LocaleContext = createContext({});

/**
 * Surround the component with <LocaleProvider availableLocales={localesArray}></LocaleProvider>
 */
export const LocaleProvider = ({ availableLocales, children }) => (
  <LocaleContext.Provider value={{ availableLocales }}>{children}</LocaleContext.Provider>
);

LocaleProvider.propTypes = {
  availableLocales: PropTypes.arrayOf(PropTypes.string).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};
