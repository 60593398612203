import { GET_PAYMENT_DETAILS, SET_PAYMENT_DETAILS } from './constants';

export const getPaymentDetails = () => ({
  type: GET_PAYMENT_DETAILS,
});

export const setPaymentDetails = payload => ({
  type: SET_PAYMENT_DETAILS,
  payload,
});
