import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import Avatar from '@material-ui/core/Avatar';
import { MainColors } from '../colors';

const style = () => ({
  chiplabel: {
    minWidth: 60,
    color: 'black',
    textTransform: 'uppercase',
    padding: '0 20px',
  },
  chipAvatar: {
    '& svg': {
      width: 22,
      height: 22,
      color: 'white',
    },
  },
  colorRed: { backgroundColor: MainColors.red },
  colorBlue: { backgroundColor: MainColors.blue },
  colorGreen: { backgroundColor: MainColors.green },
  colorYellow: { backgroundColor: MainColors.yellow },
  colorLightGrey: { backgroundColor: MainColors.lightGrey },
});

const StatusChip = ({ classes, label, color, icon }) => (
  <Chip
    label={label || '-'}
    color="primary"
    classes={{
      colorPrimary: classes.colorLightGrey,
      avatarColorPrimary: classes[color] || classes.colorBlue,
      label: classes.chiplabel,
    }}
    avatar={<Avatar className={classes.chipAvatar}>{icon}</Avatar>}
  />
);

StatusChip.propTypes = {
  classes: PropTypes.shape({}),
  label: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.node,
};

export default withStyles(style)(StatusChip);
