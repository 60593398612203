export const MainColors = {
  blue: '#038FDE',
  clearBlue: '#55ACEE',
  green: '#5DAC47',
  orange: '#FAA12B',
  red: '#F14732',
  grey: '#A0A0A0',
  lightGrey: '#EFF1F5',
  purple: '#AD00AD',
  yellow: '#F1C40F',
};
