import axios from 'axios';
import { getBusinessConf } from './config';
import utils from '../shared/utils';

// Load grants from server and store in store
async function load() {
  const { config } = getBusinessConf();
  let token = '';
  const accessToken = sessionStorage.getItem('accessToken');
  const fleetId = localStorage.getItem('fleetID');
  if (accessToken) {
    ({ token } = JSON.parse(accessToken));
  } else {
    return Promise.reject();
  }
  const instance = axios.create({
    baseURL: config.business_ws.baseUrl,
    timeout: config.timeout || 30000,
    headers: {
      Accept: 'application/json',
      'X-API-KEY': config.login.x_api_key,
      Authorization: `Bearer ${token}`,
    },
  });
  const opt = {
    url: `/business/fleets/${fleetId}/setting/role/grant`,
    method: 'GET',
  };
  return instance(opt).then(res => {
    const { dispatch } = utils.getStore();
    dispatch({ type: 'SAVE_GRANTS', payload: res.data });
  });
}

export default { load };
