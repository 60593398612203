import {
  GET_LIST,
  GET_ONE,
  CREATE,
  UPDATE,
  DELETE,
  GET_MANY,
  UPDATE_MANY,
  DELETE_MANY,
  GET_MANY_REFERENCE,
} from 'react-admin';
import dataResponseBillingTrips from './data-billing-trips';
import dataResponseBillingProducts from './data-billing-products';
import dataResponseBusinessInvoice from './data-business-invoice';
import dataResponseBusiness from './data-business';
import dataResponseBusinessTrip from './data-business-trip';
import dataResponseBillingGroup from './data-billing-group';
import dataResponseBusinessOwner from './data-business-owner';
import dataResponseCostCenter from './data-cost-center';
import dataResponseBusinessUser from './data-business-user';
import dataResponseAdminSettings from './data-admin-settings';
import dataResponseUser from './data-user';
import dataResponseProduct from './data-product';
import utils from '../../shared/utils';

// Handling data return from backend and convert it to react-admin readable data
const dataResponseConverter = (res, params, resource, type) => {
  let { data } = res;
  // Automatically assign ID in case the results dont have it...
  if (Object.prototype.toString.call(data) === '[object String]') {
    data = {};
  }
  if (resource !== 'product' && type !== UPDATE_MANY) {
    data = utils.deepAssignId(data);
  }
  if (!(data instanceof Array)) {
    switch (type) {
      case GET_LIST:
      case GET_MANY_REFERENCE:
        data = Object.assign(data, {
          data: data.content ? data.content : [],
          total: data.totalElements ? data.totalElements : 0,
        });
        break;
      case GET_MANY:
        data = Object.assign(data, {
          data: data.content ? data.content : [],
        });
        break;
      case UPDATE_MANY:
      case DELETE_MANY:
        data = Object.assign(data, {
          data: data.content ? data.content : [],
        });
        break;
      case GET_ONE:
      case UPDATE:
      case DELETE:
        if (params.id) {
          data.id = params.id;
        }
        data = Object.assign(res, {
          data,
        });
        break;
      case CREATE:
        data = Object.assign(res, {
          data,
        });
        break;
      default:
        break;
    }
    // Remove irrelevent fields
    delete data.content;
    delete data.totalElements;
  }
  // TODO: Specific resource converter goes here
  switch (resource) {
    case 'business':
      data = dataResponseBusiness(data, params, type);
      break;
    case 'businessOwner':
      data = dataResponseBusinessOwner(data, params, type);
      break;
    case 'businessTrip':
      data = dataResponseBusinessTrip(data, params, type);
      break;
    case 'billingTrips':
      data = dataResponseBillingTrips(data, params, type);
      break;
    case 'billingProducts':
      data = dataResponseBillingProducts(data, params, type);
      break;
    case 'businessInvoice':
      data = dataResponseBusinessInvoice(data, params, type);
      break;
    case 'billingGroup':
    case 'billingOwner':
      data = dataResponseBillingGroup(data, params, type);
      break;
    case 'costCenter':
      data = dataResponseCostCenter(data, params, type);
      break;
    case 'product':
      data = dataResponseProduct(data, params, type);
      break;
    case 'businessUser':
      data = dataResponseBusinessUser(data, params, type);
      break;
    case 'settings':
      data = dataResponseAdminSettings(data, params, type);
      break;
    case 'user':
      data = dataResponseUser(data, params, type);
      break;
    default:
      break;
  }
  return data;
};

export default dataResponseConverter;
