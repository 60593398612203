import { SET_FLEET_CONFIG } from './constants';

export default (state = {}, { type, payload }) => {
  switch (type) {
    case SET_FLEET_CONFIG: {
      const { timeZone: timezone } = payload;
      return {
        ...state,
        timezone,
      };
    }

    default:
      return state;
  }
};
