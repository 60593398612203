import axios from 'axios';
import utils from '../shared/utils';

let businessConf = null;
export const getBusinessConf = () => {
  if (!businessConf) {
    return axios({
      url: `${utils.getRootPath()}/conf`,
      method: 'GET',
    }).then(res => {
      businessConf = res.data;
      return res.data;
    });
  }
  return businessConf;
};

export const getFleetConf = async () => {
  let token = '';
  const accessToken = sessionStorage.getItem('accessToken');
  if (accessToken) {
    ({ token } = JSON.parse(accessToken));
  } else {
    return Promise.reject();
  }
  const instance = axios.create({
    baseURL: businessConf.config.business_ws.baseUrl,
    timeout: businessConf.config.timeout || 30000,
    headers: {
      Accept: 'application/json',
      'X-API-KEY': businessConf.config.login.x_api_key,
      Authorization: `Bearer ${token}`,
    },
  });

  const fleetId = localStorage.getItem('fleetID');
  const url = `/business/fleets/${fleetId}/conf`.replace(/\/{2,}/g, '/');

  try {
    const { data } = await instance(url);

    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};
