import { GET_ONE } from 'react-admin';

const dataBusinessUser = (res, params, type) => {
  switch (type) {
    case GET_ONE: {
      const { data } = res;
      const { profiles } = data;
      const { costCenter } = data;
      if (costCenter) {
        data.costCenterId = costCenter.id;
      }
      if (profiles) {
        data.profileStatus = profiles[0].status;
        //
        res.data = data;
      }
      break;
    }
    case 'GET_MANY': {
      res.data = [res];
      break;
    }
    default:
      break;
  }
  return res;
};

export default dataBusinessUser;
