import React from 'react';
import { connect } from 'react-redux';
import CreditsListAdmin from './admin';
import CreditsListOwner from './owner';
import utils from '../../../shared/utils';

class CreditsList extends React.PureComponent {
  render() {
    const { id } = utils.getParameters().business;
    const role = utils.getRoles(id);
    return (
      <React.Fragment>
        {role === 'admin' ? <CreditsListAdmin {...this.props} /> : <CreditsListOwner {...this.props} />}
      </React.Fragment>
    );
  }
}

export default connect(null)(CreditsList);
