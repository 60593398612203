import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  dividerTopWithoutBorder: {
    float: 'left',
    width: '100%',
    height: '4px',
  },
  dividerTop: {
    float: 'left',
    width: '100%',
    height: '4px',
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  dividerBottom: {
    float: 'left',
    width: '100%',
    height: '12px',
  },
});

const Divider = ({ classes, hasNoBorder }) => (
  <React.Fragment>
    {hasNoBorder ? <div className={classes.dividerTopWithoutBorder} /> : <div className={classes.dividerTop} />}
    <div className={classes.dividerBottom} />
  </React.Fragment>
);

Divider.propTypes = {
  classes: PropTypes.shape({}),
  hasNoBorder: PropTypes.bool,
};

export default withStyles(styles)(connect(null)(Divider));
