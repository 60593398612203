import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import {
  DELETE,
  ReferenceManyField,
  CardActions,
  Datagrid,
  TextField,
  FunctionField,
  refreshView,
  translate as translateHOC,
  withDataProvider,
} from 'react-admin';
import {
  Button,
  withStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import ActionCheck from '@material-ui/icons/CheckCircle';
import IconCreate from '@material-ui/icons/Add';
import IconEdit from '@material-ui/icons/Edit';
import IconDelete from '@material-ui/icons/Delete';
import CostCenterCreate from './create';
import CostCenterEdit from './edit';
import Divider from '../../shared/divider';

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
  iconPaddingStyle: {
    display: 'none',
  },
});

const CostCenterActions = compose(
  translateHOC,
  withStyles(styles)
)(({ openCreateDialog, translate, classes }) => (
  <CardActions>
    <Divider hasNoBorder />
    <Button color="secondary" onClick={openCreateDialog} size="medium" id="Selenium-Business-CostCenter-Create-Button">
      <IconCreate className={classes.leftIcon} />
      {translate('common.create')}
    </Button>
  </CardActions>
));

CostCenterActions.propTypes = {
  openCreateDialog: PropTypes.func,
  record: PropTypes.shape({}),
};

class CostCenterList extends React.PureComponent {
  state = {
    isShowConfirmDeleteDialog: false,
    isShowCreateDialog: false,
    isShowEditDialog: false,
    deletingCostCenterId: null,
    selectedCostCenterId: '',
    isCostCenterDeleting: false,
  };

  constructor(props) {
    super(props);
    this.delete = this.delete.bind(this);
    this.toggleConfirmDeleteDialog = this.toggleConfirmDeleteDialog.bind(this);
    this.toggleCreateDialog = this.toggleCreateDialog.bind(this);
    this.toggleEditDialog = this.toggleEditDialog.bind(this);
  }

  delete() {
    const { dataProvider, dispatch } = this.props;
    const { deletingCostCenterId } = this.state;
    this.setState({ isCostCenterDeleting: true });
    dataProvider(DELETE, 'costCenter', { id: deletingCostCenterId }).finally(() => {
      dispatch(refreshView());
    });
  }

  toggleConfirmDeleteDialog(isShow, id) {
    this.setState({ isShowConfirmDeleteDialog: isShow, deletingCostCenterId: id });
  }

  toggleCreateDialog(isShow) {
    this.setState({ isShowCreateDialog: isShow });
  }

  toggleEditDialog(isShow, id) {
    this.setState({ isShowEditDialog: isShow });
    if (id) {
      this.setState({ selectedCostCenterId: id });
    }
  }

  render() {
    const { dataProvider, dispatch, permissions, resource, record, translate, classes, ...props } = this.props;
    const {
      location: { pathname },
    } = props;
    const {
      isShowConfirmDeleteDialog,
      isShowCreateDialog,
      isShowEditDialog,
      selectedCostCenterId,
      isCostCenterDeleting,
    } = this.state;

    return (
      <>
        <CostCenterActions {...this.props} openCreateDialog={() => this.toggleCreateDialog(true)} />
        <ReferenceManyField reference="costCenter" record={record} target="business" {...props}>
          <Datagrid id="Selenium-Business-CostCenter-Table">
            <TextField source="name" key="costCenterName" />,
            <TextField source="value" key="costCenterValue" />,
            <FunctionField
              render={rec => (
                <Button
                  color="secondary"
                  size="medium"
                  onClick={() => this.toggleEditDialog(true, rec.id)}
                  id={`Selenium-Business-CostCenter-Edit-${rec.id}-Button`}
                >
                  <IconEdit className={classes.leftIcon} />
                  {translate('common.edit')}
                </Button>
              )}
            />
            <FunctionField
              render={rec => (
                <Button
                  color="secondary"
                  size="medium"
                  onClick={() => this.toggleConfirmDeleteDialog(true, rec.id)}
                  id={`Selenium-Business-CostCenter-Delete-${rec.id}-Button`}
                >
                  <IconDelete className={classes.leftIcon} />
                  {translate('common.delete')}
                </Button>
              )}
            />
          </Datagrid>
        </ReferenceManyField>
        <CostCenterCreate
          {...props}
          resource="costCenter"
          basePath={pathname}
          isShowDialog={isShowCreateDialog}
          closeDialog={() => this.toggleCreateDialog(false)}
        />
        <CostCenterEdit
          {...props}
          id={selectedCostCenterId}
          resource="costCenter"
          basePath={pathname}
          isShowDialog={isShowEditDialog}
          closeDialog={() => this.toggleEditDialog(false)}
        />
        <Dialog
          open={isShowConfirmDeleteDialog}
          onClose={() => {
            this.toggleConfirmDeleteDialog(false);
          }}
        >
          <DialogTitle>{translate('resources.costCenter.delete.title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{translate('resources.costCenter.delete.content')}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              disabled={isCostCenterDeleting}
              onClick={() => {
                this.toggleConfirmDeleteDialog(false);
              }}
            >
              {translate('common.cancel')}
            </Button>
            <Button color="primary" disabled={isCostCenterDeleting} onClick={this.delete}>
              <ActionCheck className={classes.leftIcon} />
              {translate('common.confirm')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

CostCenterList.propTypes = {
  basePath: PropTypes.string,
  dataProvider: PropTypes.func,
  dispatch: PropTypes.func,
  menuState: PropTypes.string,
  permissions: PropTypes.string,
  record: PropTypes.shape({}),
  resource: PropTypes.string,
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

const CostCenterListHOC = compose(
  withDataProvider,
  translateHOC,
  withStyles(styles)
)(CostCenterList);

export default CostCenterListHOC;
