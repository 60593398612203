import React, { memo, useState } from 'react';
import { compose } from 'redux';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Input,
  FormControl,
  InputLabel,
  FormHelperText,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import utils from '../../shared/utils';

const styles = {
  formControl: {
    width: '100%',
    margin: '24px 0 24px 0',
  },
};

const IbanMask = ({ inputRef, ...other }) => (
  <MaskedInput
    {...other}
    ref={ref => {
      inputRef(ref ? ref.inputElement : null);
    }}
    mask={utils.maskIban}
    placeholderChar={'\u2000'}
  />
);
IbanMask.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

const SetupWireTransferDialog = ({ onConfirm, onCancel, open, classes, translate }) => {
  const [iban, setIban] = useState(null);
  const isIbanValid = !!iban && iban.length >= 16 && iban.length <= 34;

  const onChange = e => {
    const { value } = e.target;
    const valueFormatted = value && value.replace(/\s/g, '');
    setIban(valueFormatted);
  };

  const onSubmit = async () => {
    await onConfirm(iban);
  };

  const onExited = () => setIban(null);

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onExited={onExited}>
      <DialogTitle id="Selenium-paymentDetails-setupWireTransfer-dialogTitle">
        {translate('resources.paymentDetails.edit.titleSetupIbanDialog')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="Selenium-paymentDetails-setupWireTransfer-dialogContextText">
          {translate('resources.paymentDetails.texts.specifyBelowIBAN')}
        </DialogContentText>
        <FormControl className={classes.formControl} id="Selenium-paymentDetails-setupWireTransfer-formControl">
          <InputLabel
            htmlFor="Selenium-SetupWireTransferDialog-Input-Iban"
            id="Selenium-paymentDetails-setupWireTransfer-inputLabel-Iban"
            error={!isIbanValid}
            shrink
          >
            {translate('resources.paymentDetails.fields.iban')}
          </InputLabel>
          <Input
            id="Selenium-SetupWireTransferDialog-Input-Iban"
            name="iban"
            placeholder="FR00 0000 0000 0000 0000 0000 0000 0000 00"
            error={!isIbanValid}
            value={iban}
            onChange={onChange}
            inputComponent={IbanMask}
            aria-describedby="Selenium-SetupWireTransferDialog-Input-Iban-error"
          />
          {!isIbanValid && (
            <FormHelperText id="Selenium-SetupWireTransferDialog-Input-Iban-error" error>
              {translate('resources.paymentDetails.texts.specifyBelowIBANerror')}
            </FormHelperText>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel}>
          {translate('common.cancel')}
        </Button>
        <Button color="primary" onClick={onSubmit} size="medium" disabled={!isIbanValid}>
          {translate('common.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SetupWireTransferDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  translate: PropTypes.func.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

export default compose(
  withStyles(styles),
  memo
)(SetupWireTransferDialog);
