import { UPDATE_ADYEN_MOP_RESULT } from './constants';

const initialState = {
  updateAdyenMopResult: {
    finishedUpdating: false,
    newMop: null,
    refusalReason: null,
    status: null,
    declineCode: '',
    reason: '',
    triggered3DS: false,
  },
};

const adyenReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_ADYEN_MOP_RESULT: {
      return {
        ...state,
        updateAdyenMopResult: {
          ...payload,
        },
      };
    }
    default:
      return state;
  }
};

export default adyenReducer;
