import React from 'react';
import { DateField, FunctionField, ReferenceField, TextField } from 'react-admin';
import { getBusinessConf } from '../config';

const sanitizeBusinessString = input => {
  if (input === 'string') {
    return '';
  }
  return input;
};

/**
 * Returns the full address of the business.
 * @param {Object} business Array element of billingAddress
 * @returns {string} The full address
 */
const getFullAddress = business => {
  if (!business.billingAddress) {
    return '';
  }

  const sanitizedBillingAddressElements = [
    sanitizeBusinessString(business.billingAddress.streetName),
    sanitizeBusinessString(business.billingAddress.city),
    sanitizeBusinessString(business.billingAddress.postalCode),
  ];

  return sanitizedBillingAddressElements.filter(Boolean).join(', ');
};

export const BusinessFields = translate => [
  <TextField key="name" source="name" />,
  <TextField key="legalName" source="legalName" sortable={false} />,
  <ReferenceField
    reference="user"
    label={translate('resources.business.fields.businessOwner')}
    source="businessOwner.id"
    key="userFullName"
    sortable={false}
    linkType={false}
    allowEmpty
  >
    <TextField key="fullName" source="fullName" />
  </ReferenceField>,
  <DateField
    source="creationDate"
    key="creationDate"
    locales={
      getBusinessConf().config.translation &&
      getBusinessConf().config.translation.default &&
      getBusinessConf().config.translation.default.replace('_', '-')
    }
  />,
  <TextField key="status" source="status" />,
  <TextField key="id" source="id" />,
  <TextField key="fleetId" source="fleetId" />,
  <FunctionField
    key="billingAddress"
    label={translate('resources.business.fields.billingAddress.streetName')}
    render={getFullAddress}
  />,
  <TextField key="phoneNumber" source="phoneNumber" sortable={false} />,
  <TextField key="faxNumber" source="faxNumber" sortable={false} />,
  <TextField key="email" source="email" sortable={false} />,
  <TextField key="vat" source="vat" sortable={false} />,
  <TextField key="ein" source="ein" sortable={false} />,
  <TextField key="registrationUrl" source="registrationUrl" sortable={false} />,
  <TextField key="address" source="address" sortable={false} />,
  <TextField key="comment" source="comment" sortable={false} />,
  <TextField key="locale" source="locale" sortable={false} />,
  <TextField key="type" source="type" />,
  <TextField key="entityName" source="entityName" sortable={false} />,
  <TextField key="tokenbillingAddress" source="tokenbillingAddress" />,
  <TextField key="services" source="services" />,
  <TextField key="masterEntity" source="masterEntity" sortable={false} />,
  <TextField key="communityTaxNumber" source="communityTaxNumber" sortable={false} />,
  <TextField key="taxNumberCountry" source="taxNumberCountry" sortable={false} />,
];
