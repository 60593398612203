import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  translate as translateHOC,
  List,
  Datagrid,
  TextField,
  FunctionField,
  crudUpdateMany as crudUpdateManyHOC,
} from 'react-admin';
import Switch from '../../../shared/Switch';

class GrantManagement extends React.PureComponent {
  handleChangeRole = (grantId, role) => {
    const {
      crudUpdateMany,
      location: { pathname },
    } = this.props;
    crudUpdateMany('settings', [grantId], { role: Object.assign(role, { value: !role.value }) }, pathname, true);
  };

  render() {
    const { ...props } = this.props;
    return (
      <List
        hasCreate={false}
        hasEdit={false}
        hasList
        hasShow={false}
        exporter={false}
        resource="settings"
        bulkActionButtons={false}
        perPage={-1}
        {...props}
        pagination={null}
      >
        <Datagrid {...props}>
          <TextField source="name" />
          <FunctionField
            label="resources.settings.fields.BUSINESS_ADMIN"
            render={record => (
              <Switch
                value={String(record.id)}
                checked={record.BUSINESS_ADMIN.value}
                disabled
                id={`Selenium-Settings-Grant-BusinessAdmin-${record.name}-Switch`}
              />
            )}
          />
          <FunctionField
            label="resources.settings.fields.BUSINESS_OWNER"
            render={record => (
              <Switch
                value={String(record.id)}
                checked={record.BUSINESS_OWNER.value}
                onChange={() => this.handleChangeRole(record.id, record.BUSINESS_OWNER)}
                id={`Selenium-Settings-Grant-BusinessOwner-${record.name}-Switch`}
              />
            )}
          />
          <FunctionField
            label="resources.settings.fields.BUSINESS_DELEGATED_ADMIN"
            render={record => (
              <Switch
                value={String(record.id)}
                checked={record.BUSINESS_DELEGATED_ADMIN.value}
                onChange={() => this.handleChangeRole(record.id, record.BUSINESS_DELEGATED_ADMIN)}
                id={`Selenium-Settings-Grant-BusinessDelegatedAdmin-${record.name}-Switch`}
              />
            )}
          />
          <FunctionField
            label="resources.settings.fields.BUSINESS_CUSTOMER"
            render={record => (
              <Switch
                value={String(record.id)}
                checked={false}
                disabled
                id={`Selenium-Settings-Grant-BusinessCustomer-${record.name}-Switch`}
              />
            )}
          />
        </Datagrid>
      </List>
    );
  }
}

GrantManagement.propTypes = {
  crudUpdateMany: PropTypes.func,
  location: PropTypes.shape({}),
};

export default translateHOC(
  connect(
    null,
    { crudUpdateMany: crudUpdateManyHOC }
  )(GrantManagement)
);
