/**
 * The code is loosely based on https://material-ui.com/demos/autocomplete/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withDataProvider, GET_LIST } from 'react-admin';
import Downshift from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import CircularProgress from '@material-ui/core/CircularProgress';
import utils from '../utils';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'fixed',
    marginTop: theme.spacing.unit,
    zIndex: 1,
  },
  inputRoot: {
    flexWrap: 'wrap',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
  },
  search: {
    position: 'absolute',
    right: 0,
    bottom: 6,
  },
});

const renderInput = ({ InputProps, classes, ref, ...other }) => {
  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
};

renderInput.propTypes = {
  InputProps: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  ref: PropTypes.shape({}),
};

const renderSuggestion = ({ suggestion, index, itemProps, highlightedIndex, selectedItem }) => {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.name) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.name}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
      id={`Selenium-Business-${index}-Option`}
    >
      {suggestion.name}
    </MenuItem>
  );
};

renderSuggestion.propTypes = {
  suggestion: PropTypes.arrayOf(PropTypes.shape({})),
  index: PropTypes.number,
  itemProps: PropTypes.shape({}),
  highlightedIndex: PropTypes.number,
  selectedItem: PropTypes.shape({}),
};

class AutocompleteField extends React.PureComponent {
  state = {
    isSearching: false,
    suggestions: [],
  };

  oldValue = '';

  counter = 0;

  debounce = 700;

  getSuggestions = (value, resource, optionText, record, counter) => {
    const { dataProvider } = this.props;
    if (value !== '') {
      const searchRecord = { page: 0, size: 5, filter: { q: value }, ...record };
      dataProvider(GET_LIST, resource, searchRecord).then(res => {
        const suggestions = [];
        if (counter === this.counter) {
          res.data.forEach(item => suggestions.push({ name: item[optionText], id: item.id }));
        }
        this.setState({ suggestions, isSearching: false });
      });
      utils.hiddenLoadingScreen();
    } else {
      this.setState({ isSearching: false });
    }
  };

  handleInput = value => {
    const { resource, record = {}, optionText = 'name' } = this.props;
    this.counter += 1;
    const locCounter = this.counter;
    setTimeout(() => {
      this.oldValue = value;
      if (locCounter === this.counter) {
        this.setState({ suggestions: [], isSearching: true });
        this.getSuggestions(value, resource, optionText, record, locCounter);
      }
    }, this.debounce);
    return null;
  };

  handleOnChange = value => {
    const { onChange } = this.props;
    const { suggestions } = this.state;
    let result = {};
    suggestions.forEach(item => {
      if (item.name === value) {
        result = item;
      }
    });
    onChange(result);
  };

  render() {
    const { classes, inputProps } = this.props;
    const { suggestions, isSearching } = this.state;
    return (
      <div className={classes.root}>
        <Downshift onChange={this.handleOnChange}>
          {({
            getInputProps,
            getItemProps,
            getMenuProps,
            highlightedIndex,
            isOpen,
            inputValue,
            selectedItem,
            clearSelection,
          }) => (
            <div className={classes.container}>
              {renderInput({
                fullWidth: true,
                classes,
                InputProps: getInputProps({
                  ...inputProps,
                  onChange: e => {
                    if (inputProps.onChange) {
                      inputProps.onChange(e);
                    }
                    if (e.target.value === '') {
                      clearSelection();
                    }
                  },
                }),
              })}
              {inputValue !== this.oldValue ? this.handleInput(inputValue) : null}
              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {suggestions.map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion.name }),
                        highlightedIndex,
                        selectedItem,
                      })
                    )}
                  </Paper>
                ) : null}
              </div>
              {isSearching ? <CircularProgress className={classes.search} size={16} /> : null}
            </div>
          )}
        </Downshift>
      </div>
    );
  }
}

AutocompleteField.propTypes = {
  dataProvider: PropTypes.func,
  resource: PropTypes.string,
  record: PropTypes.shape({}),
  optionText: PropTypes.string,
  onChange: PropTypes.func,
  classes: PropTypes.shape({}),
  inputProps: PropTypes.shape({}),
};

export default withDataProvider(withStyles(styles)(AutocompleteField));
// For Tests
export const AutocompleteFieldTest = AutocompleteField;
