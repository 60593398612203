import { GET_ONE, UPDATE, CREATE } from 'react-admin';

const dataPaymentDetails = (instance, type, params, config) => {
  const opt = {};
  switch (type) {
    case GET_ONE:
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${params.id}/paymentDetails`;
      opt.method = 'GET';
      break;
    case UPDATE:
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${params.id}/paymentDetails`;
      opt.params = params.data;
      opt.method = 'POST';
      break;
    case CREATE:
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${params.id}/paymentDetails/wireTransfer`;
      opt.data = params.data;
      opt.method = 'POST';
      break;
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
  return instance(opt);
};

export default dataPaymentDetails;
