import React from 'react';
import {
  Labeled,
  refreshView,
  Show,
  SimpleShowLayout,
  TextField,
  translate as translateHOC,
  withDataProvider,
  GET_ONE,
  CREATE,
  showNotification,
  fetchEnd,
} from 'react-admin';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Typography, withStyles, FormControl, InputLabel } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import SetupWireTransferDialog from './setup-wire-transfer';
import PaymentDetailsEdit from './payment-details-edit';
import withGrants from '../WithGrants';
import UpdateBusinessPaymentButtonBar from './UpdateBusinessPaymentButtonBar';
import { updateAdyenMopResultAction } from '../../core/redux/adyen/actions';
import { paymentDetailsSelector } from '../../core/redux/paymentDetails/selectors';

const styles = {
  field: {
    display: 'grid',
  },
  noShadow: {
    '& div': {
      boxShadow: 'none',
    },
  },
  warning: {
    boxSizing: 'border-box',
    fontStyle: 'italic',
    padding: '20px',
  },
  formControl: {
    position: 'relative',
  },
  formControlContent: {
    padding: '15px 0 0 0',
  },
  formControlLabel: {
    display: 'contents',
  },
  formControlLabelText: {
    fontSize: '0.8rem',
  },
  mopUpdating: {
    color: 'rgb(13, 60, 97)',
    backgroundColor: 'rgb(232, 244, 253)',
    padding: '6px 16px',
    borderRadius: 4,
  },
  icon: { verticalAlign: 'bottom' },
};

const IbanField = ({ classes, iban, translate }) => {
  if (iban !== undefined && iban !== null) {
    return (
      <>
        <FormControl className={classes.formControl} id="Selenium-paymentDetails-ibanField-formControl">
          <InputLabel
            className={classes.formControlLabel}
            htmlFor="Selenium-SetupWireTransfer-Input-IBAN"
            variant="outlined"
            id="Selenium-paymentDetails-ibanField-inputLabel"
          >
            <span className={classes.formControlLabelText} id="Selenium-paymentDetails-ibanField-Span">
              {translate('resources.paymentDetails.fields.iban')}
            </span>
          </InputLabel>
          <div className={classes.formControlContent}>
            <Typography id="Selenium-paymentDetails-ibanField-Typography">{iban.match(/.{1,4}/g).join(' ')}</Typography>
          </div>
        </FormControl>
      </>
    );
  }

  return (
    <>
      <Typography className={classes.warning} id="Selenium-paymentDetails-ibanField-Typography">
        {translate('notification.paymentDetails.notActivated')}
      </Typography>
    </>
  );
};

IbanField.propTypes = {
  classes: PropTypes.shape({}),
  iban: PropTypes.string,
  translate: PropTypes.func,
};

const NameField = ({ classes, record, ...rest }) =>
  record && record.holderName ? (
    <Labeled label="resources.paymentDetails.fields.holderName">
      <TextField
        source="holderName"
        className={classes.field}
        record={record}
        {...rest}
        id="Selenium-paymentDetails-nameField-textField"
      />
    </Labeled>
  ) : null;

NameField.propTypes = {
  classes: PropTypes.shape({}),
  record: PropTypes.shape({}),
};

const CardField = ({ classes, record }) => {
  if (record && record.cardSummary) {
    const card = `xxxx-xxxx-xxxx-${record.cardSummary}`;
    return (
      <Labeled label="resources.paymentDetails.fields.cardSummary">
        <span className={classes.field} id="Selenium-paymentDetails-cardField-Card">
          {card}
        </span>
      </Labeled>
    );
  }
  return null;
};

CardField.propTypes = {
  classes: PropTypes.shape({}),
  record: PropTypes.shape({}),
};

const ExpField = ({ classes, record, ...rest }) => {
  if (record && record.expiryDate) {
    const dayExp = new Date(parseFloat(record.expiryDate));
    const expiryDate = dayExp.toLocaleDateString('en-US', {
      month: '2-digit',
      year: '2-digit',
    });
    return (
      <Labeled label="resources.paymentDetails.fields.expiryDate">
        <TextField
          source="expiryDate"
          className={classes.field}
          record={{ expiryDate }}
          {...rest}
          id="Selenium-paymentDetails-expField-textField"
        />
      </Labeled>
    );
  }
  return null;
};

ExpField.propTypes = {
  classes: PropTypes.shape({}),
  record: PropTypes.shape({}),
};

class PaymentDetailsShow extends React.PureComponent {
  state = {
    isShowDialogChangePayment: false,
    isShowDialogSetupWireTransfer: false,
    iban: null,
    paymentMethod: null,
    mopUpdating: false,
    last4: null,
  };

  constructor(props) {
    super(props);
    const { location } = this.props;
    const params = queryString.parse(location.search);
    if (params && params.authResult) {
      this.state.message = `resources.paymentDetails.status.${params.authResult}`;
      this.state.isSuccessed = params.authResult === 'AUTHORISED';
    }
    window.location.hash = location.pathname;
  }

  componentDidMount() {
    const { fetchIban } = this;
    fetchIban();
  }

  componentDidUpdate(prevProps) {
    const { paymentDetails } = this.props;

    if (paymentDetails !== prevProps.paymentDetails) {
      this.fetchIban();
    }
  }

  fetchIban = async () => {
    const { id, dataProvider, checkIfMOPIsValid } = this.props;
    const result = await dataProvider(GET_ONE, 'paymentDetails', { id });

    const iban = result && result.data && result.data.iban;
    const paymentMethod = result && result.data && result.data.paymentMethod;
    const mopStatus = result && result.data && result.data.mopStatus;
    const last4 = result && result.data && result.data.last4;

    this.setState({ iban, paymentMethod, last4 });
    if (mopStatus === 'MOP_UPDATING') {
      this.setState({ mopUpdating: true });
    }
    checkIfMOPIsValid();
  };

  handleCancelDialog = () => {
    const { dispatch } = this.props;
    this.setState({ isShowDialogChangePayment: false });
    dispatch(fetchEnd());
  };

  handleCloseDialog = () => {
    const { dispatch } = this.props;
    this.setState({ isShowDialogChangePayment: false });
    dispatch(
      updateAdyenMopResultAction({
        finishedUpdating: false,
        newMop: null,
        refusalReason: null,
        status: null,
        declineCode: '',
        reason: '',
        triggered3DS: false,
      })
    );
    dispatch(refreshView());
  };

  handleChangePaymentClick = async () => {
    try {
      this.setState({ isShowDialogChangePayment: true });
    } catch (error) {
      console.error(error);
    }
  };

  handleSetupWireTransferClick = () => this.setState({ isShowDialogSetupWireTransfer: true });

  handleCloseSetupWireTransfer = () => this.setState({ isShowDialogSetupWireTransfer: false });

  onConfirmSetupWireTransfer = async value => {
    const { dispatch, dataProvider, id } = this.props;
    try {
      this.setState({ iban: value }, async () => {
        const { iban } = this.state;
        await dataProvider(CREATE, 'paymentDetails', { data: { iban }, id });
        await this.fetchIban();
        dispatch(showNotification('notification.business.setupWireTransferSuccess'));
      });
    } catch (err) {
      const errObject = JSON.parse(err.message);

      const { errorCode, httpStatusCode, httpStatus } = errObject;

      console.error(`Error: ${httpStatusCode}`, {
        httpStatusCode,
        httpStatus,
        errorCode,
      });
      dispatch(showNotification(`error.${errorCode}`, 'warning'));
    } finally {
      this.handleCloseSetupWireTransfer();
    }
  };

  render() {
    const { handleCancelDialog, handleCloseDialog, onConfirmSetupWireTransfer, handleCloseSetupWireTransfer } = this;
    const {
      basePath,
      dispatch,
      resource,
      translate,
      classes,
      dataProvider,
      location: { pathname },
      checkPermission,
      ...props
    } = this.props;
    const {
      isShowDialogChangePayment,
      isShowDialogSetupWireTransfer,
      isSuccessed,
      message,
      iban,
      paymentMethod,
      mopUpdating,
      last4,
    } = this.state;

    const { checkIfMOPIsValid, ...rest } = props;

    return (
      <>
        <Show
          {...rest}
          className={classes.noShadow}
          basePath={pathname}
          resource="paymentDetails"
          actions={null}
          title=" "
        >
          {mopUpdating ? (
            <SimpleShowLayout>
              <Typography className={classes.mopUpdating}>
                <ErrorOutline className={classes.icon} /> {translate('notification.paymentDetails.mopUpdating')}
              </Typography>
              <UpdateBusinessPaymentButtonBar
                onSetupWireTransferClick={this.handleSetupWireTransferClick}
                onChangePaymentClick={this.handleChangePaymentClick}
              />
            </SimpleShowLayout>
          ) : (
            <SimpleShowLayout>
              {paymentMethod && paymentMethod === 'SEPA' ? (
                <IbanField classes={classes} iban={`****${last4}`} translate={translate} />
              ) : (
                (!paymentMethod || paymentMethod !== 'CREDIT_CARD') && (
                  <IbanField classes={classes} iban={iban} translate={translate} />
                )
              )}

              <NameField classes={classes} />
              <CardField classes={classes} />
              <ExpField classes={classes} />
              <span
                id="messageError"
                name="messageError"
                style={{
                  color: isSuccessed ? 'green' : 'red',
                  marginTop: '20px',
                  display: 'table',
                  fontWeight: '700',
                }}
              >
                {translate(message)}
              </span>
              <UpdateBusinessPaymentButtonBar
                onSetupWireTransferClick={this.handleSetupWireTransferClick}
                onChangePaymentClick={this.handleChangePaymentClick}
              />
            </SimpleShowLayout>
          )}
        </Show>
        <PaymentDetailsEdit
          {...rest}
          resource="paymentDetails"
          basePath={pathname}
          isShowDialog={isShowDialogChangePayment}
          handleCancelDialog={handleCancelDialog}
          handleCloseDialog={handleCloseDialog}
          setMopUpdating={isUpdating => this.setState({ mopUpdating: isUpdating })}
        />
        <SetupWireTransferDialog
          translate={translate}
          open={isShowDialogSetupWireTransfer}
          onCancel={handleCloseSetupWireTransfer}
          onConfirm={onConfirmSetupWireTransfer}
        />
      </>
    );
  }
}

PaymentDetailsShow.propTypes = {
  dataProvider: PropTypes.func,
  resource: PropTypes.string,
  translate: PropTypes.func,
  dispatch: PropTypes.func,
  classes: PropTypes.shape({}),
  basePath: PropTypes.string,
  location: PropTypes.shape({ pathname: PropTypes.string }),
  checkPermission: PropTypes.func,
  id: PropTypes.string,
  checkIfMOPIsValid: PropTypes.func,
  paymentDetails: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  paymentDetails: paymentDetailsSelector(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withGrants,
  withDataProvider,
  withStyles(styles),
  translateHOC
);

export default enhance(PaymentDetailsShow);
