import axios from 'axios';
import moment from 'moment';
import dataRequestConverter from './data-request';
import dataResponseConverter from './data-response';
import { refreshTokenPromise, decodeToken } from './auth-provider';
import utils from '../shared/utils';

const dataSubmit = (promise, params, resource, type) => {
  return promise
    .then(res => {
      utils.showLoadingScreen(false);
      return Promise.resolve(dataResponseConverter(res, params, resource, type));
    })
    .catch(err => {
      utils.showLoadingScreen(false);
      return Promise.reject(new Error(utils.error(err)));
    });
};

const provider = (config, instance, type, resource, params) => {
  utils.showLoadingScreen(true);
  // Generate URL and convert submit data from create-admin data to backend-friendly one
  const promise = dataRequestConverter(instance, resource, type, params, config);
  return dataSubmit(promise, params, resource, type);
};

const dataProviderConfig = config => {
  const instance = axios.create({
    baseURL: config.business_ws.baseUrl,
    timeout: config.timeout || 30000,
    headers: {
      Accept: 'application/json',
      'X-API-KEY': config.login.x_api_key,
    },
  });

  let isRefreshing = false;
  let refreshSubscribers = [];

  instance.interceptors.request.use(request => {
    let token = '';
    const accessToken = sessionStorage.getItem('accessToken');
    if (accessToken) {
      ({ token } = JSON.parse(accessToken));
    }
    return {
      ...request,
      headers: {
        ...request.headers,
        Authorization: `Bearer ${token}`,
      },
    };
  });

  instance.interceptors.response.use(
    response => response,
    error => {
      if (!error.response) {
        return Promise.reject(error);
      }
      const {
        config: requestConfig,
        response: { status },
      } = error;
      const originalRequest = requestConfig;

      if (status === 401 && originalRequest.url && !originalRequest.url.includes('paymentSession')) {
        if (!isRefreshing) {
          isRefreshing = true;
          if (!localStorage.getItem('refreshToken')) {
            return Promise.reject(error);
          }
          const { refreshToken, refreshExpiresIn } = JSON.parse(localStorage.getItem('refreshToken'));
          if (moment().unix() > parseInt(refreshExpiresIn, 10)) {
            return Promise.reject(error);
          }

          const dataInstance = axios.create({
            baseURL: config.login.baseUrl,
            timeout: config.timeout || 30000,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
              'X-API-KEY': config.login.x_api_key,
            },
          });

          refreshTokenPromise(config, dataInstance, refreshToken)
            .then(token => {
              decodeToken(token);
              return token;
            })
            .then(token => {
              refreshSubscribers.forEach(cb => cb(token));
              refreshSubscribers = [];
              isRefreshing = false;
            })
            .catch(errorRefresh => {
              refreshSubscribers.forEach(cb => cb(null, errorRefresh));
              refreshSubscribers = [];
              isRefreshing = false;
            });
        }
        const executor = (request, resolve, reject) => {
          refreshSubscribers.push((token, errorRefresh) => {
            if (errorRefresh) {
              reject(errorRefresh);
              return;
            }
            request.headers.Authorization = `Bearer ${token}`;
            resolve(instance.request(request));
          });
        };
        return new Promise(executor.bind(null, originalRequest));
      }
      return Promise.reject(error);
    }
  );
  return provider.bind(null, config, instance);
};

export { provider, dataProviderConfig as default };
