import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, CircularProgress } from '@material-ui/core';
import { withDataProvider, translate as translateHOC } from 'react-admin';
import { compose } from 'redux';
import withGrants from '../WithGrants';

const SuccessView = translateHOC(({ translate, holderName, cardSummary, formatedExpiryDate }) => (
  <Grid container direction="column" spacing={24}>
    <Grid item container direction="column" spacing={8}>
      <Grid item>
        <Typography variant="caption">{translate('resources.paymentDetails.fields.holderName')}</Typography>
      </Grid>
      <Grid item>
        <Typography>{holderName}</Typography>
      </Grid>
    </Grid>
    <Grid item container direction="column" spacing={8}>
      <Grid item>
        <Typography variant="caption">{translate('resources.paymentDetails.fields.cardSummary')}</Typography>
      </Grid>
      <Grid item>
        <Typography>{`xxxx-xxxx-xxxx-${cardSummary}`}</Typography>
      </Grid>
    </Grid>
    <Grid item container direction="column" spacing={8}>
      <Grid item>
        <Typography variant="caption">{translate('resources.paymentDetails.fields.expiryDate')}</Typography>
      </Grid>
      <Grid item>
        <Typography>{formatedExpiryDate}</Typography>
      </Grid>
    </Grid>
  </Grid>
));

const FailedView = translateHOC(({ translate, translationKey, triggered3DS, declineCode, reason }) => (
  <Grid container direction="column" spacing={24}>
    <Grid item container direction="column" spacing={8}>
      <Grid item>
        <Typography variant="body1">{translate(`resources.paymentDetails.status.${translationKey}`)}</Typography>
      </Grid>
    </Grid>
    {!triggered3DS && (
      <>
        {declineCode && (
          <Grid item container direction="column" spacing={8}>
            <Grid item>
              <Typography variant="caption">
                {translate('resources.paymentDetails.failingReason.label.reasonCode')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{declineCode}</Typography>
            </Grid>
          </Grid>
        )}
        {reason && (
          <Grid item container direction="column" spacing={8}>
            <Grid item>
              <Typography variant="caption">
                {translate('resources.paymentDetails.failingReason.label.reason')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1">{reason}</Typography>
            </Grid>
          </Grid>
        )}
      </>
    )}
  </Grid>
));

const PaymentMethodResultProps = {
  newMop: PropTypes.shape({
    holderName: PropTypes.string,
    cardSummary: PropTypes.string,
    expiryDate: PropTypes.string,
  }),
  status: PropTypes.string,
  declineCode: PropTypes.string,
  reason: PropTypes.string,
  translate: PropTypes.func,
  finishedUpdating: PropTypes.bool,
  triggered3DS: PropTypes.bool,
};

const PaymentMethodResult = ({ newMop, status, declineCode, reason, finishedUpdating, triggered3DS }) => {
  const { holderName, expiryDate } = newMop || {};

  const dayExp = new Date(parseFloat(expiryDate));
  const formatedExpiryDate = dayExp.toLocaleDateString('en-US', {
    month: '2-digit',
    year: '2-digit',
  });

  let translationKey = '';

  // Updates the setupIntent status to match Stripe status.
  switch (status) {
    case 'SUCCEEDED':
      translationKey = 'AUTHORISED';
      break;

    case 'CANCELLED':
      translationKey = 'CANCELLED';
      break;

    case 'FAILED':
      translationKey = 'ERROR';
      break;

    case 'PROCESSING':
      translationKey = 'PENDING';
      break;

    default:
      break;
  }

  const successViewProps = {
    ...newMop,
    formatedExpiryDate,
  };

  const failedViewProps = {
    translationKey,
    triggered3DS,
    reason,
    declineCode,
  };

  return (
    <>
      {!finishedUpdating ? (
        <Grid container direction="column" spacing={24} justify="center" alignContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <>
          {status === 'SUCCEEDED' && holderName && <SuccessView {...successViewProps} />}
          {finishedUpdating && status === 'FAILED' && <FailedView {...failedViewProps} />}
        </>
      )}
    </>
  );
};

PaymentMethodResult.propTypes = PaymentMethodResultProps;

const enhance = compose(
  withDataProvider,
  withGrants
);

export default enhance(PaymentMethodResult);
