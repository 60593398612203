import { GET_MANY_REFERENCE } from 'react-admin';

const dataBillingProducts = (res, params, type) => {
  switch (type) {
    case GET_MANY_REFERENCE:
      // We insert id to records, because result return from backend doesnt have id
      res.data.forEach((item, i, arr) => {
        const array = arr;
        array[i] = Object.assign(item, { id: i });
      });
      break;
    default:
      break;
  }
  return res;
};

export default dataBillingProducts;
