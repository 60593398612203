import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { showNotification, translate as translateHOC } from 'react-admin';
import { IbanElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Button, Grid, Input, InputLabel, Typography, withStyles } from '@material-ui/core';
import { ErrorOutline } from '@material-ui/icons';
import { clientSecretSelector } from '../../../core/redux/stripe/selectors';
import { getClientSecret, resetClientSecret } from '../../../core/redux/stripe/actions';

const mandateStyles = {
  paper: {
    backgroundColor: '#E8F4FD',
    flexWrap: 'nowrap',
    padding: '14px 16px',
  },
  icon: {
    color: '#2196F3',
    paddingRight: 7,
  },
};

const MandateContainerSimple = ({ mandate, classes = {} }) => (
  <Grid item xs={12}>
    <Grid container classes={{ container: classes.paper }}>
      <ErrorOutline classes={{ root: classes.icon }} />
      <Typography>{mandate}</Typography>
    </Grid>
  </Grid>
);
MandateContainerSimple.propTypes = {
  mandate: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
};

const MandateContainer = compose(
  withStyles(mandateStyles),
  memo
)(MandateContainerSimple);

const IBAN_STYLE = {
  base: {
    color: '#32325d',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
    ':-webkit-autofill': {
      color: '#32325d',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a',
    },
  },
};

const styles = theme => ({
  stripeElement: {
    height: 40,
    padding: '10px 12px',
    width: '100%',
    color: '#32325d',
    border: '1px solid transparent',
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
    boxShadow: '0 1px 3px 0 #e6ebf1',
    boxSizing: 'border-box',
    transition: 'box-shadow 150ms ease',
    marginBottom: theme.spacing.unit * 2,
    '&--focus': {
      boxShadow: '0 1px 3px 0 #cfd7df',
    },
    '&--invalid': {
      borderColor: '#fa755a',
    },
    '&--webkit-autofill': {
      backgroundColor: '#fefde5 !important',
    },
  },
  btnContainer: {
    marginTop: theme.spacing.unit * 2,
  },
  spacingXs2: {
    '&> .MuiGrid-item': {
      padding: 0,
    },
  },
});

const SepaForm = ({
  closeDialog,
  locale,
  classes,
  clientSecret,
  getClientSecretAction,
  translate,
  resetClientSecretAction,
  dispatch,
}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isIbanValid, setIsIbanValid] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const ibanRef = useRef(null);
  const mandateButtonRef = useRef(null);

  const IBAN_ELEMENT_OPTIONS = useMemo(() => {
    const ibanCountry = locale && locale.split('_').length === 2 && locale.split('_')[1];

    return {
      supportedCountries: ['SEPA'],
      placeholderCountry: ibanCountry || 'DE',
      style: IBAN_STYLE,
    };
  }, [locale]);

  const callStripe = useCallback(async () => {
    if (!clientSecret) {
      return;
    }

    const iban = elements.getElement(IbanElement);

    const result = await stripe.confirmSepaDebitSetup(clientSecret, {
      payment_method: {
        sepa_debit: iban,
        billing_details: {
          name,
          email,
        },
      },
    });

    if (result.error) {
      dispatch(showNotification(result.error && result.error.code, 'error'));
      console.error('Error:', result.error);
    } else {
      // From Stripe docs: the SetupIntent is in the 'succeeded' state.
      dispatch(showNotification(translate('stripe.paymentSuccess'), 'success'));
    }
    closeDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSecret]);

  useEffect(() => {
    callStripe();

    return () => {
      if (clientSecret) {
        resetClientSecretAction();
      }
    };
  }, [callStripe, clientSecret]);

  const handleSubmit = () => {
    setIsSubmitting(true);
    getClientSecretAction();
  };

  const isSubmitValid = stripe && elements && name && email && isIbanValid;

  return (
    <>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6}>
          <InputLabel>
            <Typography>{translate('resources.paymentDetails.editPopup.name')}</Typography>
            <Input
              classes={{ root: classes.stripeElement }}
              name="accountholder-name"
              disableUnderline
              onChange={event => setName(event.target.value)}
              value={name}
            />
          </InputLabel>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel>
            <Typography>{translate('resources.paymentDetails.editPopup.email')}</Typography>
            <Input
              classes={{ root: classes.stripeElement }}
              name="email"
              disableUnderline
              onChange={event => setEmail(event.target.value)}
              value={email}
            />
          </InputLabel>
        </Grid>
        <Grid item xs={12} ref={ibanRef}>
          <InputLabel>
            <Typography>{translate('resources.paymentDetails.editPopup.iban')}</Typography>
            <IbanElement
              className={classes.stripeElement}
              options={IBAN_ELEMENT_OPTIONS}
              onChange={event => setIsIbanValid(!event.error)}
            />
          </InputLabel>
        </Grid>
        <MandateContainer mandate={translate('resources.paymentDetails.editPopup.mandate')} />
      </Grid>

      <Grid container justify="flex-end" classes={{ container: classes.btnContainer }} ref={mandateButtonRef}>
        <Button
          fullWidth
          onClick={handleSubmit}
          disabled={!isSubmitValid || isSubmitting}
          variant="contained"
          color="primary"
        >
          {translate('resources.paymentDetails.editPopup.confirmMandate')}
        </Button>
      </Grid>
    </>
  );
};
SepaForm.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired,
  clientSecret: PropTypes.string.isRequired,
  classes: PropTypes.shape({}).isRequired,
  getClientSecretAction: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  resetClientSecretAction: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  locale: state.i18n.locale,
  clientSecret: clientSecretSelector(state),
});

const mapDispatchToProps = dispatch => ({
  getClientSecretAction: () => dispatch(getClientSecret({ paymentMethodType: 'SEPA' })),
  resetClientSecretAction: () => dispatch(resetClientSecret()),
});

export default compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translateHOC
)(SepaForm);
