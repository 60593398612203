import { takeEvery, select } from 'redux-saga/effects';
import axios from 'axios';
import { getBusinessConf } from '../config';
import { refreshFlow } from '../auth-provider';

function* getCurrentUser() {
  const { config } = yield getBusinessConf();
  const state = yield select();
  const { currentUser } = state;
  const instance = axios.create({
    baseURL: config.login.baseUrl,
    timeout: config.timeout || 30000,
  });

  const token = JSON.parse(sessionStorage.getItem('accessToken'));
  if (token && !currentUser.firstName && !currentUser.lastName) {
    refreshFlow(config, instance);
  }
}

export default function* userSaga() {
  yield takeEvery('GET_CURRENT_USER', getCurrentUser);
}
