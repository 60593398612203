import dataRequestBusiness from './data-business';
import dataPaymentDetails from './data-payment-details';
import dataAdyen from './data-adyen';
import dataStripe from './data-stripe';
import dataRequestBillingBalance from './data-billing-balance';
import dataRequestBusinessInvoice from './data-business-invoice';
import dataRequestBusinessInvoicePdf from './data-business-invoice-pdf';
import dataRequestBillingProducts from './data-billing-products';
import dataRequestBillingTrips from './data-billing-trips';
import dataRequestBusinessOwner from './data-business-owner';
import dataRequestCredits from './data-credits';
import dataRequestPromoteCodes from './data-promote-codes';
import dataRequestBusinessUser from './data-business-user';
import dataRequestBillingGroup from './data-billing-group';
import dataRequestBusinessTrip from './data-business-trip';
import dataRequestAdminSettings from './data-admin-settings';
import dataRequestInvite from './data-invite';
import dataRequestCostCenter from './data-cost-center';
import dataRequestUser from './data-user';
import dataRequestProduct from './data-product';
import dataRequestCharge from './data-charge';
import utils from '../../shared/utils';

const dataRequestConverter = (instance, resource, type, parameters, config) => {
  let promise;
  let params = parameters;
  // Make sure object return from server is always JSON
  if (Object.prototype.toString.call(params) === '[object String]') {
    params = {};
    console.error('TODO: Server returns non-JSON result!');
  }
  // Automatically unassign any IDs start with 'autoid_' prefix
  params = utils.deepUnassignId(params);
  switch (resource) {
    case 'user':
      promise = dataRequestUser(instance, type, params, config);
      break;
    case 'business':
      promise = dataRequestBusiness(instance, type, params, config);
      break;
    case 'paymentDetails':
      promise = dataPaymentDetails(instance, type, params, config);
      break;
    case 'adyen':
      promise = dataAdyen(instance, type, params, config);
      break;
    case 'stripe':
      promise = dataStripe(instance, type, params, config);
      break;
    case 'businessOwner':
      promise = dataRequestBusinessOwner(instance, type, params, config);
      break;
    case 'businessTrip':
      promise = dataRequestBusinessTrip(instance, type, params, config);
      break;
    case 'billingBalance':
      promise = dataRequestBillingBalance(instance, type, params, config);
      break;
    case 'businessInvoice':
      promise = dataRequestBusinessInvoice(instance, type, params, config);
      break;
    case 'businessInvoicePdf':
      promise = dataRequestBusinessInvoicePdf(instance, type, params, config);
      break;
    case 'billingProducts':
      promise = dataRequestBillingProducts(instance, type, params, config);
      break;
    case 'billingTrips':
      promise = dataRequestBillingTrips(instance, type, params, config);
      break;
    case 'billingGroup':
      promise = dataRequestBillingGroup(instance, type, params, config);
      break;
    case 'businessUser':
      promise = dataRequestBusinessUser(instance, type, params, config);
      break;
    case 'promoteCodes':
      promise = dataRequestPromoteCodes(instance, type, params, config);
      break;
    case 'credits':
      promise = dataRequestCredits(instance, type, params, config);
      break;
    case 'costCenter':
      promise = dataRequestCostCenter(instance, type, params, config);
      break;
    case 'invite':
      promise = dataRequestInvite(instance, type, params, config);
      break;
    case 'settings':
      promise = dataRequestAdminSettings(instance, type, params, config);
      break;
    case 'product':
      promise = dataRequestProduct(instance, type, params, config);
      break;
    case 'charge':
      promise = dataRequestCharge(instance, type, params, config);
      break;
    default:
      return Promise.reject(new Error(`Unsupported Data Provider request resource ${resource}`));
  }
  return promise;
};

export default dataRequestConverter;
