import set from 'lodash/set';
import get from 'lodash/get';

/**
 * Adds alternate translations to the base translations object.
 * @param {Object} baseTranslations - The base translations object.
 * @param {Array<Array<string>>} alternateTranslations - An array of alternate translations.
 */
export const addAlternateTranslations = (baseTranslations, alternateTranslations) => {
  alternateTranslations.forEach(([originKey, newKey]) => {
    const newKeyValue = get(baseTranslations, newKey.replace(/::/g, '.'), 'Translation not found');
    const formattedOriginKey = originKey.replace(/::/g, '.');

    set(baseTranslations, formattedOriginKey, newKeyValue);
  });
};
