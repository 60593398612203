import { GET_MANY_REFERENCE } from 'react-admin';
import moment from 'moment';
import utils from '../../shared/utils';

const dataBillingProducts = (instance, type, params, config) => {
  const opt = {};
  switch (type) {
    case GET_MANY_REFERENCE: {
      // Convert parameters
      const month = params.filter.month ? params.filter.month : moment().month() + 1;
      const year = params.filter.year ? params.filter.year : moment().year();
      const period = moment(`${year}-${month}`, 'YYYY-M').format('YYYY-MM');
      const query = new URLSearchParams();
      if (params.pagination) {
        query.append('page', params.pagination.page - 1);
        query.append('size', params.pagination.perPage);
      }
      query.append('sort', params.sort.field + (params.sort.order === 'ASC' ? ',asc' : ',desc'));
      query.append('period', period);
      //
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/billing/entity/${params.id}/product`;
      opt.method = 'GET';
      opt.params = query;
      break;
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
  utils.hiddenLoadingScreen();
  return instance(opt);
};

export default dataBillingProducts;
