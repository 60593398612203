import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles, InputLabel, Drawer, Paper, Typography } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import moment from 'moment';
import {
  Button,
  fetchEnd,
  fetchStart,
  GET_ONE,
  Pagination,
  ReferenceManyField,
  showNotification,
  translate as translateHOC,
  withDataProvider,
} from 'react-admin';
import { reset } from 'redux-form';
import get from 'lodash/get';
import { GET_LIST } from 'ra-core';
import TripDetails from '../../trips/trip-details';
import Header from '../../../shared/header';
import Divider from '../../../shared/divider';
import { BillingOtherChargesFields, BillingTripFields } from '../../../core/data-fields/data-billing';
import CustomDatagrid from '../../../shared/custom-datagrid';
import utils from '../../../shared/utils';
import ChargeProduct from './charge-product';
import withGrants from '../../WithGrants';
import { getBusinessConf } from '../../../core/config';
import BillingFilters from './BillingFilters';

const styles = theme => ({
  drawer: {
    top: '48px',
    padding: '15px',
    width: '400px',
  },
  fieldContent: {
    width: '100%',
  },
  wrapper: {
    padding: '24px 19px 24px 24px',
    '&> div:nth-child(-n+3)': {
      marginRight: '24px',
    },
  },
  button: {
    marginTop: 20,
  },
  buttonChargeProduct: {
    marginTop: 50,
  },
  rightIcon: {
    marginLeft: theme.spacing.unit,
  },
  filterGrid: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  filterDivider: {
    marginTop: '16px',
  },
  buttonText: {
    display: 'inline-flex',
    alignItems: 'center',
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: 1.75,
    letterSpacing: '0.02857em',
  },
  multipleSelectRow: {
    display: 'flex',
    alignItems: 'center',
  },
  multipleSelect: {
    width: '100%',
  },
  infoIcon: {
    width: '16px',
    height: '16px',
    marginTop: '16px',
    marginLeft: '8px',
  },
});

const initialMonth = moment().month() + 1;

const initialYear = moment().year();

class BillingList extends React.PureComponent {
  state = {
    balance: {
      currency: '',
      amount: 0,
    },
    monthList: [],
    yearList: [],
    costCenterList: [],
    costCenterSelection: [],
    user: '',
    userInput: '',
    month: initialMonth,
    year: initialYear,
    isShowTripDetails: false,
    isShowChargeProductDialog: false,
    isFilterDrawerOpen: false,
  };

  searchTimer = createRef(null);

  componentWillMount() {
    const { dispatch, dataProvider } = this.props;
    const { id } = utils.getParameters().business;
    this.generateMonthList(moment().month() + 1);
    this.generateYearList();
    this.generateCostCenterList();
    dispatch(fetchStart());
    dataProvider(GET_ONE, 'billingBalance', { id })
      .then(({ data }) => {
        utils.setParameters({ currency: data.currency });
        this.setState({
          balance: {
            currency: data.currency,
            amount: data.amount,
            entityId: data.entityId,
          },
        });
      })
      .catch(err => {
        const errObject = JSON.parse(err.message);

        const { errorCode, httpStatusCode, httpStatus } = errObject;

        console.error(`Error: ${httpStatusCode}`, {
          httpStatusCode,
          httpStatus,
          errorCode,
        });
        dispatch(showNotification(`error.${errorCode}`, 'warning'));
      })
      .finally(() => {
        dispatch(fetchEnd());
        this.handleSearch();
      });
  }

  /**
   * We call the react-admin backend API to receive the list of Cost Centers
   */
  generateCostCenterList = () => {
    const { dataProvider, record } = this.props;
    dataProvider(GET_LIST, 'costCenter', record).then(res => {
      const costCenterList = res.data
        .filter(cc => cc.id && cc.name && cc.value) // We filter out null or empty values
        .map(cc => ({ value: cc.id, label: cc.name })); // We transform the object so it can be used in the MultipleSelect
      this.setState({ costCenterList });
    });
  };

  /**
   * Sets the userInput's value
   * @param {Object} event Input event
   */
  handleUserInputChange = event => this.setState({ userInput: event.target.value });

  handleSearch = () => {
    const { month, year, user, costCenterSelection } = this.state;
    const filter = {
      dummy: Math.random(), // Dummy filter value to force refresh
      month,
      year,
      user,
      costCenters: costCenterSelection,
    };
    if (filter.user === '') {
      delete filter.user;
    }
    this.setState({ filter });
  };

  /**
   * This method is called when the manager selects one or more items
   * from the filter of Cost Centers
   * @param {Array<Object>} costCenterSelection
   */
  handleCostCenterChange = costCenterSelection => {
    this.setState({ costCenterSelection }, () => {
      if (this.searchTimer && this.searchTimer.current) {
        clearTimeout(this.searchTimer.current);
      }
      this.searchTimer.current = setTimeout(this.handleSearch, 1000);
    });
  };

  handleUserChange = value => {
    if (value) {
      const { user } = this.state;
      if (value.id !== user) {
        this.setState({ user: value.id });
        setTimeout(this.handleSearch, 1000);
      }
    } else {
      this.setState({ user: '' });
    }

    // After selecting a user from the suggestion list, set this name as the input
    this.setState({ userInput: value.name });
  };

  handleMonthChange = event => {
    const { value } = event.target;
    const { month } = this.state;
    if (value !== month) {
      this.setState({ month: value });
      setTimeout(this.handleSearch, 1000);
    }
  };

  handleYearChange = event => {
    const { value } = event.target;
    const { month, year } = this.state;
    // We will limit the highest month if the selected year is current year
    if (moment().year() === value) {
      const highestMonthValue = moment().month() + 1;
      this.generateMonthList(highestMonthValue);
      if (month > highestMonthValue) {
        this.setState({ month: highestMonthValue });
      }
    } else {
      this.generateMonthList();
    }
    if (value !== year) {
      this.setState({ year: value });
      setTimeout(this.handleSearch, 1000);
    }
  };

  handleShowTripDetails = (id, basePath, record) => {
    const { config } = getBusinessConf();
    const { tripId, userId } = record.trip;
    const amount = record.amountOfBill;
    if (config && config.isMapDisplayed !== false) {
      utils.setParameters({
        businessTrip: {
          tripId,
          tripDetailStatus: 'Complete',
          amountPaid: amount,
          distanceUnit: record.distanceUnit,
          userId,
        },
      });
      this.setState({ isShowTripDetails: true });
    }
  };

  handleHideTripDetails = () => {
    this.setState({ isShowTripDetails: false });
  };

  handleChargeProductClick = () => {
    const { dispatch } = this.props;
    this.setState({
      isShowChargeProductDialog: true,
    });
    dispatch(reset('product'));
  };

  handleCloseChargeProductDialog = () => {
    this.setState({
      isShowChargeProductDialog: false,
    });
  };

  generateMonthList = (maxMonth = 12) => {
    const monthList = [];
    for (let i = 1; i <= maxMonth; i += 1) {
      monthList.push(i);
    }
    this.setState({ monthList });
  };

  generateYearList = () => {
    // Change this to adjust the smallest year we can select
    const minYear = 2010;
    const maxYear = moment().year();
    const yearList = [];
    for (let i = maxYear; i >= minYear; i -= 1) {
      yearList.push(i);
    }
    this.setState({ yearList });
  };

  /**
   * This method is called when the drawer closes
   */
  closeDrawer = () => this.setState({ isFilterDrawerOpen: false });

  /**
   * Resets the react-admin filters
   */
  resetFilters = () => {
    this.setState({
      costCenterSelection: [],
      user: '',
      userInput: '',
      month: initialMonth,
      year: initialYear,
    });
    setTimeout(this.handleSearch, 1000);
  };

  render() {
    const {
      filter,
      currentBillingTripsSelection,
      currentBillingOtherChargesSelection,
      isFilterDrawerOpen,
      balance,
      isShowTripDetails,
      isShowChargeProductDialog,
      month,
      year,
      monthList,
      yearList,
      costCenterList,
      costCenterSelection,
      userInput,
    } = this.state;
    const { dispatch, classes, hasList, hasEdit, hasShow, checkPermission, translate, id, ...props } = this.props;
    const record = { id };
    const datagridProps = { ...this.props };
    ['classes', 'dataProvider', 'isShowDialog', 'dispatch'].forEach(propName => {
      delete datagridProps[propName];
    });
    return (
      <>
        <Drawer
          anchor="right"
          variant="temporary"
          ModalProps={{
            keepMounted: true,
          }}
          onClose={this.closeDrawer}
          open={isFilterDrawerOpen}
          classes={{ paper: classes.drawer }}
        >
          <Typography variant="headline">{translate('common.filters')}</Typography>
          <Divider hasNoBorder />
          <Paper elevation={1}>
            <BillingFilters
              classes={classes}
              translate={translate}
              month={month}
              year={year}
              monthList={monthList}
              yearList={yearList}
              costCenterList={costCenterList}
              costCenterSelection={costCenterSelection}
              userInput={userInput}
              handleUserInputChange={this.handleUserInputChange}
              handleUserChange={this.handleUserChange}
              handleMonthChange={this.handleMonthChange}
              handleYearChange={this.handleYearChange}
              handleCostCenterChange={this.handleCostCenterChange}
            />
          </Paper>
          <Divider hasNoBorder />
          <Divider hasNoBorder />
          <Button
            className={classes.buttonText}
            variant="contained"
            color="primary"
            label={translate('resources.billing.filterDrawer.clearFilters')}
            onClick={this.resetFilters}
          />
          <Divider hasNoBorder />
          <Button
            className={classes.buttonText}
            variant="contained"
            color="primary"
            label={translate('resources.billing.filterDrawer.confirmFilters')}
            onClick={this.closeDrawer}
          />
        </Drawer>
        <TripDetails isShowDialog={isShowTripDetails} handleCloseDialog={this.handleHideTripDetails} />
        <Divider hasNoBorder />
        <Divider hasNoBorder />
        <div className={classes.filterGrid}>
          {checkPermission('VIEW_BUSINESS_BALANCE') && (
            <InputLabel className={classes.field} id="Selenium-Business-Billing-BusinessBalance">
              {translate('resources.billing.fields.businessBalance')}: {balance.amount} {balance.currency}
            </InputLabel>
          )}
          <Button
            variant="contained"
            color="primary"
            id="Selenium-Table-Header-Filters-Button"
            size="medium"
            onClick={() => this.setState({ isFilterDrawerOpen: true })}
          >
            <span
              style={{
                display: 'inline-flex',
                alignItems: 'center',
                fontSize: '0.875rem',
                fontWeight: 500,
                lineHeight: 1.75,
                letterSpacing: '0.02857em',
              }}
            >
              {translate('common.filters')}
              <FilterListIcon className={classes.rightIcon} />
            </span>
          </Button>
        </div>
        {filter ? (
          <>
            <Divider hasNoBorder />
            <Divider hasNoBorder />
            <Divider hasNoBorder />
            <div id="Selenium-Business-Billing-Trips">
              <Header label="resources.billing.list.listOfBilledTrips" />
              <ReferenceManyField
                {...props}
                pagination={<Pagination />}
                filter={filter}
                perPage={10}
                record={record}
                reference="billingTrips"
                target="business"
                currentSelection={currentBillingTripsSelection}
              >
                <CustomDatagrid
                  {...datagridProps}
                  onColumnChange={selection => {
                    this.setState({ currentBillingTripsSelection: selection });
                  }}
                  record={record}
                  rowClick={this.handleShowTripDetails}
                  currency={balance.currency}
                  defaultColumns={get(getBusinessConf().config, 'defaultColumnTables.billingTrips')}
                  id="Selenium-Business-Billing-Trips-Table"
                >
                  {BillingTripFields()}
                </CustomDatagrid>
              </ReferenceManyField>
            </div>
            <Divider hasNoBorder />
            <div id="Selenium-Business-Billing-OtherCharges">
              <Header label="resources.billing.list.otherCharges" />
              <ReferenceManyField
                {...props}
                pagination={<Pagination />}
                filter={filter}
                perPage={10}
                record={record}
                reference="billingProducts"
                target="business"
                currentSelection={currentBillingOtherChargesSelection}
              >
                <CustomDatagrid
                  {...datagridProps}
                  onColumnChange={selection => {
                    this.setState({ currentBillingOtherChargesSelection: selection });
                  }}
                  record={record}
                  currency={balance.currency}
                  defaultColumns={get(getBusinessConf().config, 'defaultColumnTables.billingOtherCharges')}
                  id="Selenium-Business-Billing-OtherCharges-Table"
                >
                  {BillingOtherChargesFields({ currency: balance.currency })}
                </CustomDatagrid>
              </ReferenceManyField>
            </div>
          </>
        ) : null}
        {checkPermission('ADD_CHARGE_PRODUCT') && (
          <Button
            variant="outlined"
            onClick={this.handleChargeProductClick}
            label="resources.billing.buttons.chargeProduct"
            className={classes.buttonChargeProduct}
            size="medium"
            id="Selenium-Business-Billing-ChargeProduct-Button"
          />
        )}
        {checkPermission('ADD_CHARGE_PRODUCT') && isShowChargeProductDialog && (
          <ChargeProduct
            currency={balance.currency}
            entityId={balance.entityId}
            handleCloseDialog={this.handleCloseChargeProductDialog}
          />
        )}
      </>
    );
  }
}

BillingList.propTypes = {
  dataProvider: PropTypes.func,
  dispatch: PropTypes.func,
  translate: PropTypes.func,
  classes: PropTypes.shape({}),
  hasList: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasShow: PropTypes.bool,
  id: PropTypes.string,
  checkPermission: PropTypes.func,
  record: PropTypes.shape({}).isRequired,
};

BillingTripFields.propTypes = {
  currency: PropTypes.string.isRequired,
};

export default withGrants(withDataProvider(translateHOC(withStyles(styles)(BillingList))));

// For Tests
export const BillingListTest = BillingList;
export const BillingFiltersTest = BillingFilters;
