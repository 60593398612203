import {
  GET_CLIENT_SECRET,
  RESET_CLIENT_SECRET,
  RESET_SETUP_INTENT,
  RESET_STRIPE,
  SET_CLIENT_SECRET,
  STRIPE_SYNCHRONIZE,
} from './constants';

export const getClientSecret = payload => ({
  type: GET_CLIENT_SECRET,
  payload,
});

export const setClientSecret = payload => ({
  type: SET_CLIENT_SECRET,
  payload,
});

export const stripeSynchronize = payload => ({
  type: STRIPE_SYNCHRONIZE,
  payload,
});

export const resetClientSecret = () => ({
  type: RESET_CLIENT_SECRET,
});

export const resetSetupIntent = () => ({
  type: RESET_SETUP_INTENT,
});

export const resetStripe = () => ({
  type: RESET_STRIPE,
});
