import React, { useState, useEffect, Fragment } from 'react';
import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js';
import { withStyles, Button, TextField, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import CardSection from './CardSection';
import { clientSecretSelector } from '../../../core/redux/stripe/selectors';
import { resetClientSecret } from '../../../core/redux/stripe/actions';

const styles = theme => ({
  container: {
    backgroundColor: '#fbfbfb',
    border: '1px solid #d6d6d6',
    borderRadius: 4,
    padding: theme.spacing.unit * 4,
  },
  containerGrid: {
    marginBottom: theme.spacing.unit * 2,
  },
  buttonSubmit: {
    marginTop: theme.spacing.unit * 2,
  },
  bootstrapRoot: {
    padding: 0,
    'label + &': {
      marginTop: theme.spacing.unit * 3,
    },
  },
  bootstrapInput: {
    borderRadius: 4,
    backgroundColor: theme.palette.common.white,
    border: '1px solid #d2dae1',
    fontSize: 16,
    padding: '10px 12px',
    width: 'calc(100% - 24px)',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    '&::placeholder': {
      color: '#a6a6a6',
      opacity: 1,
    },
    '&:focus': {
      borderColor: '#969696',
    },
  },
  bootstrapFormLabel: {
    fontSize: 16,
    transform: 'translate(0, 1.5px) scale(1)',
  },
});

const CardSetupForm = ({
  isPostalCodeMandatory,
  classes,
  translate,
  handleCloseDialog,
  dispatch,
  setMopUpdating,
  onSubmit,
  resetClientSecretAction,
  clientSecret,
  synchronizeMOP,
  resetStripeFlow,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const card = {
    number: false,
    expiryDate: false,
    cvc: false,
  };

  const [isCardReady, setIsCardReady] = useState(card);
  const [cardHolderName, setCardHolderName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [postalCode, setPostalCode] = useState('');

  const onCardChange = (event, type) => {
    switch (type) {
      case 'number':
      case 'expiryDate':
      case 'cvc':
        if (event.complete) {
          setIsCardReady({ ...isCardReady, [type]: true });
        } else if (event.error) {
          setIsCardReady({ ...isCardReady, [type]: false });
        }
        break;

      default:
        break;
    }
  };

  const isCardCompleted = () => {
    // all card values are true
    const areAllCardValuesTrue = Object.values(isCardReady).every(val => val);
    const isNum = val => /^\d+$/.test(val);
    if (isPostalCodeMandatory) {
      return areAllCardValuesTrue && isNum(postalCode) && postalCode.length === 5 && cardHolderName;
    }
    return areAllCardValuesTrue && cardHolderName;
  };

  const handleSubmit = async event => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    setIsLoading(true);
    onSubmit();
  };

  useEffect(() => {
    if (!stripe || !elements || !clientSecret) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    stripe
      .confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: cardHolderName,
            address: {
              postal_code: postalCode,
            },
          },
        },
      })
      .then(result => {
        // Handle result.error or result.paymentMethod
        if (result.error) {
          dispatch(showNotification(result.error.message, 'warning'));
          setIsLoading(false);
        } else if (result.setupIntent.status === 'succeeded') {
          // The payment has been processed!
          synchronizeMOP();
          setMopUpdating(true);
          setIsLoading(false);
          resetStripeFlow();
          dispatch(showNotification(translate('stripe.paymentSuccess'), 'success'));
          handleCloseDialog();
        }
      })
      .catch(e => {
        console.error('Error', e);
      });
  }, [clientSecret]);

  useEffect(() => {
    return () => {
      if (clientSecret) resetClientSecretAction();
    };
  }, [clientSecret]);

  return (
    <Fragment>
      <div className={classes.container}>
        <div className={classes.containerGrid}>
          <Grid container item spacing={16} className={classes.containerGrid}>
            <Grid item xs={12} sm={8} md={6}>
              <TextField
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.bootstrapRoot,
                    input: classes.bootstrapInput,
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                  focused: false,
                  className: classes.bootstrapFormLabel,
                }}
                name="cardHolderName"
                id="cardHolderName"
                label={translate('stripe.cardholderName')}
                placeholder={translate('stripe.cardholderNamePlaceholder')}
                defaultValue=""
                onChange={e => setCardHolderName(e.target.value)}
              />
            </Grid>
          </Grid>
          <CardSection onCardChange={onCardChange} classes={classes} translate={translate} />
          {isPostalCodeMandatory && (
            <Grid container item spacing={16}>
              <Grid item xs={6} sm={4} md={3}>
                <TextField
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: classes.bootstrapRoot,
                      input: classes.bootstrapInput,
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                    focused: false,
                    className: classes.bootstrapFormLabel,
                  }}
                  name="zipCode"
                  id="zipCode"
                  label={translate('stripe.zipCode')}
                  placeholder="12345"
                  defaultValue=""
                  onChange={e => setPostalCode(e.target.value)}
                />
              </Grid>
            </Grid>
          )}
        </div>
      </div>

      <Button
        className={classes.buttonSubmit}
        onClick={handleSubmit}
        fullWidth
        variant="contained"
        color="primary"
        disabled={!stripe || !isCardCompleted() || isLoading}
      >
        {translate('stripe.saveCard')}
      </Button>
    </Fragment>
  );
};

CardSetupForm.propTypes = {
  isPostalCodeMandatory: PropTypes.bool,
  translate: PropTypes.func,
  classes: PropTypes.shape({}),
  dispatch: PropTypes.func,
  handleCloseDialog: PropTypes.func.isRequired,
  setMopUpdating: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  resetClientSecretAction: PropTypes.func.isRequired,
  synchronizeMOP: PropTypes.func.isRequired,
  resetStripeFlow: PropTypes.func.isRequired,
  clientSecret: PropTypes.string,
};

const mapStateToProps = state => ({
  clientSecret: clientSecretSelector(state),
});

const mapActionToProps = dispatch => ({
  resetClientSecretAction: () => dispatch(resetClientSecret()),
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapActionToProps
  ),
  withStyles(styles)
);

export default enhance(CardSetupForm);
