import React from 'react';
import PropTypes from 'prop-types';
import { translate as translateHOC, Show, Button, SimpleShowLayout, ReferenceField } from 'react-admin';
import ReactMapboxGl, { Layer, Feature, Marker, ZoomControl } from 'react-mapbox-gl';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import IconCancel from '@material-ui/icons/Cancel';
import IconAvTimer from '@material-ui/icons/AvTimer';
import IconClockOutline from '@material-ui/icons/Schedule';
import IconChart from '@material-ui/icons/Timeline';
import IconCar from '@material-ui/icons/DirectionsCar';
import { getBusinessConf } from '../../../core/config';
import MultiTextField from '../../../shared/multi-text-field';
import utils from '../../../shared/utils';
import math from '../../../shared/utils/math';
import { Account, CashMultiple, TrainCar } from '../../../shared/icons';

const styles = {
  icon: {
    fontSize: '20px',
    display: 'inline-block',
    transform: 'translate(0px, 4px)',
    opacity: 0.54,
  },
  label: {
    fontSize: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    opacity: 0.54,
    textAlign: 'center',
    height: '32px',
  },
  field: {
    whiteSpace: 'nowrap',
    float: 'left',
    minWidth: '40px',
    marginRight: '5%',
  },
  fieldTranslate: {
    whiteSpace: 'nowrap',
    float: 'left',
    marginRight: '5%',
    transform: 'translate(0px, 2px)',
  },
  fieldContent: {
    width: '100%',
    marginRight: '0',
  },
  textComplete: {
    background: '#73AD21',
    color: 'white',
    textAlign: 'center',
    borderRadius: '8px',
    padding: '6px',
  },
  textOngoing: {
    background: '#FF8C00',
    color: 'white',
    textAlign: 'center',
    borderRadius: '8px',
    padding: '8px',
  },
};

const MapField = ({ record, handleLoadMapData, handleLoadComplete }) => {
  handleLoadMapData(record);
  handleLoadComplete();
  return null;
};

MapField.propTypes = {
  record: PropTypes.shape({}),
  handleLoadMapData: PropTypes.func,
};

const UserField = ({ record, translate, classes }) => {
  return (
    <MultiTextField
      customLabel={translate('resources.businessTrip.fields.userId')}
      record={record}
      icon={<Account fontSize="20" />}
      sources={['firstName', 'lastName']}
      className={classes.field}
      classes={{ label: classes.label, icon: classes.icon }}
      id={`tripDetails-${translate('resources.businessTrip.fields.userId')}`}
    />
  );
};

UserField.propTypes = {
  record: PropTypes.shape({}),
  translate: PropTypes.func,
  classes: PropTypes.shape({}),
};

const DirectionalMap = ({ isLoaded, record }) => {
  if (!isLoaded) {
    return null;
  }
  const Map = ReactMapboxGl({
    accessToken: getBusinessConf().config.mapbox.accessToken,
  });
  const { mapCoordinates } = record;
  const numCoord = mapCoordinates.length;
  const startCoord = mapCoordinates[0];
  const endCoord = mapCoordinates[numCoord - 1];
  const bbox = math.bbox(mapCoordinates);
  const { min, max } = bbox;
  // Calculate margin
  const marginX = (max[0] - min[0]) * 0.05;
  const marginY = (max[1] - min[1]) * 0.05;
  min[0] -= marginX;
  min[1] -= marginY;
  max[0] += marginX;
  max[1] += marginY;
  //
  const boundary = [[...min], [...max]];
  return (
    <Map
      // eslint-disable-next-line react/style-prop-object
      style="mapbox://styles/mapbox/streets-v10"
      containerStyle={{
        width: '100%',
        height: '60vh',
      }}
      zoom={[15]}
      center={endCoord}
      fitBounds={boundary}
    >
      <ZoomControl zoomDiff={2} />
      <Marker coordinates={startCoord} anchor="center">
        <svg height="60" width="60">
          <circle cx="30" cy="30" r="20" stroke="#1E90FF" strokeWidth="2" fill="#1E90FF" opacity="0.7" />
          <text x="30" y="34" fill="white" fontSize="10" fontWeight="bold" textAnchor="middle">
            START
          </text>
        </svg>
      </Marker>
      {numCoord > 1 ? (
        <Marker coordinates={endCoord} anchor="center">
          <svg height="60" width="60">
            <circle cx="30" cy="30" r="20" stroke="#3CB371" strokeWidth="2" fill="#3CB371" opacity="0.7" />
            <text x="30" y="34" fill="white" fontSize="10" fontWeight="bold" textAnchor="middle">
              END
            </text>
          </svg>
        </Marker>
      ) : null}
      <Layer
        key="line"
        type="line"
        paint={{
          'line-color': '#6495ED',
          'line-width': 4,
        }}
      >
        <Feature coordinates={mapCoordinates} />
      </Layer>
    </Map>
  );
};

DirectionalMap.propTypes = {
  isLoaded: PropTypes.bool,
  record: PropTypes.shape({}),
};

class TripDetails extends React.PureComponent {
  state = { tripUserId: '', isLoaded: false, record: {} };

  handleLoadComplete = () => {
    this.setState({ isLoaded: true });
  };

  handleLoadMapData = record => {
    this.setState({ record });
  };

  render() {
    let id;
    let status;
    let amount;
    const { translate, isShowDialog, handleCloseDialog, classes, ...props } = this.props;
    const { tripUserId } = this.state;
    if (utils.getParameters().businessTrip) {
      const { tripId, tripDetailStatus, amountPaid, userId } = utils.getParameters().businessTrip;
      status = tripDetailStatus;
      id = tripId;
      amount = amountPaid;
      if (amount === undefined) {
        amount = 0;
      }
      if (tripUserId === '') {
        this.setState({ tripUserId: userId });
      }
    }
    const { currency } = utils.getParameters ? utils.getParameters() : utils.getParams;
    const { isLoaded, record } = this.state;

    return (
      <>
        <Dialog maxWidth={false} fullWidth open={isShowDialog} onClose={handleCloseDialog}>
          <DialogTitle>{translate('resources.businessTrip.show.title')}</DialogTitle>
          <DialogContent>
            <Show title=" " {...props} basePath="" id={id} resource="businessTrip">
              <SimpleShowLayout>
                <MultiTextField
                  customLabel=" "
                  sources={['status']}
                  className={classes.fieldTranslate}
                  classes={{
                    label: classes.label,
                    text: status === 'Ongoing' ? classes.textOngoing : classes.textComplete,
                  }}
                  id="tripDetails-status"
                />
                <MultiTextField
                  type="datetime"
                  customLabel={translate('resources.businessTrip.fields.tripStartDate')}
                  icon={<IconAvTimer fontSize="inherit" />}
                  sources={['date']}
                  className={classes.field}
                  classes={{ label: classes.label, icon: classes.icon }}
                  id={`tripDetails-${translate('resources.businessTrip.fields.tripStartDate')}`}
                />
                <MultiTextField
                  customLabel={translate('resources.businessTrip.fields.duration')}
                  icon={<IconClockOutline fontSize="inherit" />}
                  sources={['duration', 'min']}
                  className={classes.field}
                  classes={{ label: classes.label, icon: classes.icon }}
                  type="number"
                  roundingRule={0}
                  id={`tripDetails-${translate('resources.businessTrip.fields.duration')}`}
                />
                <MultiTextField
                  customLabel={translate('resources.businessTrip.fields.distance')}
                  icon={<IconChart fontSize="inherit" />}
                  sources={['length', 'distanceUnit']}
                  className={classes.field}
                  classes={{ label: classes.label, icon: classes.icon }}
                  type="number"
                  id={`tripDetails-${translate('resources.businessTrip.fields.distance')}`}
                />
                <MultiTextField
                  customLabel={translate('resources.businessTrip.fields.vehicleDetail.name')}
                  icon={<IconCar fontSize="inherit" />}
                  sources={['vehicleDetail.name']}
                  className={classes.field}
                  classes={{ label: classes.label, icon: classes.icon }}
                  id={`tripDetails-${translate('resources.businessTrip.fields.vehicleDetail.name')}`}
                />
                <ReferenceField
                  addLabel={null}
                  source="userId"
                  reference="businessUser"
                  linkType={false}
                  className={classes.field}
                  classes={{ root: classes.fieldContent }}
                  id="tripDetails-userId"
                >
                  <UserField translate={translate} classes={classes} />
                </ReferenceField>
                <MultiTextField
                  customLabel={translate('resources.businessTrip.fields.serviceName')}
                  sources={['serviceName']}
                  icon={<TrainCar fontSize="20" />}
                  className={classes.field}
                  classes={{ label: classes.label, icon: classes.icon }}
                  id={`tripDetails-${translate('resources.businessTrip.fields.serviceName')}`}
                />
                {status !== 'Ongoing' ? (
                  <MultiTextField
                    type="custom"
                    customLabel={translate('resources.businessTrip.fields.amountPaid')}
                    icon={<CashMultiple fontSize="20" />}
                    sources={[amount, ` ${currency}`]}
                    className={classes.field}
                    classes={{ label: classes.label, icon: classes.icon }}
                    id={`tripDetails-${translate('resources.businessTrip.fields.amountPaid')}`}
                  />
                ) : null}
                <MapField handleLoadMapData={this.handleLoadMapData} handleLoadComplete={this.handleLoadComplete} />
              </SimpleShowLayout>
            </Show>
            <DirectionalMap record={record} isLoaded={isLoaded} />
          </DialogContent>
          <DialogActions>
            <Button
              label="common.cancel"
              onClick={handleCloseDialog}
              size="medium"
              id="Selenium-tripDetails-Button-Cancel"
            >
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

TripDetails.propTypes = {
  translate: PropTypes.func,
  isShowDialog: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
  classes: PropTypes.shape({}),
};

export default withStyles(styles)(translateHOC(TripDetails));
