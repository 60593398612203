import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { translate as translateHOC } from 'react-admin';

const styles = {
  headerCenter: {
    opacity: '0.87',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontSize: 20,
    marginBottom: 16,
  },
  header: {
    opacity: '0.87',
    float: 'left',
    width: '100%',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontSize: 20,
    marginBottom: 16,
  },
};

const Header = ({ translate, classes, label, isCentered, fontSize = 20 }) => {
  return (
    <div className={isCentered ? classes.headerCenter : classes.header} style={{ fontSize }}>
      <span>{translate(label)}</span>
    </div>
  );
};

Header.propTypes = {
  fontSize: PropTypes.number,
  translate: PropTypes.func,
  classes: PropTypes.shape({}),
  label: PropTypes.string,
  isCentered: PropTypes.bool,
};

export default translateHOC(withStyles(styles)(Header));
