import { GET_LIST, GET_MANY } from 'react-admin';

const dataAdminSettings = (res, params, type) => {
  let data = res;
  switch (type) {
    case GET_MANY:
    case GET_LIST: {
      const { grants } = data;
      const { roles } = data;
      const { result } = data;
      const grid = [];
      grants.forEach(grant => {
        const localGrant = grant;
        localGrant.BUSINESS_ADMIN = {
          value: true,
        };
        roles.forEach(role => {
          let isGrant = false;
          // Look for the role with this grant in result
          for (let j = 0; j < result.length; j += 1) {
            const item = result[j];
            const { roleId } = item;
            const resultGrants = item.grants;
            if (roleId === role.id) {
              for (let i = 0; i < resultGrants.length; i += 1) {
                if (resultGrants[i].id === grant.id) {
                  isGrant = true;
                  break;
                }
              }
            }
            if (isGrant) {
              break;
            }
          }
          //
          localGrant[role.name] = {
            id: role.id,
            value: isGrant,
          };
        });
        grid.push(localGrant);
      });
      data = {
        data: grid,
        total: grid.length,
      };
      break;
    }
    default:
      break;
  }
  return data;
};

export default dataAdminSettings;
