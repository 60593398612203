import { GET_MANY_REFERENCE, UPDATE, GET_ONE } from 'react-admin';
import utils from '../../shared/utils';
import { convertToTimezoneToUtc } from '../../shared/utils/date';

const dataBusinessInvoice = (
  instance,
  type,
  { pagination, sort, filter, id, field, invoiceId, amount, data, status, actionType }
) => {
  const opt = {};
  switch (type) {
    case GET_MANY_REFERENCE: {
      // Convert parameters
      const query = new URLSearchParams();

      if (pagination) {
        query.append('page', pagination.page - 1);
        query.append('size', pagination.perPage);
      }
      if (sort) {
        query.append('sort', `invoiceDate${sort.order === 'ASC' ? ',asc' : ',desc'}`);
      }
      if (filter) {
        query.append('fromDate', convertToTimezoneToUtc(filter.startDate, filter.timezone));
        query.append('toDate', convertToTimezoneToUtc(filter.endDate, filter.timezone, 'eod'));
      }

      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/entities/${id}/businessInvoices`;
      opt.method = 'GET';
      opt.params = query;
      break;
    }
    case GET_ONE:
      switch (field) {
        case 'getRefundableAmount':
          opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/invoices/${invoiceId}/refundableAmount`;
          opt.method = 'GET';
          break;
        case 'refund':
          opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/invoices/${invoiceId}/refund/${amount}`;
          opt.method = 'POST';
          break;
        default:
          throw new Error(`Unsupported Data Provider request type ${type}`);
      }
      break;
    case UPDATE:
      switch (actionType) {
        case 'markAsPaid':
          opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/invoices/${id}/externalPayment`;
          opt.method = 'PUT';
          opt.data = { ...data };
          break;
        case 'updateStatus':
          opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/invoices/${invoiceId}/status/${status}`;
          opt.method = 'POST';
          break;
        default:
          throw new Error(`Unsupported action type ${actionType}`);
      }
      break;
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
  utils.hiddenLoadingScreen();
  return instance(opt);
};

export default dataBusinessInvoice;
