import { GET_ONE } from 'react-admin';

const dataBusiness = (res, params, type) => {
  switch (type) {
    case GET_ONE: {
      // If address is null, then it's the same as billing address. We set the useAsBilling address to true and
      // copy its data to address
      const { data } = res;
      if (!data.address) {
        data.useAsBillingAddress = true;
        data.address = Object.assign({}, data.billingAddress);
        data.address.id = 'ref';
      }
      break;
    }
    default:
      break;
  }
  return res;
};

export default dataBusiness;
