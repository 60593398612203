import React from 'react';
import PropTypes from 'prop-types';
import {
  Edit,
  fetchStart,
  fetchEnd,
  withDataProvider,
  refreshView,
  showNotification,
  translate as translateHOC,
  SimpleForm,
  BooleanInput,
  TextInput,
  ReferenceInput,
  SelectInput,
  Button,
  Toolbar,
  UPDATE,
  FormDataConsumer,
} from 'react-admin';
import { submit, isSubmitting as isSubmittingAction } from 'redux-form';
import { connect } from 'react-redux';
import IconSave from '@material-ui/icons/Save';
import IconCancel from '@material-ui/icons/Cancel';
import { Dialog, DialogTitle, DialogContent, withStyles, CircularProgress } from '@material-ui/core';
import withGrants from '../../WithGrants';
import ConfirmArchiveDialog from './ConfirmArchiveDialog';

const styles = theme => ({
  toolbar: {
    justifyContent: 'flex-end',
    '& >button:last-child': {
      marginLeft: theme.spacing.unit,
    },
  },
  fullWidth: {
    width: '100%',
  },
  notThere: {
    display: 'none',
  },
});

const SaveButton = ({ isSubmitting, handleSaveClick, className, isDisabled }) => (
  <Button
    variant="raised"
    onClick={handleSaveClick}
    label="common.save"
    size="medium"
    color={isSubmitting ? 'default' : 'primary'}
    className={className}
    disabled={isDisabled}
    id="Selenium-Users-Edit-Button-Save"
  >
    {isSubmitting ? <CircularProgress size={25} thickness={2} /> : <IconSave />}
  </Button>
);

const CancelButton = ({ handleCancelClick }) => (
  <Button onClick={handleCancelClick} label="common.cancel" size="medium" id="Selenium-Users-Edit-Button-Cancel">
    <IconCancel />
  </Button>
);

const UserEditToolbar = ({
  isSubmitting,
  handleSaveClick,
  handleCancelClick,
  classes,
  record: { profiles },
  ...props
}) => (
  <Toolbar className={classes.toolbar} {...props}>
    <CancelButton handleCancelClick={handleCancelClick} />
    <SaveButton isSubmitting={isSubmitting} handleSaveClick={handleSaveClick} isDisabled={profiles === undefined} />
  </Toolbar>
);

class UserEdit extends React.Component {
  constructor(props) {
    super(props);
    this.dialogTitleRef = React.createRef();
    this.onSubmit = this.onSubmit.bind(this);
    this.onUpdateUserProfile = this.onUpdateUserProfile.bind(this);
    this.submit = this.submit.bind(this);
  }

  state = {
    confirmInactive: false,
    record: {},
  };

  onUpdateUserProfile(record) {
    const { dataProvider, dispatch, handleCancelClick, id } = this.props;
    dispatch(fetchStart());

    return dataProvider(UPDATE, 'businessUser', { data: record, id })
      .then(() => {
        dispatch(showNotification('notification.profileUpdateSuccess'));
        handleCancelClick();
        dispatch(refreshView());
      })
      .catch(err => {
        const errObject = JSON.parse(err.message);

        const { errorCode, httpStatusCode, httpStatus } = errObject;

        console.error(`Error: ${httpStatusCode}`, {
          httpStatusCode,
          httpStatus,
          errorCode,
        });
        if (err.message !== 'error.BR0002') {
          dispatch(showNotification(`error.${errorCode}`, 'warning'));
        } else {
          dispatch(showNotification(`error.${errorCode}`));
        }
      })
      .finally(dispatch(fetchEnd()));
  }

  onSubmit(record) {
    if (record && record.profileStatus && record.profileStatus === 'INACTIVE') {
      // FIRST DISPLAY A MODAL TO CONFIRM
      return this.setState({ confirmInactive: true, record });
    }
    return this.onUpdateUserProfile(record);
  }

  submit() {
    const { dispatch } = this.props;
    dispatch(submit('user-edit'));
  }

  render() {
    const {
      businessId,
      classes,
      dataProvider,
      dispatch,
      isShowDialog,
      isSubmitting,
      handleCancelClick,
      statusTabs,
      translate,
      checkPermission,
      ...props
    } = this.props;
    const { confirmInactive, record } = this.state;
    const statuses = [];
    const l = statusTabs.length;
    statusTabs.map((item, i) => {
      if (i < l - 1) {
        statuses.push(statusTabs[i]);
      }
      return null;
    });
    // If the user has the rights to archive a user, we add the option in the list
    if (checkPermission('ARCHIVE_BUSINESS_USER')) {
      statuses.push({ id: 'INACTIVE', name: 'resources.businessUser.list.statusTabs.inactive' });
    }

    return (
      <Dialog maxWidth="sm" fullWidth open={isShowDialog}>
        <DialogTitle>
          <div ref={this.dialogTitleRef} id="Selenium-Users-Edit-dialogTitle-Title" />
        </DialogTitle>
        {!confirmInactive && (
          <DialogContent>
            <Edit title=" " {...props}>
              <SimpleForm
                toolbar={
                  <UserEditToolbar
                    handleSaveClick={this.submit}
                    handleCancelClick={handleCancelClick}
                    classes={classes}
                    isSubmitting={isSubmitting}
                  />
                }
                form="user-edit"
                onSubmit={this.onSubmit}
                id="Selenium-Users-Edit-form"
              >
                <FormDataConsumer>
                  {({ formData }) => {
                    if (formData.userName && this.dialogTitleRef && this.dialogTitleRef.current) {
                      this.dialogTitleRef.current.innerHTML = translate('resources.businessUser.edit.title', {
                        userName: formData.userName,
                      });
                    }
                  }}
                </FormDataConsumer>
                <TextInput
                  source="userName"
                  className={classes.notThere}
                  disabled
                  id="Selenium-Users-Edit-Form-userName"
                />
                <TextInput
                  label="resources.businessUser.fields.email"
                  source="profiles[0].email"
                  className={classes.fullWidth}
                  id="Selenium-Users-Edit-textInput-Email"
                />
                <BooleanInput
                  label="resources.businessUser.fields.emailConsent"
                  source="profiles[0].emailConsent"
                  id="Selenium-Users-Edit-Form-emailConsent"
                />
                <SelectInput
                  label="resources.businessUser.fields.profileStatus"
                  source="profileStatus"
                  choices={statuses}
                  className={classes.fullWidth}
                  id="Selenium-Users-Edit-Form-profileStatus"
                />
                <ReferenceInput
                  label={translate('resources.businessUser.fields.costCenter')}
                  source="costCenterId"
                  reference="costCenter"
                  className={classes.fullWidth}
                  id="Selenium-Users-Edit-Form-costCenter-Id"
                >
                  <SelectInput optionText="name" className={classes.fullWidth} id="Selenium-Users-Edit-Form-Name" />
                </ReferenceInput>
                <BooleanInput
                  label="resources.businessUser.fields.useAsDelegatedAdmin"
                  source="isDelegatedAdmin"
                  id="Selenium-Users-Edit-Form-useAsDelegatedAdmin"
                />
              </SimpleForm>
            </Edit>
          </DialogContent>
        )}
        {confirmInactive && (
          <ConfirmArchiveDialog
            onSave={() => this.onUpdateUserProfile(record)}
            onCancel={() => this.setState({ confirmInactive: false })}
            {...props}
          />
        )}
      </Dialog>
    );
  }
}

SaveButton.propTypes = {
  isSubmitting: PropTypes.bool,
  className: PropTypes.string,
  handleSaveClick: PropTypes.func,
  isDisabled: PropTypes.bool,
};

CancelButton.propTypes = {
  handleCancelClick: PropTypes.func,
};

UserEditToolbar.propTypes = {
  classes: PropTypes.shape({}),
  handleCancelClick: PropTypes.func,
  handleSaveClick: PropTypes.func,
  isSubmitting: PropTypes.bool,
  record: PropTypes.shape({}),
};

UserEdit.propTypes = {
  businessId: PropTypes.string,
  classes: PropTypes.shape({}),
  closeCreateDialog: PropTypes.func,
  dataProvider: PropTypes.func,
  dispatch: PropTypes.func,
  handleCancelClick: PropTypes.func,
  history: PropTypes.shape({}),
  id: PropTypes.string,
  isShowDialog: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  location: PropTypes.shape({}),
  record: PropTypes.shape({}),
  statusTabs: PropTypes.arrayOf(PropTypes.shape({})),
  translate: PropTypes.func,
  checkPermission: PropTypes.func,
};

const mapStateToProps = state => ({
  isSubmitting: isSubmittingAction('user-edit')(state),
});

export default withGrants(withDataProvider(translateHOC(connect(mapStateToProps)(withStyles(styles)(UserEdit)))));
export const UserEditTest = UserEdit;
