import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Chip, Grid, InputLabel, withStyles } from '@material-ui/core';

const styles = ({ spacing: { unit } }) => ({
  root: {
    width: 'auto',
    display: 'inline-flex',
    marginTop: unit * 2,
    marginBottom: unit,
  },
  chipsContainer: {
    minHeight: '60px',
  },
  chip: {
    marginRight: unit,
    marginBottom: unit,
  },
});

const ChipsArray = ({ label, data, classes, canDelete, onDelete, canClick, onClick }) => {
  const Chips = () => (
    <Grid container alignItems="center" classes={{ container: classes.chipsContainer }}>
      {data.map(chip => (
        <Grid item key={chip.name}>
          <Chip
            label={chip.name}
            id={`Selenium-Chip-${chip.id}`}
            onDelete={canDelete ? onDelete(chip) : undefined}
            onClick={canClick ? onClick(chip) : undefined}
            className={classNames(classes.chip, 'Selenium-Chip')}
          />
        </Grid>
      ))}
    </Grid>
  );

  if (!label) {
    return <Chips />;
  }

  return (
    <Grid container alignItems="center" classes={{ container: classes.root }}>
      <Grid item>
        <InputLabel shrink style={{ display: 'block' }}>
          {label}
        </InputLabel>
      </Grid>
      <Chips />
    </Grid>
  );
};

ChipsArray.propTypes = {
  label: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ).isRequired,
  classes: PropTypes.shape({}).isRequired,
  canDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  canClick: PropTypes.bool,
  onClick: PropTypes.func,
};

export default withStyles(styles)(ChipsArray);
