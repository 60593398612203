import { GET_LIST, GET_MANY } from 'react-admin';

export const getActiveServices = services => {
  if (!Array.isArray(services)) {
    return [];
  }

  let selectedFleetId = null;

  try {
    selectedFleetId = localStorage.getItem('fleetID');
  } catch (err) {
    selectedFleetId = null;
  }

  // return only the services that are not disabled
  return services.filter(s => s.fleetId === selectedFleetId && s.status !== 'DISABLED');
};

const dataProduct = (res, params, type) => {
  const data = res;
  switch (type) {
    case GET_MANY:
    case GET_LIST:
      if (params.filter) {
        let newRes = res;
        if (params.filter.hideSubscription) {
          newRes = newRes.filter(
            product =>
              ![
                'INSURANCE',
                'EXCESS_MILEAGE_PACKAGE',
                'ADD_ON',
                'SUBSCRIPTION_CUSTOM_FEE',
                'SCHEDULED_BOOKING_ONE_WAY_FEE',
              ].includes(product.type)
          );
        }

        data.data = newRes;
        data.total = newRes.length;
      } else {
        data.data = res;
        data.total = res.length;
      }
      break;
    default:
      break;
  }
  return data;
};

export default dataProduct;
