import moment from 'moment';
import * as PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import {
  fetchEnd,
  fetchStart,
  GET_ONE,
  Pagination,
  ReferenceManyField,
  UPDATE,
  GET_MANY_REFERENCE,
  showNotification,
  translate as translateHOC,
  withDataProvider,
  refreshView,
} from 'react-admin';
import { connect } from 'react-redux';
import { InvoicesFields } from '../../../core/data-fields/data-invoices';
import CustomDatagrid from '../../../shared/custom-datagrid';
import withGrants from '../../WithGrants';
import Header from '../../../shared/header';

import InvoiceFilters from './invoiceFilters';
import RefundDialog from './refundDialog';
import CancelDialog from './CancelDialog';
import SetAsPaidDialog from './SetAsPaidDialog';
import { calculateTimeDifferenceInDays } from '../../../shared/utils/date';

class InvoiceList extends React.PureComponent {
  pagination = React.createRef();

  defaultDates = {
    startDate: moment()
      .subtract(3, 'month')
      .format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  };

  state = {
    ...this.state,
    filterValues: this.defaultDates,
    backendFilter: this.defaultDates,
    perPage: 10,
    isSetAsPaidDialogOpen: false,
    isRefundDialogOpen: false,
    isCancelDialogOpen: false,
    invoice: {},
    invoiceId: '',
    amount: 0,
    refundableAmount: 0,
    isFilteredDatesOverLimit: false,
  };

  componentDidUpdate() {
    const { filterValues, backendFilter } = this.state;

    const { startDate, endDate } = filterValues;

    const timeDifferenceInDays = calculateTimeDifferenceInDays(new Date(startDate), new Date(endDate));

    const timeLimitInDays = 365;

    // eslint-disable-next-line react/no-did-update-set-state
    this.setState({ isFilteredDatesOverLimit: timeDifferenceInDays > timeLimitInDays });

    if (
      filterValues.startDate !== '' &&
      filterValues.endDate !== '' &&
      (backendFilter.startDate !== filterValues.startDate || backendFilter.endDate !== filterValues.endDate) &&
      timeDifferenceInDays < timeLimitInDays
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        backendFilter: {
          ...backendFilter,
          startDate: filterValues.startDate,
          endDate: filterValues.endDate,
        },
      });
    }
  }

  handleChangePage = value => {
    this.pagination.current.props.setPage(value);
  };

  setFilters = (name, value) => {
    const { filterValues } = this.state;
    const newFilter = {
      ...filterValues,
      [name]: value,
    };
    this.setState({ filterValues: newFilter });
  };

  resetDates = () => {
    this.setState({ filterValues: this.defaultDates });
  };

  downloadPdf = invoiceId => {
    const { dispatch, dataProvider } = this.props;
    dispatch(fetchStart());
    dataProvider(GET_ONE, 'businessInvoicePdf', { id: invoiceId })
      .then(({ data }) => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([data], { type: 'application/pdf' });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const objectUrl = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = objectUrl;
        link.download = 'invoice.pdf';
        document.body.appendChild(link);
        link.click();

        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(() => {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(data);
        }, 100);
      })
      .catch(err => {
        if (typeof err.message === 'object') {
          const errObject = JSON.parse(err.message);
          const { errorCode, httpStatusCode, httpStatus } = errObject;

          console.error(`Error: ${httpStatusCode}`, {
            httpStatusCode,
            httpStatus,
            errorCode,
          });
          dispatch(showNotification(`error.${errorCode}`, 'warning'));
        } else if (typeof err.message === 'string') {
          dispatch(showNotification(err.message, 'warning'));
        }
      })
      .finally(dispatch(fetchEnd()));
  };

  fetchInvoices = page => {
    const { filterValues, perPage } = this.state;
    const { dataProvider, id, timezone } = this.props;
    const filter = { ...filterValues, timezone };

    dataProvider(GET_MANY_REFERENCE, 'businessInvoice', {
      id,
      filter,
      pagination: { perPage, page: page || 1 },
      sort: 'invoiceDate',
    });
  };

  openSetAsPaidDialog = invoiceId => {
    this.toggleSetAsPaidDialog();
    this.setState({ invoiceId });
  };

  toggleSetAsPaidDialog = () => {
    this.setState(({ isSetAsPaidDialogOpen }) => ({ isSetAsPaidDialogOpen: !isSetAsPaidDialogOpen }));
  };

  setInvoiceToPending = async invoiceId => {
    const { dataProvider, dispatch } = this.props;
    try {
      await dataProvider(UPDATE, 'businessInvoice', { actionType: 'updateStatus', invoiceId, status: 'PENDING' });
      dispatch(refreshView());
      dispatch(showNotification('notification.invoices.setToPendingSuccessfully'));
    } catch (err) {
      console.error(err);
      dispatch(showNotification('notification.invoices.setToPendingFailure', 'warning'));
    }
  };

  openCancelDialog = invoice => {
    this.toggleCancelDialog();
    this.setState({ invoice });
  };

  toggleCancelDialog = () => {
    const { isCancelDialogOpen } = this.state;
    this.setState({ isCancelDialogOpen: !isCancelDialogOpen });
  };

  onCancel = () => {
    const { dataProvider, dispatch } = this.props;
    const { invoice } = this.state;
    dataProvider(UPDATE, 'businessInvoice', { actionType: 'updateStatus', invoiceId: invoice.id, status: 'CANCELLED' })
      .then(() => {
        dispatch(showNotification('notification.invoices.canceledSuccessfully'));
      })
      .catch(e => {
        console.error(e);
        dispatch(
          showNotification('notification.invoices.canceledFailure', 'info', { messageArgs: { error_message: e } })
        );
      })
      .finally(() => {
        this.toggleCancelDialog();
        dispatch(refreshView());
      });
  };

  openRefundDialog = invoice => {
    const { dataProvider } = this.props;
    this.toggleRefundDialog();
    this.setState({ invoice });
    dataProvider(GET_ONE, 'businessInvoice', { field: 'getRefundableAmount', invoiceId: invoice.id })
      .then(({ data }) => {
        this.setState({ refundableAmount: data.refundableAmount, amount: data.refundableAmount });
      })
      .catch(e => {
        console.error(`Error: ${e}`);
      });
  };

  toggleRefundDialog = () => {
    const { isRefundDialogOpen } = this.state;
    this.setState({ isRefundDialogOpen: !isRefundDialogOpen, amount: 0 });
  };

  setAmount = amount => {
    this.setState({ amount });
  };

  onRefund = () => {
    const { dataProvider, dispatch } = this.props;
    const { invoice, amount } = this.state;
    dataProvider(GET_ONE, 'businessInvoice', { field: 'refund', invoiceId: invoice.id, amount })
      .then(() => {
        dispatch(showNotification('notification.invoices.refundedSuccessfully'));
      })
      .catch(e => {
        console.error(e);
        dispatch(
          showNotification('notification.invoices.refundedFailure', 'info', { messageArgs: { error_message: e } })
        );
      })
      .finally(() => {
        this.toggleRefundDialog();
        dispatch(refreshView());
      });
  };

  downloadRefundNote = invoiceId => {
    const { dataProvider, dispatch } = this.props;
    dataProvider(GET_ONE, 'businessInvoicePdf', { field: 'getRefundNoteByInvoiceId', invoiceId })
      .then(({ data }) => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([data], { type: 'application/pdf' });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob);
          return;
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const objectUrl = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = objectUrl;
        link.download = 'refundnote.pdf';
        document.body.appendChild(link);
        link.click();

        // For Firefox it is necessary to delay revoking the ObjectURL
        setTimeout(() => {
          document.body.removeChild(link);
          window.URL.revokeObjectURL(data);
        }, 100);
      })
      .catch(e => {
        console.error(e);
        dispatch(
          showNotification('notification.invoices.refundNoteFailure', 'info', { messageArgs: { error_message: e } })
        );
      });
  };

  render() {
    const { translate, id, permissions, checkPermission, timezone, dispatch, ...props } = this.props;
    const {
      filterValues,
      currentSelection,
      perPage,
      isSetAsPaidDialogOpen,
      isCancelDialogOpen,
      isRefundDialogOpen,
      invoice,
      invoiceId,
      amount,
      refundableAmount,
      backendFilter,
      isFilteredDatesOverLimit,
    } = this.state;
    const {
      downloadPdf,
      setFilters,
      resetDates,
      openSetAsPaidDialog,
      toggleSetAsPaidDialog,
      openCancelDialog,
      toggleCancelDialog,
      onCancel,
      openRefundDialog,
      toggleRefundDialog,
      setAmount,
      onRefund,
      downloadRefundNote,
      setInvoiceToPending,
    } = this;
    const record = { id };
    const filter = { ...backendFilter, timezone };

    const datagridProps = { ...this.props };
    ['classes', 'translate', 'downloadPdf', 'dataProvider', 'dispatch'].forEach(propName => {
      delete datagridProps[propName];
    });

    const invoiceFieldsToRender = {
      translate,
      downloadPdf,
      openCancelDialog,
      openRefundDialog,
      downloadRefundNote,
      setInvoiceToPending,
      permissions,
      checkPermission,
      openSetAsPaidDialog,
    };

    return (
      <>
        <InvoiceFilters
          translate={translate}
          filterValues={filterValues}
          setFilters={setFilters}
          resetDates={resetDates}
          isFilteredDatesOverLimit={isFilteredDatesOverLimit}
        />
        <Header label="menu.invoice" />
        <ReferenceManyField
          {...props}
          pagination={<Pagination ref={this.pagination} rowsPerPageOptions={[10, 25, 50]} />}
          perPage={perPage}
          record={record}
          filter={filter}
          reference="businessInvoice"
          target="id"
          currentSelection={currentSelection}
        >
          <CustomDatagrid
            {...datagridProps}
            onColumnChange={selection => {
              this.setState({ currentSelection: selection });
            }}
            defaultColumns={['total', 'type', 'status', 'downloadButton']}
            id="Selenium-Business-Invoices-Table"
          >
            {InvoicesFields(invoiceFieldsToRender)}
          </CustomDatagrid>
        </ReferenceManyField>
        <RefundDialog
          isOpen={isRefundDialogOpen}
          toggleDialog={toggleRefundDialog}
          invoice={invoice}
          setAmount={setAmount}
          amount={amount}
          onRefund={onRefund}
          refundableAmount={refundableAmount}
          translate={translate}
        />
        <CancelDialog
          isOpen={isCancelDialogOpen}
          toggleDialog={toggleCancelDialog}
          invoice={invoice}
          onCancel={onCancel}
          translate={translate}
        />
        <SetAsPaidDialog
          isOpen={isSetAsPaidDialogOpen}
          toggleDialog={toggleSetAsPaidDialog}
          invoiceId={invoiceId}
          translate={translate}
          dispatch={dispatch}
        />
      </>
    );
  }
}

InvoiceList.propTypes = {
  dataProvider: PropTypes.func,
  dispatch: PropTypes.func,
  translate: PropTypes.func,
  id: PropTypes.string,
  checkPermission: PropTypes.func,
  timezone: PropTypes.string,
  permissions: PropTypes.string,
};

const mapStateToProps = state => ({
  timezone: state.configuration.fleet.timezone,
});

export default compose(
  withGrants,
  withDataProvider,
  translateHOC,
  connect(mapStateToProps)
)(InvoiceList);

// For Tests
export const InvoiceListTest = InvoiceList;
