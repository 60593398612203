import { AUTH_GET_PERMISSIONS, AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK } from 'react-admin';
import moment from 'moment';
import axios from 'axios';
import utils from '../shared/utils';
import configRoutes from './config-routes';
import settings from './settings';

const logout = () => {
  // clearing all entries except the columns settings
  const filteredLocalStorageKeys = Object.keys(localStorage).filter(el => el !== 'raColumnsConfigurations');
  filteredLocalStorageKeys.forEach(key => {
    localStorage.removeItem(key);
  });

  // clearing all entries except the selectedHost (for development)
  const filteredSessionStorageKeys = Object.keys(sessionStorage).filter(el => el !== 'selectedHost');
  filteredSessionStorageKeys.forEach(key => {
    sessionStorage.removeItem(key);
  });

  // Force reload the page to completely clear any remaining data
  window.location.reload();
};

const saveToken = res => {
  sessionStorage.setItem(
    'accessToken',
    JSON.stringify({
      token: res.access_token,
      expiresIn: res.expires_in + moment().unix(),
    })
  );
  localStorage.setItem(
    'refreshToken',
    JSON.stringify({
      refreshToken: res.refresh_token,
      refreshExpiresIn: res.refresh_expires_in + moment().unix(),
    })
  );
};

const decodeToken = tokenObj => {
  const accesstoken = tokenObj.access_token;
  const tokendata = utils.getDecodeToken(accesstoken);
  const { userId, fl = [''], of = '' } = tokendata;
  let currentFleet = '';
  if (fl.includes(of)) {
    currentFleet = of;
  } else {
    [currentFleet] = fl;
    if (!fl.includes(currentFleet)) {
      [currentFleet] = fl;
    }
  }
  if (tokendata && tokendata.scope && tokendata.scope[currentFleet] && tokendata.scope[currentFleet].name) {
    saveToken(tokenObj);
    const role = tokendata.scope[currentFleet].name;
    let superRole = 'user';
    if (role === 'staff' || role === 'manager') {
      superRole = 'admin';
    }
    localStorage.setItem('role', superRole);
    localStorage.setItem('fleetID', currentFleet);
    return [userId, currentFleet];
  }
  throw new Error(utils.error('401'));
};

const tokenFetchPromise = (config, instance, params) => {
  const { username, password } = params;
  const paramSearch = new URLSearchParams();
  paramSearch.append('username', username);
  paramSearch.append('password', password);
  paramSearch.append('client_id', config.login.client_id);
  paramSearch.append('client_secret', config.login.client_secret);
  paramSearch.append('securityOptions', config.login.securityOptions);
  paramSearch.append('grant_type', 'password');

  const request = {
    url: `/auth/realms/${config.business_ws.fleetId}/protocol/openid-connect/token`,
    method: 'POST',
    body: paramSearch,
  };

  return instance
    .post(request.url, request.body)
    .then(res => {
      const { data } = res;
      if (data.access_token) {
        return Promise.resolve(data);
      }
      return Promise.reject();
    })
    .catch(err => {
      throw new Error(utils.error(err));
    });
};

const tokenFetchPromise2FA = (config, instance, params) => {
  const { username, password, recaptchaToken, recaptchaSiteKey } = params;
  const paramSearch = new URLSearchParams();
  const { dispatch } = utils.getStore();
  paramSearch.append('username', username);
  paramSearch.append('password', password);
  paramSearch.append('recaptcha_token', recaptchaToken);
  paramSearch.append('recaptcha_site_key', recaptchaSiteKey);
  paramSearch.append('client_id', config.login.client_id);
  paramSearch.append('client_secret', config.login.client_secret);
  paramSearch.append('securityOptions', config.login.securityOptions);
  paramSearch.append('grant_type', 'password');

  const request = {
    url: `/auth/realms/${config.business_ws.fleetId}/protocol/openid-connect/token`,
    method: 'POST',
    body: paramSearch,
  };

  return instance
    .post(request.url, request.body)
    .then(res => {
      const { data } = res;
      if (data.access_token) {
        return Promise.resolve(data);
      }
      return Promise.reject();
    })
    .catch(err => {
      if (err.response.status === 401 && err.response.data && err.response.data.error === '2FA') {
        // save the info coming from the auth server
        dispatch({
          type: 'LOGIN_SAVE_2FA_DATA',
          payload: { sessionId: err.response.data.session_id, pincodeCheckUrl: err.response.data.url },
        });
        return Promise.reject();
      }
      throw new Error(utils.error(err));
    });
};

const userFetchPromise = (userId, currentFleet, config) => {
  const accessToken = JSON.parse(sessionStorage.getItem('accessToken')).token;
  const options = {
    timeout: config.timeout || 30000,
    headers: {
      Accept: 'application/json',
      'X-API-KEY': config.login.x_api_key,
      Authorization: `Bearer ${accessToken}`,
    },
  };
  const { dispatch } = utils.getStore();
  return fetch(`${config.business_ws.baseUrl}/business/fleets/${currentFleet}/users/${userId}`, options)
    .then(res => res.json())
    .then(resData => {
      const userData = resData;
      const { entityRoles, ...userDetails } = userData;
      localStorage.setItem('businessRole', JSON.stringify(entityRoles));
      dispatch({
        type: 'LOGIN_SAVE_USER_DATA',
        payload: { ...userDetails },
      });
      // Reject login in case this user is not a Business Admin, nor Business Owner, nor delegated admin
      // for any businesses
      if (utils.getRoles() === 'customer') {
        return Promise.reject(new Error('notification.customerDeny'));
      }
      return Promise.resolve();
    })
    .catch(err => {
      throw new Error(utils.error(err));
    });
};

const refreshTokenPromise = (config, instance, refreshToken) => {
  const bodyFormData = new URLSearchParams();
  bodyFormData.append('refresh_token', refreshToken);
  bodyFormData.append('client_id', config.login.client_id);
  bodyFormData.append('client_secret', config.login.client_secret);
  bodyFormData.append('securityOptions', config.login.securityOptions);
  bodyFormData.append('grant_type', 'refresh_token');
  const request = {
    url: `/auth/realms/${config.business_ws.fleetId}/protocol/openid-connect/token`,
    method: 'POST',
    body: bodyFormData,
  };
  return instance
    .post(request.url, request.body)
    .then(res => {
      const { data } = res;
      if (data.access_token) {
        return Promise.resolve(data);
      }
      return Promise.reject();
    })
    .catch(err => {
      throw new Error(utils.error(err));
    });
};

async function loginFlow(config, instance, params) {
  const { grecaptcha } = window;
  if (!grecaptcha) {
    // normal flow
    const tokenObj = await tokenFetchPromise(config, instance, params);
    const [userId, fleetId] = decodeToken(tokenObj);

    if (userId) {
      await userFetchPromise(userId, fleetId, config);
    }
  } else {
    // two factor authentication flow
    const loginInfo = config.login;
    const reCAPTCHAsiteKey = () => {
      if (loginInfo && loginInfo.reCAPTCHAsiteKey) {
        return process.env.NODE_ENV === 'development'
          ? '6LcDgOUUAAAAAMfJPNZV5fRhf5DsUU2KcV9TvXZH'
          : loginInfo.reCAPTCHAsiteKey;
      }
      return '';
    };
    if (reCAPTCHAsiteKey) {
      let errMsg = '';
      const reCAPTCHAtoken = await grecaptcha.enterprise.execute(reCAPTCHAsiteKey(), { action: 'login' });
      const params2fa = { ...params, recaptchaToken: reCAPTCHAtoken, recaptchaSiteKey: reCAPTCHAsiteKey() };
      try {
        const tokenObj = await tokenFetchPromise2FA(config, instance, params2fa);
        if (tokenObj) {
          const [userId, fleetId] = decodeToken(tokenObj);
          if (userId) {
            await userFetchPromise(userId, fleetId, config);
            return Promise.resolve();
          }
        }
      } catch (err) {
        errMsg = err;
      }
      if (errMsg) {
        return Promise.reject(errMsg);
      }
      return Promise.reject(new Error('Please enter the pin code'));
    }
  }
  return Promise.resolve();
}

async function refreshFlow(config, instance) {
  const refreshToken = JSON.parse(localStorage.getItem('refreshToken'));
  const rt = refreshToken.refreshToken;
  const tokenObj = await refreshTokenPromise(config, instance, rt);
  const [userId, fleetId] = decodeToken(tokenObj);
  if (userId) {
    await userFetchPromise(userId, fleetId, config);
  }
  await settings.load();
  return Promise.resolve();
}

const isExpired = timeStr => {
  const time = parseInt(timeStr, 10);
  if (moment().unix() > time) {
    return true;
  }
  return false;
};

const provider = (config, instance, type, params) => {
  switch (type) {
    case AUTH_LOGIN: {
      return loginFlow(config, instance, params);
    }

    case AUTH_LOGOUT: {
      logout();
      return Promise.resolve();
    }

    case AUTH_ERROR: {
      const { status } = params;
      if (status === 401 || status === 403) {
        sessionStorage.removeItem('accessToken');
        return Promise.reject();
      }
      return Promise.resolve();
    }

    case AUTH_GET_PERMISSIONS: {
      const role = localStorage.getItem('role');
      let needPermission = true;

      Object.keys(configRoutes).forEach(route => {
        if (window.location.hash.indexOf(configRoutes[route].path) === 1) {
          needPermission = false;
        }
      });

      if (!needPermission) {
        return Promise.resolve();
      }

      return role ? Promise.resolve(role) : Promise.reject();
    }

    case AUTH_CHECK: {
      if (!localStorage.getItem('refreshToken')) {
        logout();
        return Promise.reject();
      }
      //
      const { refreshExpiresIn } = JSON.parse(localStorage.getItem('refreshToken'));
      if (isExpired(refreshExpiresIn)) {
        logout();
        return Promise.reject();
      }
      //
      if (!sessionStorage.getItem('accessToken')) {
        return refreshFlow(config, instance);
      }
      //
      const { expiresIn } = JSON.parse(sessionStorage.getItem('accessToken'));
      if (isExpired(expiresIn)) {
        return refreshFlow(config, instance);
      }

      return Promise.resolve();
    }
    default:
      return Promise.reject(new Error('Unknown method'));
  }
};

const authProvider = config => {
  const instance = axios.create({
    baseURL: config.login.baseUrl,
    timeout: config.timeout || 30000,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  // A little note about the authProvider . Explanation on why we pass an axios instance.
  // why do we inject the dependency of the axios instance respect to a simple fetch?
  // The added complexity is worth it because we can mock the axios request  in this way ( see the tests that come along )

  return provider.bind(null, config, instance);
};

export { provider, refreshTokenPromise, refreshFlow, decodeToken, userFetchPromise, authProvider as default };
