import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { Chip, MenuItem, TextField, Typography, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { translate as translateHOC } from 'react-admin';
import Select from 'react-select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const styles = theme => ({
  input: {
    display: 'flex',
    padding: 0,
  },
  inputFormControl: {
    marginTop: '12px !important',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing.unit / 4}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  arrowDropdown: {
    top: 'calc(50% - 12px)',
    right: 0,
    color: 'rgba(0, 0, 0, 0.54)',
    position: 'absolute',
  },
  root: {},
});

const NoOptionsMessage = ({ selectProps: { classes }, innerProps, children }) => (
  <Typography color="textSecondary" className={classes.noOptionsMessage} {...innerProps}>
    {children}
  </Typography>
);
NoOptionsMessage.propTypes = {
  selectProps: PropTypes.shape({
    classes: PropTypes.shape({
      noOptionsMessage: PropTypes.string,
    }),
  }),
  innerProps: PropTypes.PropTypes.shape({}),
  children: PropTypes.node,
};

const InputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />;
InputComponent.propTypes = {
  inputRef: PropTypes.func,
};

const Control = ({ selectProps: { classes, textFieldProps }, innerRef, innerProps, children }) => (
  <TextField
    fullWidth
    InputProps={{
      inputComponent: InputComponent,
      inputProps: {
        className: classes.input,
        inputRef: innerRef,
        children,
        ...innerProps,
      },
      classes: {
        formControl: classes.inputFormControl,
      },
    }}
    {...textFieldProps}
  />
);
Control.propTypes = {
  selectProps: PropTypes.shape({
    classes: PropTypes.shape({
      textFieldProps: PropTypes.string,
    }),
  }),
  innerProps: PropTypes.PropTypes.shape({}),
  children: PropTypes.node,
  innerRef: PropTypes.func,
};

const Option = ({ innerRef, isFocused, isSelected, innerProps, children }) => (
  <MenuItem
    buttonRef={innerRef}
    selected={isFocused}
    component="div"
    style={{
      fontWeight: isSelected ? 500 : 400,
    }}
    {...innerProps}
  >
    {children}
  </MenuItem>
);
Option.propTypes = {
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
  innerProps: PropTypes.PropTypes.shape({}),
  children: PropTypes.node,
  innerRef: PropTypes.func,
};

const ValueContainer = ({ selectProps: { classes }, children }) => (
  <div className={classes.valueContainer}>{children}</div>
);
ValueContainer.propTypes = {
  selectProps: PropTypes.shape({
    classes: PropTypes.shape({
      valueContainer: PropTypes.string,
    }),
  }),
  children: PropTypes.node,
};

const MultiValue = ({ selectProps: { classes }, children, isFocused, removeProps: { onClick, onMouseDown } }) => (
  <Chip
    tabIndex={-1}
    label={children}
    className={classNames(classes.chip, {
      [classes.chipFocused]: isFocused,
    })}
    onDelete={event => {
      onClick();
      onMouseDown(event);
    }}
  />
);
MultiValue.propTypes = {
  selectProps: PropTypes.shape({
    classes: PropTypes.shape({
      valueContainer: PropTypes.string,
    }),
  }),
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.shape({
    onClick: PropTypes.func,
    onMouseDown: PropTypes.func,
  }),
};

const DropdownIndicator = ({ selectProps: { classes } }) => (
  <ArrowDropDownIcon classes={{ root: classes.arrowDropdown }} />
);
DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape({
    classes: PropTypes.shape({
      valueContainer: PropTypes.string,
    }),
  }),
};

const selectComponents = {
  Option,
  Control,
  NoOptionsMessage,
  MultiValue,
  ValueContainer,
  DropdownIndicator,
  ClearIndicator: null,
  IndicatorSeparator: null,
};

const AutocompleteChips = ({
  classes,
  label,
  translate,
  options = [],
  isMulti,
  onChange,
  defaultValue,
  placeholder,
}) => (
  <Select
    className={classes.root}
    classes={classes}
    textFieldProps={{
      label: translate(label),
      InputLabelProps: {
        shrink: true,
      },
    }}
    options={options}
    components={selectComponents}
    onChange={onChange}
    isMulti={isMulti}
    placeholder={placeholder}
    defaultValue={defaultValue}
    closeMenuOnSelect={false}
  />
);

const optionPropType = PropTypes.shape({
  value: PropTypes.string,
  name: PropTypes.string,
});

AutocompleteChips.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([null, optionPropType, PropTypes.arrayOf(optionPropType)]),
  classes: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(optionPropType),
  isMulti: PropTypes.bool,
  placeholder: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default translateHOC(withStyles(styles)(AutocompleteChips));
