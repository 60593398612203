import DomainIcon from '@material-ui/icons/Domain';
import BusinessList from './business-list';
import BusinessDetails from './business-details';
import businessCreate from './create/index';

export default {
  list: BusinessList,
  edit: BusinessDetails,
  create: businessCreate,
  icon: DomainIcon,
};
