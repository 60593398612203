import { CHANGE_LOCALE_SUCCESS } from 'react-admin';
import { takeEvery } from 'redux-saga/effects';

function changeLocaleRequest({ payload }) {
  const { locale } = payload;
  sessionStorage.setItem('language', locale);
}

export default function* localeSaga() {
  yield takeEvery(CHANGE_LOCALE_SUCCESS, changeLocaleRequest);
}
