import { GET_LIST, GET_MANY } from 'react-admin';

const dataBusinessOwner = (res, params, type) => {
  let data = res;
  switch (type) {
    case GET_MANY:
    case GET_LIST:
      data = {
        data: res,
        total: res.length,
      };
      break;
    default:
      break;
  }
  return data;
};

export default dataBusinessOwner;
