import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import { translate as translateHOC } from 'react-admin';

const RejectSuspendConfirmationDialog = ({ showDialog, hideDialog, onConfirm, status, translate }) => {
  return (
    <Dialog open={showDialog} onClose={hideDialog}>
      <DialogContent>
        <Typography>
          {status === 'REJECTED' && translate('resources.businessUser.list.confirmationText.reject')}
          {status === 'SUSPENDED' && translate('resources.businessUser.list.confirmationText.suspend')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onConfirm}>
          {status === 'REJECTED' && translate('resources.businessUser.list.statusButtons.reject')}
          {status === 'SUSPENDED' && translate('resources.businessUser.list.statusButtons.suspend')}
        </Button>
        <Button color="primary" onClick={hideDialog}>
          {translate('common.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
RejectSuspendConfirmationDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  hideDialog: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
};

export default translateHOC(RejectSuspendConfirmationDialog);
