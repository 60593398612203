import React, { useState } from 'react';
import PropTypes from 'prop-types';
import IconEdit from '@material-ui/icons/Edit';
import { Button, CardActions } from 'react-admin';
import Divider from '../../../shared/divider';
import withGrants from '../../WithGrants';
import AddCreditsDialog from './AddCreditsDialog';

const CreditsListActions = ({ handleCreateClick }) => (
  <CardActions>
    <Divider hasNoBorder />
    <Button
      onClick={handleCreateClick}
      label="credits.list.button.addCredits"
      size="medium"
      id="Selenium-Business-Credits-AddCredits-Button"
    >
      <IconEdit />
    </Button>
  </CardActions>
);
CreditsListActions.propTypes = {
  handleCreateClick: PropTypes.func,
};

const AddCredits = ({ checkPermission, ...props }) => {
  const [isDialogVisible, setDialogVisible] = useState(false);

  if (!checkPermission('UPDATE_BUSINESS_CREDIT')) {
    return null;
  }

  const handleCreateClick = () => setDialogVisible(true);

  const handleCloseDialogAdd = () => setDialogVisible(false);

  return (
    <>
      <CreditsListActions {...props} handleCreateClick={handleCreateClick} />
      <AddCreditsDialog {...props} isShowDialog={isDialogVisible} handleCloseDialog={handleCloseDialogAdd} />
    </>
  );
};
AddCredits.propTypes = {
  checkPermission: PropTypes.func.isRequired,
};

export default withGrants(AddCredits);

// For Tests
export const CreditsListActionsTest = CreditsListActions;
