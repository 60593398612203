import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import { withStyles, createStyles } from '@material-ui/core/styles';
import compose from 'recompose/compose';
import classnames from 'classnames';

import {
  hideNotification as hideNotificationHOC,
  getNotification as getNotificationHOC,
  translate as translateHOC,
  undo as undoHOC,
  complete as completeHOC,
} from 'ra-core';

const styles = theme =>
  createStyles({
    confirm: {
      backgroundColor: theme.palette.background.default,
    },
    warning: {
      backgroundColor: theme.palette.error.light,
    },
    undo: {
      color: theme.palette.primary.main,
    },
  });

class Notification extends React.Component {
  state = {
    open: false,
  };

  componentWillMount = () => {
    this.setOpenState(this.props);
  };

  componentWillReceiveProps = nextProps => {
    this.setOpenState(nextProps);
  };

  setOpenState = ({ notification }) => {
    this.setState({
      open: !!notification,
    });
  };

  handleRequestClose = () => {
    this.setState({
      open: false,
    });
  };

  handleExited = () => {
    const { notification, hideNotification, complete } = this.props;
    if (notification && notification.undoable) {
      complete();
    }
    hideNotification();
  };

  render() {
    const {
      undo,
      complete,
      classes,
      className,
      type,
      translate,
      notification,
      autoHideDuration,
      hideNotification,
      ...rest
    } = this.props;
    const {
      warning,
      confirm,
      undo: undoClass, // Rename classes.undo to undoClass in this scope to avoid name conflicts
      ...snackbarClasses
    } = classes;
    const { open } = this.state;
    return (
      <Snackbar
        open={open}
        message={notification && notification.message && translate(notification.message, notification.messageArgs)}
        autoHideDuration={(notification && notification.autoHideDuration) || autoHideDuration}
        disableWindowBlurListener={notification && notification.undoable}
        onExited={this.handleExited}
        onClose={this.handleRequestClose}
        ContentProps={{
          className: classnames(classes[(notification && notification.type) || type], className),
        }}
        action={
          notification && notification.undoable ? (
            <Button color="primary" className={undoClass} size="small" onClick={undo}>
              {translate('ra.action.undo')}
            </Button>
          ) : null
        }
        classes={snackbarClasses}
        {...rest}
      />
    );
  }
}

Notification.propTypes = {
  complete: PropTypes.func,
  classes: PropTypes.shape({}),
  className: PropTypes.string,
  notification: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.string,
    autoHideDuration: PropTypes.number,
    messageArgs: PropTypes.object,
  }),
  type: PropTypes.string,
  hideNotification: PropTypes.func,
  autoHideDuration: PropTypes.number,
  translate: PropTypes.func,
  undo: PropTypes.func,
};

Notification.defaultProps = {
  type: 'info',
  autoHideDuration: 4000,
};

const mapStateToProps = state => ({
  notification: getNotificationHOC(state),
});

export default compose(
  translateHOC,
  withStyles(styles),
  connect(
    mapStateToProps,
    {
      complete: completeHOC,
      hideNotification: hideNotificationHOC,
      undo: undoHOC,
    }
  )
)(Notification);
