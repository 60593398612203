import { GET_ONE } from 'react-admin';

const dataAdyen = (instance, type, params, config) => {
  const opt = {};
  switch (type) {
    case GET_ONE:
      switch (params.field) {
        case 'paymentSession':
          opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/adyen/${params.id}/paymentSession`;
          opt.method = 'GET';
          break;
        case 'paymentResult':
          opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/adyen/paymentResult`;
          opt.data = { payload: params.payload };
          opt.method = 'POST';
          break;
        default:
          throw new Error(`Need provide a field to get`);
      }
      break;
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
  return instance(opt);
};

export default dataAdyen;
