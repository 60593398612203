import React from 'react';
import PropTypes from 'prop-types';
import {
  withDataProvider,
  refreshView,
  showNotification,
  translate as translateHOC,
  SimpleForm,
  LongTextInput,
  Edit,
  SimpleShowLayout,
  Button,
  fetchStart,
  fetchEnd,
  UPDATE,
  SaveButton,
} from 'react-admin';
import { compose } from 'redux';
import { submit, isSubmitting as isSubmittingAction, destroy } from 'redux-form';
import { connect } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, withStyles } from '@material-ui/core';
import IconCancel from '@material-ui/icons/Cancel';
import { BusinessDetails } from './business-details';
import { BusinessAddress } from './business-address';
import Header from '../../../shared/header';
import Divider from '../../../shared/divider';
import utils from '../../../shared/utils';
import { maxLength } from '../../../core/validators';
import { savePrivateFleets } from '../../../core/services';
import { associatedServicesSelector } from '../../../core/selectors';

const styles = () => ({
  buttonToolbar: {
    marginRight: '35px',
    '& button:first-child': {
      marginRight: 0,
    },
  },
  fillRequired: {
    width: '100%',
    marginLeft: '1rem',
  },
});

const Toolbar = ({ invalid, invalidCallback }) => {
  invalidCallback(invalid);
  return null;
};

Toolbar.propTypes = {
  invalid: PropTypes.bool,
  invalidCallback: PropTypes.func,
};

class BusinessEdit extends React.PureComponent {
  state = {
    invalid: false,
    privateFleetSelection: [],
    privateFleetUpdated: false,
  };

  constructor(props) {
    super(props);

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(destroy('business-details-edit'));
  }

  setPrivateFleetSelection = privateFleetSelection => {
    this.setState({ privateFleetSelection, privateFleetUpdated: true });
  };

  handleInvalid = value => {
    this.setState({ invalid: value });
  };

  handleSaveClick() {
    const { dispatch } = this.props;
    dispatch(submit('business-details-edit'));
  }

  handleSubmit(record) {
    const updatedRecord = { ...record, is_approved: true };
    const { dispatch, handleCloseDialog, dataProvider, privateFleetDefaultOptions } = this.props;
    const { privateFleetSelection, privateFleetUpdated } = this.state;
    // We have to use utils because record.id contains the id of the previous opened business...
    const { business } = utils.getParameters();
    dispatch(fetchStart());

    let savePrivateFleetsPromise;

    if (privateFleetUpdated) {
      const defaultIds = privateFleetDefaultOptions.map(element => element.value);
      const selectionIds = privateFleetSelection.map(element => element.value);
      const [privateFleetsAdded, privateFleetsRemoved] = utils.getArrayDiff(defaultIds, selectionIds);

      savePrivateFleetsPromise = savePrivateFleets(business.id, privateFleetsAdded, privateFleetsRemoved);
    }

    const dataProviderPromise = dataProvider(UPDATE, 'business', { id: business.id, data: updatedRecord });

    return Promise.all([savePrivateFleetsPromise, dataProviderPromise])
      .then(() => {
        dispatch(showNotification('notification.business.updateSuccess'));
        handleCloseDialog();
        dispatch(refreshView());
      })
      .catch(err => {
        const errObject = JSON.parse(err.message);

        const { errorCode, httpStatusCode, httpStatus, message } = errObject;

        console.error(`Error: ${httpStatusCode}`, {
          httpStatusCode,
          httpStatus,
          errorCode,
          message,
        });
        if (errorCode === 'NF0013') {
          dispatch(showNotification('notification.business.MOPmissing', 'warning'));
        } else {
          dispatch(showNotification(`error.${errorCode}: ${message}`, 'warning'));
        }
      })
      .finally(dispatch(fetchEnd()));
  }

  render() {
    const {
      isSubmitting,
      dispatch,
      translate,
      isShowDialog,
      handleCloseDialog,
      dataProvider,
      record,
      classes,
      resource,
      privateFleetDefaultOptions,
      permissions,
      ...props
    } = this.props;
    const { invalid } = this.state;
    const { entityId, checkPermission, ...sanitizedProps } = props;
    const isAdmin = permissions === 'admin';

    return (
      <>
        <Dialog maxWidth="md" fullWidth open={isShowDialog} onClose={handleCloseDialog}>
          <DialogTitle>{translate('resources.business.edit.title')}</DialogTitle>
          <DialogContent>
            <Edit title=" " resource={resource} {...sanitizedProps}>
              <SimpleForm
                form="business-details-edit"
                onSubmit={this.handleSubmit}
                toolbar={<Toolbar invalidCallback={this.handleInvalid} />}
                translate={translate}
              >
                <BusinessDetails
                  isAdmin={isAdmin}
                  resource={resource}
                  classes={classes}
                  translate={translate}
                  privateFleet={{
                    privateFleetDefaultOptions,
                    setPrivateFleetSelection: this.setPrivateFleetSelection,
                  }}
                />
                <Divider />
                <BusinessAddress resource={resource} classes={classes} />
                <Divider />
                <Header label="common.null" />
                <SimpleShowLayout resource={resource} className={classes.fullWidth}>
                  <LongTextInput
                    className={classes.commentWidth}
                    validate={[maxLength(255, translate('validation.maxLength'))]}
                    source="comment"
                    id="Selenium-Business-Edit-comment-Input"
                  />
                </SimpleShowLayout>
              </SimpleForm>
            </Edit>
            <Typography className={classes.fillRequired}>{translate('validation.fillRequired')}</Typography>
          </DialogContent>
          <DialogActions className={classes.buttonToolbar}>
            <Button
              label="common.cancel"
              onClick={handleCloseDialog}
              size="medium"
              id="Selenium-Business-Edit-Cancel-Button"
            >
              <IconCancel />
            </Button>
            <SaveButton
              saving={isSubmitting}
              onClick={this.handleSaveClick}
              invalid={invalid}
              disabled={invalid}
              id="Selenium-Business-Edit-Save-Button"
            />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

BusinessEdit.propTypes = {
  dataProvider: PropTypes.func,
  isSubmitting: PropTypes.bool,
  dispatch: PropTypes.func,
  translate: PropTypes.func,
  isShowDialog: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
  record: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  resource: PropTypes.string,
  privateFleetDefaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  permissions: PropTypes.string,
};

const mapStateToProps = state => ({
  isSubmitting: isSubmittingAction('business-details-edit')(state),
  privateFleetDefaultOptions: associatedServicesSelector(state).reduce((acc, service) => {
    if (service.visibility === 'PRIVATE') {
      acc.push({ label: service.name, value: service.id });
    }
    return acc;
  }, []),
});

export default compose(
  withStyles(styles),
  withDataProvider,
  translateHOC,
  connect(mapStateToProps)
)(BusinessEdit);
// For Tests
export const BusinessEditTest = BusinessEdit;
