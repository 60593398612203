import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  translate as translateHOC,
  withDataProvider,
  Responsive,
  List,
  Pagination,
  FunctionField,
  fetchStart,
  fetchEnd,
  CREATE,
  showNotification,
  crudUpdateMany as crudUpdateManyHOC,
} from 'react-admin';
import compose from 'recompose/compose';
import { reset } from 'redux-form';
import { Card, Button, withStyles } from '@material-ui/core';
import queryString from 'query-string';
import InviteUserIcon from '@material-ui/icons/Email';
import BulkInviteSimpleIcon from '@material-ui/icons/GetApp';
import { getBusinessConf } from '../../../core/config';
import UserRoleDatagrid from './user-role-datagrid';
import UserEdit from '../edit';
import withGrants from '../../WithGrants';
import InviteImportBulkDialog from './invite-import-bulk';
import InviteCreateDialog from './invite-create';
import RejectSuspendConfirmationDialog from './RejectSuspendConfirmationDialog';

const roleTabs = [
  {
    id: 'customer',
    name: 'resources.businessUser.list.role.customer',
  },
  {
    id: 'delegated admin',
    name: 'resources.businessUser.list.role.delegatedAdmin',
  },
];

const statusTabs = [
  {
    id: 'APPROVED',
    name: 'resources.businessUser.list.statusTabs.approved',
    buttonName: 'resources.businessUser.list.statusButtons.approve',
  },
  {
    id: 'REJECTED',
    name: 'resources.businessUser.list.statusTabs.rejected',
    buttonName: 'resources.businessUser.list.statusButtons.reject',
  },
  {
    id: 'SUSPENDED',
    name: 'resources.businessUser.list.statusTabs.suspended',
    buttonName: 'resources.businessUser.list.statusButtons.suspend',
  },
  { id: '', name: 'resources.businessUser.list.statusTabs.all' },
];

const Actions = ({ classes, translate, checkPermission, onOpenInviteCreateDialog, onBulkSimple, onBulkInvite }) => {
  return (
    <>
      {checkPermission('ADD_BUSINESS_USER') && (
        <div className={classes.actions}>
          <FunctionField
            render={() => (
              <Button
                size="medium"
                color="secondary"
                onClick={onBulkSimple}
                id="Selenium-Users-Index-Button-bulkInviteSample"
              >
                <BulkInviteSimpleIcon />
                &nbsp;{translate('resources.businessUser.list.bulkActions.bulkInviteSample')}
              </Button>
            )}
          />
          <FunctionField
            render={() => (
              <Button
                size="medium"
                color="secondary"
                onClick={onBulkInvite}
                id="Selenium-Users-Index-Button-bulkInvite"
              >
                <InviteUserIcon />
                &nbsp;{translate('resources.businessUser.list.bulkActions.bulkInviteUsers')}
              </Button>
            )}
          />
          <FunctionField
            render={() => (
              <Button
                size="medium"
                color="secondary"
                onClick={onOpenInviteCreateDialog}
                id="Selenium-Users-Index-Button-Invite"
              >
                <InviteUserIcon />
                &nbsp;{translate('resources.businessUser.list.bulkActions.inviteUser')}
              </Button>
            )}
          />
        </div>
      )}
    </>
  );
};

Actions.propTypes = {
  classes: PropTypes.shape({}),
  translate: PropTypes.func,
  checkPermission: PropTypes.func,
  onOpenInviteCreateDialog: PropTypes.func,
  onBulkSimple: PropTypes.func,
  onBulkInvite: PropTypes.func,
};

const BulkActions = ({ exceptDefault, translate, changeStatus, selectedIds }) => {
  const result = [];
  const [itemId, setItemId] = useState('');
  const showDialog = !!itemId;

  const handleConfirm = () => changeStatus(itemId, selectedIds);

  const hideDialog = () => setItemId('');

  const handleActionClick = item => {
    if (item.id === 'APPROVED') {
      changeStatus('APPROVED', selectedIds);
    } else {
      setItemId(item.id);
    }
  };

  statusTabs.forEach(item => {
    if (item.id !== exceptDefault && item.id !== '') {
      result.push(
        <Button
          key={item.id}
          value={item.id}
          onClick={() => handleActionClick(item)}
          id={`Selenium-Users-Index-Status-Button-${item.id}`}
        >
          {translate(item.buttonName)}
        </Button>
      );
    }
  });

  return (
    <>
      <RejectSuspendConfirmationDialog
        showDialog={showDialog}
        hideDialog={hideDialog}
        onConfirm={handleConfirm}
        status={itemId}
      />
      {result.map(item => item)}
    </>
  );
};

BulkActions.propTypes = {
  exceptDefault: PropTypes.string,
  translate: PropTypes.func,
  changeStatus: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.string),
};
const BulkUserActions = connect(
  undefined,
  { crudUpdateManyHOC }
)(BulkActions);

const userListStyle = {
  tabs: {
    width: '100%',
  },
  tabRoot: {
    flexBasis: '100%',
    minWidth: 10,
  },
  wrapper: {
    background: '#DFDFDF',
    marginTop: '10px',
    width: '100%',
    position: 'relative',
  },
  selectedRole: {
    flexBasis: '100%',
    minWidth: 10,
  },
  actions: {
    display: 'flex',
  },
};

const CustomPagination = props => <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />;

class UsersList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.refUserRoleDatagrid = React.createRef();
    this.refInputOpenInviteImportBulkDialog = React.createRef();

    this.changeStatus = this.changeStatus.bind(this);
    this.setStateStatus = this.setStateStatus.bind(this);
    this.setStateRole = this.setStateRole.bind(this);
    this.showHideEditDialog = this.showHideEditDialog.bind(this);

    let defaultUserRole = 'customer';
    let defaultStatus = '';
    const {
      location: { search },
    } = this.props;
    const params = queryString.parse(search);
    let filter = {};

    if (params && params.filter) {
      filter = JSON.parse(params.filter);
    }

    if (filter.userRole && filter.userRole !== undefined && filter.userRole === 'delegated admin') {
      defaultUserRole = filter.userRole;
    }
    if (filter.status && filter.status !== undefined) {
      statusTabs.forEach(item => {
        if (filter.status === item.id) {
          defaultStatus = filter.status;
        }
      });
    }
    this.state = {
      isShowEditDialog: false,
      isShowInviteCreateDialog: false,
      isShowInviteImportBulkDialog: false,
      isShowDetailsInviteImportBulk: false,
      selectedUserId: '',
      status: defaultStatus,
      role: defaultUserRole,
      defaultUserRole,
      defaultStatus,
      inviteBulkList: [],
      fileInviteBulk: null,
      errorMessageInviteImportSubmit: null,
      lang: 'en_GB',
      locales: [{ code: 'en_GB', label: 'English' }],
    };
  }

  componentDidMount() {
    const translations = this.getLanguages();
    if (translations && translations.invoiceLanguage) {
      this.setState({ locales: translations.invoiceLanguage });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { locales, isShowInviteCreateDialog, isShowInviteImportBulkDialog } = this.state;
    if (prevState.locales !== locales) {
      this.setDefaultLanguageState();
    }
    if (prevState.isShowInviteCreateDialog !== isShowInviteCreateDialog && !isShowInviteCreateDialog) {
      this.setDefaultLanguageState();
    }
    if (prevState.isShowInviteImportBulkDialog !== isShowInviteImportBulkDialog && !isShowInviteImportBulkDialog) {
      this.setDefaultLanguageState();
    }
  }

  setDefaultLanguageState = () => this.setState({ lang: this.getDefaultLanguage() });

  getDefaultLanguage = () => {
    const translations = this.getLanguages();
    const re = /[_-]/g;
    const defaultLanguage =
      translations.invoiceLanguage &&
      translations.invoiceLanguage.find(locale => {
        const splitLocale = locale.code.split(re);
        const splitDefaultLang = translations.default.split(re);
        // We have to compare split values because in the conf, we have sometimes en-GB and sometimes en_GB
        if (splitLocale[0] === splitDefaultLang[0]) {
          if (splitLocale[1] === splitDefaultLang[1]) {
            return locale.code;
          }
        }
        return undefined;
      });
    return (defaultLanguage && defaultLanguage.code) || translations.invoiceLanguage[0].code;
  };

  setStateStatus(newStatus) {
    this.setState({ status: newStatus });
  }

  setStateRole(newRole) {
    this.setState({ role: newRole });
  }

  changeStatus = (newStatus, selectedIds) => {
    const {
      id,
      crudUpdateMany,
      location: { pathname },
    } = this.props;
    crudUpdateMany(
      'businessUser',
      selectedIds,
      { businessId: id, updateField: 'status', status: newStatus },
      pathname,
      true
    );
  };

  onSubmitInviteCreate = record => {
    const { dispatch, dataProvider } = this.props;
    const { lang } = this.state;
    dispatch(fetchStart());
    return dataProvider(CREATE, 'invite', { id: record.id, data: { locale: lang, ...record } })
      .then(() => {
        dispatch(reset('invite-create'));
        dispatch(showNotification('notification.inviteSuccess'));
        this.setState({ isShowInviteCreateDialog: false });
      })
      .catch(err => {
        const errObject = JSON.parse(err.message);

        const { errorCode, httpStatusCode, httpStatus } = errObject;

        console.error(`Error: ${httpStatusCode}`, {
          httpStatusCode,
          httpStatus,
          errorCode,
        });
        dispatch(showNotification(`error.${errorCode}`, 'warning'));
      })
      .finally(() => {
        dispatch(fetchEnd());
      });
  };

  onSubmitInviteImportBulk = () => {
    const { onCloseInviteImportBulk } = this;
    const { dispatch, dataProvider, id } = this.props;
    const { fileInviteBulk: file, lang } = this.state;
    dispatch(fetchStart());
    return dataProvider(CREATE, 'invite', { id, data: { file, locale: lang } })
      .then(async ({ data }) => {
        const resultList = [];
        let errorMessageInviteImportSubmit = null;
        if (data.listOfFailed.length > 0) {
          errorMessageInviteImportSubmit = 'notification.bulkInvite.errorOccurs';
          data.listOfFailed.forEach(failedUser => {
            if (failedUser && failedUser.userCSVImport) {
              resultList.push({
                ...failedUser.userCSVImport,
                errorMessage: `error.${failedUser.errorCode}`,
              });
            }
          });
        } else {
          dispatch(showNotification('notification.bulkInvite.uploaded'));
          onCloseInviteImportBulk();
        }
        this.setState({ inviteBulkList: resultList, errorMessageInviteImportSubmit });
      })
      .catch(err => {
        const errObject = JSON.parse(err.message);

        const { errorCode, httpStatusCode, httpStatus } = errObject;

        console.error(`Error: ${httpStatusCode}`, {
          httpStatusCode,
          httpStatus,
          errorCode,
        });
        dispatch(showNotification(`error.${errorCode}`, 'warning'));
      })
      .finally(() => {
        dispatch(fetchEnd());
      });
  };

  handleIsShowDetailsInviteImportBulk = value => this.setState({ isShowDetailsInviteImportBulk: value });

  handleInviteBulkList = value => this.setState({ inviteBulkList: value });

  handleFileInviteBulk = value => this.setState({ fileInviteBulk: value });

  onBulkInvite = () => {
    this.setState({ inviteBulkList: [], isShowDetailsInviteImportBulk: false });
    this.refInputOpenInviteImportBulkDialog.current.value = '';
    this.refInputOpenInviteImportBulkDialog.current.click();
  };

  onBulkSimple = () => {
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      `data:text/plain;charset=utf-8,${encodeURIComponent(
        'email,firstName,lastName,costCenterValue\nsample@email.com,Sample First Name 1,Sample Last Name 1,Sample Code Value 1\nsample2@email.com,Sample First Name 2,Sample Last Name 2,Sample Code Value 2'
      )}`
    );
    element.setAttribute('download', 'sample.csv');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  onCloseInviteImportBulk = () => {
    const { dispatch } = this.props;
    dispatch(reset('invite-import-bulk'));
    this.setState({
      isShowInviteImportBulkDialog: false,
      isShowDetailsInviteImportBulk: false,
      errorMessageInviteImportSubmit: null,
      inviteBulkList: [],
    });
  };

  getLanguages = () =>
    getBusinessConf() &&
    getBusinessConf().config &&
    getBusinessConf().config.translation &&
    getBusinessConf().config.translation;

  onSelectLanguage = lang => this.setState({ lang });

  showHideEditDialog(isShow, id) {
    const node = this.refUserRoleDatagrid.current;
    const {
      props: { onUnselectItems },
    } = node;
    const { dispatch } = this.props;
    onUnselectItems();
    if (id) {
      this.setState({ isShowEditDialog: isShow, selectedUserId: id });
    } else {
      this.setState({ isShowEditDialog: isShow });
      dispatch(reset('user-edit'));
    }
  }

  render() {
    const {
      onCloseInviteImportBulk,
      handleIsShowDetailsInviteImportBulk,
      handleInviteBulkList,
      handleFileInviteBulk,
      onSubmitInviteImportBulk,
      onSubmitInviteCreate,
      onBulkInvite,
      onBulkSimple,
      onSelectLanguage,
    } = this;
    const {
      basePath,
      classes,
      crudUpdateMany,
      dispatch,
      id,
      permissions,
      record,
      resource,
      translate,
      checkPermission,
      dataProvider,
      ...props
    } = this.props;
    const {
      location: { pathname },
    } = props;
    const {
      defaultUserRole,
      defaultStatus,
      isShowEditDialog,
      isShowInviteCreateDialog,
      isShowInviteImportBulkDialog,
      isShowDetailsInviteImportBulk,
      role,
      selectedUserId,
      status,
      inviteBulkList,
      fileInviteBulk,
      errorMessageInviteImportSubmit,
      locales,
      lang,
    } = this.state;
    const isEditable = checkPermission('UPDATE_BUSINESS_USER_STATUS');
    return (
      <>
        <Card>
          <React.Fragment>
            <Responsive
              medium={
                <List
                  actions={
                    <Actions
                      classes={classes}
                      translate={translate}
                      checkPermission={checkPermission}
                      onBulkInvite={onBulkInvite}
                      onBulkSimple={onBulkSimple}
                      onOpenInviteCreateDialog={() => this.setState({ isShowInviteCreateDialog: true })}
                    />
                  }
                  bulkActionButtons={
                    isEditable ? (
                      <BulkUserActions
                        permissions={permissions}
                        exceptDefault={status}
                        translate={translate}
                        changeStatus={this.changeStatus}
                      />
                    ) : null
                  }
                  basePath={pathname}
                  filterDefaultValues={{ userRole: defaultUserRole, status: defaultStatus }}
                  filter={{ businessId: id }}
                  title=" "
                  exporter={false}
                  sort={{ field: 'registrationDate', order: 'DESC' }}
                  resource="businessUser"
                  pagination={<CustomPagination />}
                  debounce={50}
                  {...props}
                >
                  <UserRoleDatagrid
                    ref={this.refUserRoleDatagrid}
                    classes={classes}
                    businessId={id}
                    roleTabs={roleTabs}
                    statusTabs={statusTabs}
                    defaultRole={role}
                    defaultStatus={status}
                    setParentStateRole={this.setStateRole}
                    setParentStateStatus={this.setStateStatus}
                    crudUpdateMany={crudUpdateMany}
                    showHideEditDialog={this.showHideEditDialog}
                    {...props}
                  />
                </List>
              }
            />
            {isEditable ? (
              <UserEdit
                {...props}
                businessId={id}
                basePath={pathname}
                id={selectedUserId}
                handleCancelClick={() => this.showHideEditDialog(false)}
                resource="businessUser"
                isShowDialog={isShowEditDialog}
                statusTabs={statusTabs}
              />
            ) : null}
          </React.Fragment>
        </Card>
        <InviteImportBulkDialog
          {...this.props}
          inputOpenFileRef={this.refInputOpenInviteImportBulkDialog}
          errorMessageSubmit={errorMessageInviteImportSubmit}
          open={isShowInviteImportBulkDialog}
          isShowDetails={isShowDetailsInviteImportBulk}
          handleIsShowDetails={handleIsShowDetailsInviteImportBulk}
          resultList={inviteBulkList}
          handleResultList={handleInviteBulkList}
          file={fileInviteBulk}
          handleFile={handleFileInviteBulk}
          onSubmit={onSubmitInviteImportBulk}
          onClose={onCloseInviteImportBulk}
          onOpen={() => this.setState({ isShowInviteImportBulkDialog: true })}
          lang={lang}
          onSelectLanguage={onSelectLanguage}
          locales={locales}
        />
        <InviteCreateDialog
          {...this.props}
          open={isShowInviteCreateDialog}
          onClose={() => this.setState({ isShowInviteCreateDialog: false })}
          onSubmit={onSubmitInviteCreate}
          locales={locales}
          lang={lang}
          onSelectLanguage={onSelectLanguage}
          translate={translate}
        />
      </>
    );
  }
}

UsersList.propTypes = {
  basePath: PropTypes.string,
  changeStatus: PropTypes.func,
  classes: PropTypes.shape({}),
  crudUpdateMany: PropTypes.func,
  dataProvider: PropTypes.func,
  dispatch: PropTypes.func,
  id: PropTypes.string,
  location: PropTypes.shape({}),
  permissions: PropTypes.string,
  record: PropTypes.shape({}),
  resource: PropTypes.string,
  translate: PropTypes.func,
  checkPermission: PropTypes.func,
};

export const UsersListTest = UsersList;
export { roleTabs, statusTabs };

const enhance = compose(
  withGrants,
  withDataProvider,
  translateHOC,
  connect(
    null,
    { crudUpdateMany: crudUpdateManyHOC }
  ),
  withStyles(userListStyle)
);
export default enhance(UsersList);
