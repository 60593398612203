import axios from 'axios';
import { all, put, select, takeEvery } from 'redux-saga/effects';
import { showNotification } from 'react-admin';
import { GET_CLIENT_SECRET, STRIPE_SYNCHRONIZE } from '../redux/stripe/constants';
import { getBusinessConf } from '../config';
import utils from '../../shared/utils';
import { resetClientSecret, resetSetupIntent, resetStripe, setClientSecret } from '../redux/stripe/actions';
import { USER_LOGOUT } from '../actions/user';
import { getPaymentDetails } from './adyenSaga';
import { setPaymentDetails } from '../redux/paymentDetails/actions';
import { stripeSetupIntentSelector } from '../redux/stripe/selectors';

const synchronize = async ({ setupIntentId }) => {
  const { config } = getBusinessConf();
  let token = '';
  const accessToken = sessionStorage.getItem('accessToken');
  const fleetId = localStorage.getItem('fleetID');
  if (accessToken) {
    ({ token } = JSON.parse(accessToken));
  } else {
    return Promise.reject();
  }
  const instance = axios.create({
    baseURL: config.business_ws.baseUrl,
    timeout: config.timeout || 30000,
    headers: {
      Accept: 'application/json',
      'X-API-KEY': config.login.x_api_key,
      Authorization: `Bearer ${token}`,
    },
  });

  const businessId = utils.getParameters().business.id;

  const opt = {
    url: `/business/fleets/${fleetId}/entities/${businessId}/setup_intents/${setupIntentId}/synchronize`,
    method: 'POST',
    // We need to pass an empty object for Stripe.
    data: {},
  };

  try {
    const response = await instance(opt);

    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const setupIntents = async paymentMethodType => {
  if (paymentMethodType !== 'SEPA' && paymentMethodType !== 'CREDIT_CARD') {
    throw new Error(`[stripeSaga][setupIntents] Error: Invalid paymentMethodType: '${paymentMethodType}'`);
  }

  const { config } = getBusinessConf();
  let token = '';
  const accessToken = sessionStorage.getItem('accessToken');
  const fleetId = localStorage.getItem('fleetID');
  if (accessToken) {
    ({ token } = JSON.parse(accessToken));
  } else {
    return Promise.reject();
  }
  const instance = axios.create({
    baseURL: config.business_ws.baseUrl,
    timeout: config.timeout || 30000,
    headers: {
      Accept: 'application/json',
      'X-API-KEY': config.login.x_api_key,
      Authorization: `Bearer ${token}`,
    },
  });

  const businessId = utils.getParameters().business.id;

  const opt = {
    url: `/business/fleets/${fleetId}/entities/${businessId}/setup_intents`,
    method: 'POST',
    data: {
      paymentMethodType,
    },
  };

  try {
    const response = await instance(opt);

    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
};

function* stripeSynchronize() {
  try {
    const setupIntentId = yield select(stripeSetupIntentSelector);
    yield synchronize({ setupIntentId });

    const updatedMOP = yield getPaymentDetails();

    yield put(setPaymentDetails(updatedMOP));
  } catch (e) {
    console.error('[SAGA][stripeSynchronize]', e);
    yield put(showNotification(e.message, 'warning'));
  } finally {
    yield put(resetSetupIntent());
  }
}

/**
 * When we click on "Pay", we call the setup_intents endpoint to retrieve the client secret
 * @param {Object} action
 * @param {Object} action.payload
 * @param {string} action.payload.paymentMethodType The payment method type
 */
function* setupIntentRequest({ payload }) {
  const { paymentMethodType } = payload;
  try {
    const { pspPublishableKey: pubKey, pspClientSecret: clientSecret, id: setupIntentId } = yield setupIntents(
      paymentMethodType
    );
    yield put(setClientSecret({ pubKey, clientSecret, setupIntentId }));
  } catch (e) {
    console.error('[SAGA][setupIntentRequest]', e);
    yield put(showNotification(e.message, 'warning'));
    yield put(resetClientSecret());
  }
}

/**
 * Logging out resets stripe state
 */
function* userLogoutRequest() {
  yield put(resetStripe());
}

export default function* stripeSaga() {
  yield all([
    takeEvery(GET_CLIENT_SECRET, setupIntentRequest),
    takeEvery(STRIPE_SYNCHRONIZE, stripeSynchronize),
    takeEvery(USER_LOGOUT, userLogoutRequest),
  ]);
}
