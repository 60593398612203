import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { ArrowForwardIos } from '../../shared/icons';
import { clients } from '../../host';

const ItemClient = ({ clientUrl, onClientSelect }) => {
  const handleClientClick = () => {
    sessionStorage.setItem('selectedHost', clientUrl);
    onClientSelect(clientUrl);
  };

  return (
    <ListItem button onClick={handleClientClick}>
      <ListItemText primary={clientUrl} />
      <ListItemIcon>
        <ArrowForwardIos />
      </ListItemIcon>
    </ListItem>
  );
};
ItemClient.propTypes = {
  clientUrl: PropTypes.string.isRequired,
  onClientSelect: PropTypes.func.isRequired,
};

const SearchStyles = {
  searchGrid: {
    marginLeft: 20,
    width: 300,
  },
};
const SearchSimple = ({ onSearch: handleSearch, classes }) => (
  <Grid container alignItems="flex-end" className={classes.searchGrid}>
    <Grid item>
      <SearchIcon />
    </Grid>
    <Grid item>
      <TextField label="Filter" onChange={evt => handleSearch(evt.target.value)} />
    </Grid>
  </Grid>
);
SearchSimple.propTypes = {
  onSearch: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    searchGrid: PropTypes.string.isRequired,
  }).isRequired,
};
const Search = withStyles(SearchStyles)(SearchSimple);

const ClientList = ({ searchText, onClientSelect }) => (
  <List>
    {clients.reduce((clientUrls, clientUrl) => {
      if (!clientUrl.includes(searchText)) {
        return clientUrls;
      }

      clientUrls.push(<ItemClient key={clientUrl} clientUrl={clientUrl} onClientSelect={onClientSelect} />);
      return clientUrls;
    }, [])}
  </List>
);
ClientList.propTypes = {
  searchText: PropTypes.string.isRequired,
  onClientSelect: PropTypes.func.isRequired,
};

const ChooseClient = ({ onClientSelect }) => {
  const [searchText, setSearchText] = useState('');

  return (
    <Dialog fullWidth disableBackdropClick disableEscapeKeyDown maxWidth="md" open>
      <DialogTitle>Select Host</DialogTitle>
      <Search onSearch={setSearchText} />
      <DialogContent>
        <ClientList searchText={searchText} onClientSelect={onClientSelect} />
      </DialogContent>
    </Dialog>
  );
};
ChooseClient.propTypes = {
  onClientSelect: PropTypes.func.isRequired,
};

export default ChooseClient;
