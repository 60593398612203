import { GET_ONE } from 'react-admin';
import utils from '../../shared/utils';

const dataBusinessInvoicePdf = (instance, type, params) => {
  const opt = {
    headers: {
      Accept: 'application/pdf',
    },
    responseType: 'blob',
  };
  switch (type) {
    case GET_ONE:
      switch (params.field) {
        case 'getRefundNoteByInvoiceId':
          opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/invoices/${params.invoiceId}/refundNote`;
          opt.method = 'GET';
          break;
        default:
          opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/invoices/${params.id}/pdf`;
          opt.method = 'GET';
          break;
      }
      break;
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
  utils.hiddenLoadingScreen();
  return instance(opt);
};

export default dataBusinessInvoicePdf;
