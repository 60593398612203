import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import {
  CRUD_UPDATE_MANY,
  UPDATE_MANY,
  translate as translateHOC,
  withDataProvider,
  TextField,
  DateField,
  Datagrid,
  FunctionField,
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { Button, Tabs, Tab, Switch } from '@material-ui/core';
import IconEdit from '@material-ui/icons/Edit';
import withGrants from '../../WithGrants';

const styles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

const EditButtonNotEnhanced = ({ classes, translate, ...props }) => (
  <Button size="medium" color="secondary" {...props}>
    <IconEdit className={classes.leftIcon} />
    {translate('common.edit')}
  </Button>
);
EditButtonNotEnhanced.propTypes = {
  classes: PropTypes.shape({
    leftIcon: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
};
const EditButton = withStyles(styles)(translateHOC(EditButtonNotEnhanced));

const fieldsAll = (changeRoleByUserId, showHideEditDialog, isEditable) => [
  <TextField source="userName" key="userName" />,
  <TextField source="firstName" key="firstName" />,
  <TextField source="lastName" key="lastName" />,
  <DateField source="registrationDate" key="registrationDate" />,
  <TextField source="profileStatus" key="status" sortable={false} />,
  <TextField source="accountStatus" key="accountStatus" sortable={false} />,
  <FunctionField
    key="toggle"
    label="resources.businessUser.fields.useAsDelegatedAdmin"
    render={record => {
      if (record.isDelegatedAdmin !== undefined) {
        return (
          <Switch
            value={record.id}
            checked={record.isDelegatedAdmin}
            onChange={changeRoleByUserId}
            disabled={!isEditable || record.profileStatus.toUpperCase() === 'INACTIVE'}
          />
        );
      }
      return null;
    }}
  />,
  <FunctionField
    key="edit"
    render={record => <EditButton onClick={() => showHideEditDialog(true, record.id)} disabled={!isEditable} />}
  />,
];

const fieldsWithoutStatus = (changeRoleByUserId, showHideEditDialog, isEditable) => [
  <TextField source="userName" key="userName" />,
  <TextField source="firstName" key="firstName" />,
  <TextField source="lastName" key="lastName" />,
  <DateField source="registrationDate" key="registrationDate" />,
  <TextField source="accountStatus" key="accountStatus" sortable={false} />,
  <FunctionField
    key="toggle"
    label="resources.businessUser.fields.useAsDelegatedAdmin"
    render={record => {
      if (record.isDelegatedAdmin !== undefined) {
        return (
          <Switch
            value={record.id}
            checked={record.isDelegatedAdmin}
            onChange={changeRoleByUserId}
            disabled={!isEditable}
          />
        );
      }
      return null;
    }}
  />,
  <FunctionField
    key="edit"
    render={record => <EditButton onClick={() => showHideEditDialog(true, record.id)} disabled={!isEditable} />}
  />,
];

class UserRoleDatagrid extends React.PureComponent {
  constructor(props) {
    super(props);
    const { businessId, filterValues, defaultStatus, defaultRole } = this.props;
    this.state = {
      businessId,
      status: filterValues.status || defaultStatus || '',
      role: filterValues.userRole || defaultRole || 'customer',
    };

    this.changeFilterRole = this.changeFilterRole.bind(this);
    this.changeFilterStatus = this.changeFilterStatus.bind(this);
    this.changeRoleByUserId = this.changeRoleByUserId.bind(this);
  }

  changeFilterRole = (event, value) => {
    const { onUnselectItems, setFilters, setParentStateRole } = this.props;
    const { status, businessId } = this.state;
    onUnselectItems();
    setFilters({ userRole: value, status, businessId });
    this.setState({ role: value });
    setParentStateRole(value);
  };

  changeFilterStatus = (event, value) => {
    const { onUnselectItems, setFilters, setParentStateStatus } = this.props;
    const { role, businessId } = this.state;
    onUnselectItems();
    this.setState({ status: value });
    setFilters({ userRole: role, status: value, businessId });
    setParentStateStatus(value);
  };

  changeRoleByUserId = (user, toggle) => {
    const userId = user.target.value;
    const {
      dispatch,
      location: { pathname },
    } = this.props;
    const { businessId } = this.state;
    const action = toggle ? 'add' : 'remove';

    dispatch({
      type: CRUD_UPDATE_MANY,
      payload: { ids: [userId], data: { businessId, updateField: 'role', action } },
      meta: {
        resource: 'businessUser',
        fetch: UPDATE_MANY,
        onSuccess: {
          notification: {
            body: 'ra.notification.updated',
            level: 'info',
            messageArgs: {
              smart_count: 1,
            },
          },
          pathname,
          refresh: true,
          unselectAll: true,
        },
        onFailure: {
          notification: {
            body: 'ra.notification.http_error',
            level: 'info',
          },
        },
      },
    });
  };

  render() {
    const {
      businessId,
      classes,
      crudUpdateMany,
      dataProvider,
      defaultRole,
      defaultStatus,
      dispatch,
      filterValues,
      hasCreate,
      hasEdit,
      hasList,
      hasShow,
      roleTabs,
      setParentStateRole,
      setParentStateStatus,
      statusTabs,
      showHideEditDialog,
      translate,
      checkPermission,
      ...props
    } = this.props;
    const { role, status } = this.state;
    let clonedFields;
    const isEditable = checkPermission('UPDATE_BUSINESS_USER_STATUS');
    if (status === '') {
      clonedFields = fieldsAll(this.changeRoleByUserId, showHideEditDialog, isEditable);
    } else {
      clonedFields = fieldsWithoutStatus(this.changeRoleByUserId, showHideEditDialog, isEditable);
    }
    return (
      <React.Fragment>
        <Tabs fullWidth value={role} onChange={this.changeFilterRole}>
          {roleTabs.map(item => (
            <Tab
              classes={item.id === role ? { root: classes.selectedRole } : { root: classes.tabRoot }}
              key={item.id}
              value={item.id}
              label={translate(item.name)}
              id={`Selenium-Users-Role-dataGrid-Tab-${item.id}`}
            />
          ))}
        </Tabs>
        <Tabs fullWidth value={status} onChange={this.changeFilterStatus}>
          {statusTabs.map(item => (
            <Tab
              classes={{ root: classes.tabRoot }}
              key={item.id}
              value={item.id}
              label={translate(item.name)}
              id={`Selenium-Users-Status-dataGrid-Tab-${item.id}`}
            />
          ))}
        </Tabs>
        <Datagrid {...props} filterValues={filterValues}>
          {clonedFields}
        </Datagrid>
      </React.Fragment>
    );
  }
}

UserRoleDatagrid.propTypes = {
  businessId: PropTypes.string,
  classes: PropTypes.shape({}),
  crudUpdateMany: PropTypes.func,
  data: PropTypes.shape({}),
  dataProvider: PropTypes.func,
  defaultRole: PropTypes.string,
  defaultStatus: PropTypes.string,
  dispatch: PropTypes.func,
  filterValues: PropTypes.shape({}),
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasList: PropTypes.bool,
  hasShow: PropTypes.bool,
  location: PropTypes.shape({}),
  onUnselectItems: PropTypes.func,
  roleTabs: PropTypes.arrayOf(PropTypes.shape({})),
  setFilters: PropTypes.func,
  setParentStateRole: PropTypes.func,
  setParentStateStatus: PropTypes.func,
  statusTabs: PropTypes.arrayOf(PropTypes.shape({})),
  showHideEditDialog: PropTypes.func,
  translate: PropTypes.func,
  checkPermission: PropTypes.func,
};

const enhance = compose(
  withGrants,
  withDataProvider,
  translateHOC
);

export default enhance(UserRoleDatagrid);
// For Tests
export const UserRoleDatagridTest = UserRoleDatagrid;
