import React, { useState } from 'react';
import * as PropTypes from 'prop-types';
import { UPDATE, showNotification, withDataProvider, refreshView } from 'react-admin';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormHelperText,
} from '@material-ui/core';
import utils from '../../../shared/utils';

const SetAsPaidDialog = ({ isOpen, toggleDialog, translate, dataProvider, dispatch, invoiceId }) => {
  const [paymentMethod, setPaymentMethod] = useState('');
  const [notes, setNotes] = useState('');
  const [paymentMethodIsValid, setPaymentMethodIsValid] = useState('');
  const [notesIsValid, setNotesIsValid] = useState('');

  const markAsPaid = async () => {
    try {
      const { userId: requesterId } = utils.getCurrentUserDataLogged();
      await dataProvider(UPDATE, 'businessInvoice', {
        actionType: 'markAsPaid',
        id: invoiceId,
        data: { requesterId, ...(paymentMethod && { paymentMethod }), ...(notes && { notes }) },
      });
      dispatch(refreshView());
      dispatch(showNotification('notification.invoices.paidSuccessfully'));
    } catch (error) {
      console.error(error);
      dispatch(showNotification('notification.invoices.paidFailure', 'warning'));
    }
  };

  const handlePaymentMethodChange = value => {
    setPaymentMethod(value);
    if (value.length > 148) {
      setPaymentMethodIsValid(translate('resources.invoice.setAsPaidDialog.tooManyChars'));
    } else {
      setPaymentMethodIsValid('');
    }
  };

  const handleNotesChange = value => {
    setNotes(value);
    if (value.length > 148) {
      setNotesIsValid(translate('resources.invoice.setAsPaidDialog.tooManyChars'));
    } else {
      setNotesIsValid('');
    }
  };

  const handleCancelDialog = () => {
    setNotesIsValid('');
    setPaymentMethodIsValid('');
    toggleDialog();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={toggleDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">{translate('resources.invoice.setAsPaidDialog.title')}</DialogTitle>
      <DialogContent>
        <TextField
          id="alert-dialog-paymentMethod-input"
          label={translate('resources.invoice.setAsPaidDialog.paymentMethodLabel')}
          margin="normal"
          fullWidth
          onChange={event => handlePaymentMethodChange(event.target.value)}
          error={Boolean(paymentMethodIsValid)}
        />
        {paymentMethodIsValid && <FormHelperText error>{paymentMethodIsValid}</FormHelperText>}
        <TextField
          id="alert-dialog-paymentMethod-input"
          label={translate('resources.invoice.setAsPaidDialog.notesLabel')}
          multiline
          margin="normal"
          fullWidth
          rowsMax={5}
          onChange={event => handleNotesChange(event.target.value)}
          error={Boolean(notesIsValid)}
        />
        {notesIsValid && <FormHelperText error>{notesIsValid}</FormHelperText>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelDialog} color="primary" id="setAsPaidDialog-cancel-button">
          {translate('common.cancel')}
        </Button>
        <Button
          onClick={markAsPaid}
          label="Confirm"
          id="setAsPaidDialog-setAsPaid-button"
          color="primary"
          variant="contained"
          disabled={Boolean(paymentMethodIsValid) || Boolean(notesIsValid)}
        >
          {translate('resources.invoice.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

SetAsPaidDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  dataProvider: PropTypes.func,
  dispatch: PropTypes.func,
  invoiceId: PropTypes.string.isRequired,
};

export default withDataProvider(SetAsPaidDialog);
