import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormGroup from '@material-ui/core/FormGroup';
import { Button } from 'react-admin';

const styles = theme => ({
  formControl: {
    margin: '30px 0',
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    paddingTop: 15,
  },
  filterField: {
    width: 200,
    marginRight: 60,
  },
  inputLabel: {
    color: theme.palette.grey['500'],
    transform: 'translate(0, 0) scale(1)',
  },
});

const InvoiceFilters = ({ classes, translate, filterValues, setFilters, resetDates, isFilteredDatesOverLimit }) => (
  <FormControl className={classes.formControl} error={isFilteredDatesOverLimit}>
    <FormGroup className={classes.formGroup}>
      <TextField
        id="Selenium-Business-Invoice-Filter-Start-Date-Input"
        type="date"
        required
        fullWidth
        className={classes.filterField}
        label={translate('common.startDate')}
        value={filterValues.startDate || ''}
        InputLabelProps={{
          classes: {
            root: classes.inputLabel,
          },
        }}
        onChange={v => setFilters('startDate', v.target.value)}
        error={isFilteredDatesOverLimit}
      />
      <TextField
        id="Selenium-Business-Invoice-Filter-End-Date-Input"
        type="date"
        required
        fullWidth
        className={classes.filterField}
        label={translate('common.endDate')}
        value={filterValues.endDate || ''}
        InputLabelProps={{
          classes: {
            root: classes.inputLabel,
          },
        }}
        onChange={v => setFilters('endDate', v.target.value)}
        error={isFilteredDatesOverLimit}
      />
      <Button
        id="Selenium-Business-Invoice-ResetLastThreeMonths-Button"
        variant="outlined"
        onClick={resetDates}
        label="resources.invoice.resetButton"
        size="medium"
        style={{ height: 'fit-content' }}
      />
    </FormGroup>
    {isFilteredDatesOverLimit && <FormHelperText>{translate('common.error.maxDuration')}</FormHelperText>}
  </FormControl>
);

InvoiceFilters.propTypes = {
  classes: PropTypes.shape({}),
  translate: PropTypes.func,
  setFilters: PropTypes.func,
  resetDates: PropTypes.func,
  filterValues: PropTypes.shape({}),
  isFilteredDatesOverLimit: PropTypes.bool,
};

export default withStyles(styles)(InvoiceFilters);
