import { takeEvery, put } from 'redux-saga/effects';
import axios from 'axios';
import { getBusinessConf } from '../config';
import { GET_PAYMENT_DETAILS } from '../redux/paymentDetails/constants';
import utils from '../../shared/utils';
import { setPaymentDetails } from '../redux/paymentDetails/actions';

function* getPaymentDetails() {
  const {
    config: {
      business_ws: { baseUrl, timeout },
      login: { x_api_key: xAPIKey },
    },
  } = yield getBusinessConf();

  const fleetId = localStorage.getItem('fleetID');
  let token = '';
  const accessToken = sessionStorage.getItem('accessToken');
  if (accessToken) {
    ({ token } = JSON.parse(accessToken));
  } else {
    return Promise.reject();
  }
  const businessId = utils.getParameters().business.id;
  const endpoint = `business/fleets/${fleetId}/business/${businessId}/paymentDetails`;

  const instance = axios.create({
    baseURL: baseUrl,
    timeout: timeout || 30000,
    headers: {
      Accept: 'application/json',
      'X-API-KEY': xAPIKey,
      Authorization: `Bearer ${token}`,
    },
  });

  const opt = {
    url: endpoint,
    method: 'GET',
  };

  try {
    const response = yield instance(opt);
    yield put(setPaymentDetails({ ...response.data }));
    return Promise.resolve(response.data);
  } catch (err) {
    return Promise.reject(err);
  }
}

export default function* paymentDetailsSaga() {
  yield takeEvery(GET_PAYMENT_DETAILS, getPaymentDetails);
}
