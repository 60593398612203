import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import filter from 'lodash/filter';
import get from 'lodash/get';

import isEmpty from 'lodash/isEmpty';
import * as PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Datagrid, Responsive, SimpleList } from 'react-admin';
import DatagridActions from './DatagridActions';
import LocalStorage from './LocalStorage';

import SelectionDrawer from './SelectionDrawer';

const styles = () => ({
  tabRoot: {
    minWidth: 10,
    textAlign: 'center',
  },
});

// CustomDatagrid allows to show/hide columns dynamically
// the preferences are stored in local storage
class CustomDatagrid extends Component {
  constructor(props) {
    super(props);
    const { filterColumn, defaultTab, filterValues } = this.props;
    const tab = filterValues && filterValues[filterColumn] ? filterValues[filterColumn] : defaultTab;
    this.state = {
      drawerOpened: false,
      columns: this.getInitialSelection(tab),
      tab,
    };
  }

  getColumns() {
    const { children, defaultColumns } = this.props;

    return filter(
      React.Children.map(
        children,
        field =>
          field && {
            key: field.key,
            source: get(field, ['props', 'source']),
            label: get(field, ['props', 'label']),
            show: !isEmpty(defaultColumns) ? defaultColumns.includes(field.key) : true,
          }
      )
    );
  }

  getInitialSelection(tab) {
    const { resource, storage } = this.props;

    let key = resource;
    if (tab) {
      key = `${key}::${tab}`;
    }
    const previousSelection = storage.get(key);

    // if we have a previously stored value, let's return it
    if (!isEmpty(previousSelection)) {
      return previousSelection;
    }

    return this.getColumns();
  }

  // updates the storage with the internal state value
  updateStorage = () => {
    const { resource, storage } = this.props;
    const { tab, columns } = this.state;
    let key = resource;
    if (tab) {
      key = `${key}::${tab}`;
    }
    storage.set(key, columns);
  };

  handleColumnChange = newColumns => {
    const { onColumnChange } = this.props;

    this.setState({ columns: newColumns }, this.updateStorage);
    if (onColumnChange) {
      onColumnChange(newColumns);
    }
  };

  handleOpen = () => this.setState({ drawerOpened: true });

  handleClose = () => this.setState({ drawerOpened: false });

  renderChild = child => {
    const source = child.key;
    const { columns } = this.state;

    // Show children without source, or children explicitly visible
    if (!source || columns.find(column => column.key === source)) {
      return React.cloneElement(child, {});
    }

    return null;
  };

  handleTabClick = (event, value) => {
    const { handleTabClick } = this.props;
    this.setState({ tab: value });
    this.setFilters(value);
    this.setState({ columns: this.getInitialSelection(value) });

    if (handleTabClick) {
      handleTabClick(event, value);
    }
  };

  setFilters = value => {
    const { setFilters, filterColumn } = this.props;
    setFilters({ [filterColumn]: value });
  };

  renderData = () => {
    const { children } = this.props;
    const { columns } = this.state;

    return columns.reduce((acc, column) => {
      const childToRender = children.find(child => child.key === column.key && column.show);
      if (!childToRender) {
        return acc;
      }
      acc.push(this.renderChild(childToRender));
      return acc;
    }, []);
  };

  render() {
    const { translate, classes, filterTabs, ids, resource } = this.props;
    const { columns, drawerOpened, tab } = this.state;
    const datagridProps = { ...this.props };
    [
      'dispatch',
      'hasList',
      'hasEdit',
      'hasShow',
      'defaultTab',
      'filterColumn',
      'defaultColumns',
      'filterTabs',
      'classes',
      'onColumnChange',
    ].forEach(propName => {
      delete datagridProps[propName];
    });

    return (
      <>
        {ids && ids.length > 0 && <DatagridActions openDrawer={this.handleOpen} />}
        {drawerOpened && (
          <SelectionDrawer
            resource={resource}
            columns={columns}
            defaultColumns={this.getColumns()}
            onColumnChange={this.handleColumnChange}
            onClose={this.handleClose}
          />
        )}
        <>
          {filterTabs && (
            <>
              <Responsive
                small={null}
                medium={
                  <Tabs fullWidth value={tab} onChange={this.handleTabClick}>
                    {filterTabs.map(item => (
                      <Tab
                        classes={{ root: classes.tabRoot }}
                        key={item.id}
                        value={item.id}
                        label={translate(item.name)}
                        id={`Selenium-mainBusiness-Tabs-${item.id}`}
                      />
                    ))}
                  </Tabs>
                }
              />
              <Divider />
            </>
          )}
          <Responsive
            small={
              <SimpleList {...datagridProps} primaryText={record => record.name} id="Selenium-businessMain-Table" />
            }
            medium={
              <Datagrid {...datagridProps} id="Selenium-businessMain-Table">
                {this.renderData()}
              </Datagrid>
            }
          />
        </>
      </>
    );
  }
}

CustomDatagrid.propTypes = {
  defaultColumns: PropTypes.arrayOf(PropTypes.string),
  storage: PropTypes.shape({
    get: PropTypes.func.isRequired,
    set: PropTypes.func.isRequired,
  }),
  resource: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.shape({})),
  filterValues: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  filterTabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  handleTabClick: PropTypes.func,
  defaultTab: PropTypes.string,
  filterColumn: PropTypes.string,
  setFilters: PropTypes.func,
  tab: PropTypes.string,
  translate: PropTypes.func,
  onColumnChange: PropTypes.func,
  ids: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
};

CustomDatagrid.defaultProps = {
  defaultColumns: [],
  storage: LocalStorage,
};

export default withStyles(styles)(CustomDatagrid);
