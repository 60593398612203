import React from 'react';
import PropTypes from 'prop-types';
import { withDataProvider, TextField, SimpleShowLayout, CardActions, Button } from 'react-admin';
import IconEdit from '@material-ui/icons/Edit';
import { reset } from 'redux-form';
import Divider from '../../../shared/divider';
import { BusinessDetails } from './business-detail';
import { BusinessAddress } from './business-address';
import { BillingAddress } from './billing-address';
import BusinessEdit from '../edit';
import withGrants from '../../WithGrants';

const BusinessShowActions = ({ handleEditClick }) => (
  <CardActions>
    <Button onClick={handleEditClick} label="common.edit" size="medium">
      <IconEdit />
    </Button>
  </CardActions>
);

BusinessShowActions.propTypes = {
  handleEditClick: PropTypes.func,
};

const BusinessComment = ({ resource, record, classes }) => (
  <SimpleShowLayout resource={resource} record={record}>
    <TextField source="comment" className={classes.block} id="Selenium-businessDetails-Comment" />
  </SimpleShowLayout>
);

BusinessComment.propTypes = {
  record: PropTypes.shape({}),
  classes: PropTypes.shape({}),
  resource: PropTypes.string,
};

class BusinessShow extends React.PureComponent {
  state = {
    // True: Show the Edit business details dialog
    isShowDialog: false,
  };

  constructor(props) {
    super(props);
    this.inputOpenFileRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    const { id } = this.props;
    if (prevProps.id !== id) {
      this.handleCloseDialog();
    }
  }

  handleEditClick = () => {
    const { dispatch } = this.props;
    dispatch(reset('business-details-edit'));
    this.setState({
      isShowDialog: true,
    });
  };

  // This is called by BusinessEdit, to close the Edit dialog
  handleCloseDialog = () => {
    this.setState({
      isShowDialog: false,
    });
  };

  render() {
    const { record, classes, checkPermission, permissions } = this.props;
    const { resource } = this.props;
    const { isShowDialog } = this.state;
    const isAdmin = permissions === 'admin';

    return (
      <>
        {checkPermission('UPDATE_BUSINESS') && (
          <>
            <BusinessShowActions handleEditClick={this.handleEditClick} />
            <Divider />
          </>
        )}
        <BusinessDetails resource={resource} record={record} classes={classes} isAdmin={isAdmin} />
        <Divider />
        <BusinessAddress resource={resource} record={record} classes={classes} />
        <BillingAddress resource={resource} record={record} classes={classes} />
        <Divider />
        <BusinessComment resource={resource} record={record} classes={classes} />
        <Divider />
        {checkPermission('UPDATE_BUSINESS') && (
          <BusinessEdit {...this.props} isShowDialog={isShowDialog} handleCloseDialog={this.handleCloseDialog} />
        )}
      </>
    );
  }
}

BusinessShow.propTypes = {
  id: PropTypes.string,
  dataProvider: PropTypes.func,
  dispatch: PropTypes.func,
  record: PropTypes.shape({}),
  classes: PropTypes.shape({
    block: PropTypes.string,
    rowContent: PropTypes.string,
    inviteButton: PropTypes.string,
    field: PropTypes.string,
  }),
  resource: PropTypes.string,
  checkPermission: PropTypes.func,
  permissions: PropTypes.string,
};
export default withGrants(withDataProvider(BusinessShow));
// For Tests
export const BusinessShowTest = BusinessShow;
