import { SETUP_ADYEN_INTENT, UPDATE_ADYEN_MOP_RESULT } from './constants';

export const setupAdyenIntentAction = payload => ({
  type: SETUP_ADYEN_INTENT,
  payload,
});

export const updateAdyenMopResultAction = payload => ({
  type: UPDATE_ADYEN_MOP_RESULT,
  payload,
});
