import React, { useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { GET_LIST } from 'react-admin';
import { FormControl, Input, Tooltip } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { push } from 'react-router-redux';
import { debounce } from 'debounce';
import dataProviderConfigurator from '../../core/data-provider';
import { getBusinessConf } from '../../core/config';

const styles = {
  searchField: {
    color: 'currentColor',
    flex: '2',
    textDecorationColor: 'currentColor',
    height: 20,
    marginTop: 0,
  },
  iconButton: {
    color: 'inherit',
    flex: '0 0 48px',
  },
};

const SearchControl = props => {
  const { dispatch } = props;
  const debounceTime = 200;
  const [showSearch, setShowSearch] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [suggestionList, setSuggestionList] = useState([{ name: 'titi', id: '1234' }, { name: 'toto', id: '5678' }]);

  const updateSearch = () => {
    setSuggestionList([]);

    const { config } = getBusinessConf();
    if (config) {
      const dataProvider = dataProviderConfigurator(config);
      dataProvider(GET_LIST, 'business', {
        pagination: { page: 1, perPage: 10 },
        filter: { q: searchValue },
        sort: { field: 'name' },
      })
        .then(({ data }) => {
          setShowSuggestions(false);
          const modifiedData = data
            .filter(el => el.name.toLowerCase().includes(searchValue.toLowerCase()))
            .map(el => ({ name: el.name, id: el.id }));
          setSuggestionList(modifiedData);
        })
        .finally(() => setShowSuggestions(true))
        .catch(err => console.error(err));
    }
  };

  const handleSearchRequest = e => {
    const { target } = e;
    const { value } = target;
    setSearchValue(value);
    if (value !== '' && value.length > 3) {
      debounce(updateSearch(), debounceTime);
    }
  };

  const cleanSearchKey = () => {
    setSearchValue('');
    setShowSuggestions(false);
    setShowSearch(false);
  };
  return (
    <>
      {showSearch ? (
        <FormControl name="search-form" autoComplete="off" style={{ marginTop: 0 }}>
          {
            <>
              <Input
                style={styles.searchField}
                color="currentColor"
                placeholder="Search for Businesses"
                id="Selenium-Navigation-Search-Input"
                onChange={e => handleSearchRequest(e)}
                autoComplete="Off"
                autoFocus
              />
              {showSuggestions && (
                <div
                  style={{
                    height: 20,
                    width: 'auto',
                    position: 'absolute',
                    top: 40,
                  }}
                >
                  <Paper>
                    {suggestionList.map((x, index) => (
                      <MenuItem
                        id={`Selenium-Navigation-Search-Result-${index}`}
                        key={x.id}
                        onClick={() => {
                          cleanSearchKey();
                          dispatch(push(`/business/${x.id}`));
                        }}
                      >
                        {x.name}
                      </MenuItem>
                    ))}
                  </Paper>
                </div>
              )}
            </>
          }
        </FormControl>
      ) : null}
      <Tooltip title={showSearch ? 'Clear' : 'Search'}>
        <IconButton
          id="Selenium-Navigation-Search-ShowHide-Button"
          aria-label="search"
          color="inherit"
          onClick={() => {
            setShowSearch(!showSearch);
            if (showSearch) {
              cleanSearchKey();
            }
          }}
          style={styles.iconButton}
        >
          {showSearch ? <CloseIcon /> : <SearchIcon />}
        </IconButton>
      </Tooltip>
    </>
  );
};
SearchControl.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(reduxForm({ form: 'search-form' })(SearchControl));
