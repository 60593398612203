import React from 'react';
import PropTypes from 'prop-types';
import {
  TextInput,
  DisabledInput,
  SimpleShowLayout,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import Header from '../../../shared/header';
import { email, required, number, maxLength, minLength, validVat, stringValue } from '../../../core/validators';
import { countryList } from '../../../shared/country';
import { getBusinessConf } from '../../../core/config';

export const BusinessDetails = ({ resource, classes, translate }) => {
  const { config } = getBusinessConf();
  const languageList =
    config && config.translation && config.translation.invoiceLanguage
      ? config.translation.invoiceLanguage.map(language => ({
          id: language.code,
          name: language.label,
        }))
      : [];
  const taxNumberCountry = config && config.taxNumberEnable && (
    <AutocompleteInput
      source="taxNumberCountry"
      choices={countryList}
      className={classes.field}
      classes={{ suggestionsContainerOpen: classes.autoCompleteContainer }}
      autoComplete="nope"
      id="Selenium-Business-Create-Address-Country-Input"
    />
  );
  const communityTaxNumber = config && config.taxNumberEnable && (
    <TextInput
      source="communityTaxNumber"
      validate={[maxLength(20)]}
      className={classes.field}
      id="Selenium-Business-Create-communityTaxNumber-Input"
    />
  );
  const invoiceFrequencyList = [
    {
      id: 'DAILY',
      name: translate(`resources.business.fields.invoiceFrequencies.daily`),
    },
    {
      id: 'MONTHLY',
      name: translate(`resources.business.fields.invoiceFrequencies.monthly`),
    },
  ];

  return (
    <SimpleShowLayout resource={resource}>
      <Header label="resources.business.businessDetails" />
      <TextInput
        source="name"
        required
        validate={[required, stringValue, maxLength(64)]}
        className={classes.field}
        id="Selenium-Business-Create-Name-Input"
      />
      <TextInput
        source="legalName"
        required
        validate={[required, stringValue, maxLength(64)]}
        className={classes.field}
        id="Selenium-Business-Create-legalName-Input"
      />
      <TextInput
        source="phoneNumber"
        className={classes.field}
        validate={[number, maxLength(15)]}
        id="Selenium-Business-Create-phoneName-Input"
      />
      <TextInput
        source="faxNumber"
        className={classes.field}
        validate={[number, maxLength(15)]}
        id="Selenium-Business-Create-faxNumber-Input"
      />
      <TextInput
        source="email"
        type="email"
        validate={[email, maxLength(124)]}
        className={classes.field}
        id="Selenium-Business-Create-Email-Input"
      />
      <DisabledInput source="status" className={classes.field} id="Selenium-Business-Create-Status-Input" />
      <TextInput
        source="vat"
        className={classes.field}
        required
        validate={
          config && config.isCountryCodeVat
            ? [required, minLength(4), maxLength(15), validVat]
            : [required, maxLength(32)]
        }
        id="Selenium-Business-Create-Vat-Input"
      />
      <TextInput
        source="ein"
        validate={[required, maxLength(32)]}
        required
        className={classes.field}
        id="Selenium-Business-Create-ein-Input"
      />
      <TextInput
        source="sdicemCode"
        label="SDI / PEC"
        className={classes.field}
        validate={[maxLength(124)]}
        id="Selenium-Business-Create-sdicemCode-Input"
      />
      <ReferenceInput
        label="resources.business.fields.billingGroup.name"
        source="billingGroup.id"
        resource="name"
        reference="billingGroup"
        className={classes.field}
      >
        <SelectInput source="name" id="Selenium-Business-Create-billingGroup-Select" />
      </ReferenceInput>
      {taxNumberCountry}
      {communityTaxNumber}
      <SelectInput
        source="locale"
        choices={languageList}
        className={classes.field}
        validate={[required, maxLength(5)]}
        required
        id="Selenium-Business-Create-language-Select"
      />
      <SelectInput
        source="invoiceFrequency"
        id="Selenium-Business-Create-invoiceFrequency-Select"
        className={classes.field}
        choices={invoiceFrequencyList}
        validate={[required]}
        required
        allowEmpty={false}
      />
    </SimpleShowLayout>
  );
};

BusinessDetails.propTypes = {
  classes: PropTypes.shape({}),
  resource: PropTypes.string,
  translate: PropTypes.func.isRequired,
};
