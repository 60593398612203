import React from 'react';
import { Route } from 'react-router';
import configRoutes from './config-routes';

const customRoutes = [];

Object.keys(configRoutes).forEach(route => {
  let importedComponent = null;
  import(`../components/${route}`)
    .then(res => {
      importedComponent = res.default;
      return Promise.resolve();
    })
    .then(() => {
      customRoutes.push(
        <Route
          exact
          path={configRoutes[route].path}
          component={importedComponent}
          noLayout={configRoutes[route].isNoLayout}
        />
      );
    });
});

export default customRoutes;
