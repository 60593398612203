import { GET_LIST, GET_MANY } from 'react-admin';

const dataProduct = (instance, type, params, config) => {
  const opt = {};
  switch (type) {
    case GET_MANY:
    case GET_LIST: {
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/product`;
      opt.method = 'GET';
      break;
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
  return instance(opt);
};

export default dataProduct;
