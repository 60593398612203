import grantSaga from './grantSaga';
import login2FASaga from './login2FASaga';
import userSaga from './userSaga';
import localeSaga from './localeSaga';
import configSaga from './configSaga';
import stripeSaga from './stripeSaga';
import paymentDetailsSaga from './paymentDetailsSaga';
import adyenSaga from './adyenSaga';

export default [grantSaga, login2FASaga, userSaga, localeSaga, configSaga, stripeSaga, adyenSaga, paymentDetailsSaga];
