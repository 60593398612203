export const MENU_VISIBILITY = 'MENU_VISIBILITY';
export const MENU_PATH = 'MENU_PATH';

const menuReducer = (state = {}, { type, id, visible, path }) => {
  switch (type) {
    case MENU_VISIBILITY:
      return Object.assign({}, state, {
        [id]: Object.assign({}, state[id], {
          visible,
        }),
      });
    case MENU_PATH:
      return Object.assign({}, state, {
        [id]: Object.assign({}, state[id], {
          path,
        }),
      });
    default:
      return state;
  }
};

export default menuReducer;
