import React from 'react';
import PropTypes from 'prop-types';
import {
  translate as translateHOC,
  TextField,
  Responsive,
  DateField,
  ReferenceManyField,
  Pagination,
  Datagrid,
  ReferenceField,
} from 'react-admin';
import { Card, withStyles } from '@material-ui/core';
import TripDetails from './trip-details';
import utils from '../../shared/utils';
import { getBusinessConf } from '../../core/config';

const styles = {
  tabRoot: {
    flexBasis: '100%',
    minWidth: 10,
  },
};

const fieldsAll = id => [
  <ReferenceField
    label="Username"
    source="userId"
    reference="user"
    sortable={false}
    linkType={false}
    allowEmpty
    key="userName"
  >
    <TextField source="userName" id={`Selenium-Trips-textField-withStatus-userName-${id}`} />
  </ReferenceField>,
  <ReferenceField
    label="First name"
    source="userId"
    reference="user"
    sortable={false}
    linkType={false}
    allowEmpty
    key="firstName"
  >
    <TextField source="firstName" id={`Selenium-Trips-textField-withStatus-firstName-${id}`} />
  </ReferenceField>,
  <ReferenceField
    label="Last name"
    source="userId"
    reference="user"
    sortable={false}
    linkType={false}
    allowEmpty
    key="lastName"
  >
    <TextField source="lastName" id={`Selenium-Trips-textField-withStatus-lastName-${id}`} />
  </ReferenceField>,
  // <TextField source="user.userName" key="userName" />,
  // <TextField source="user.firstName" key="firstName" />,
  // <TextField source="user.lastName" key="lastName" />,
  <DateField
    source="booking_date"
    key="registrationDate"
    showTime
    id={`Selenium-Trips-textField-withStatus-registrationDate-${id}`}
  />,
];

const fieldsWithoutStatus = id => [
  <ReferenceField
    label="Username"
    source="userId"
    reference="user"
    sortable={false}
    linkType={false}
    allowEmpty
    key="userName"
  >
    <TextField source="userName" id={`Selenium-Trips-textField-withoutStatus-userName-${id}`} />
  </ReferenceField>,
  <ReferenceField
    label="First name"
    source="userId"
    reference="user"
    sortable={false}
    linkType={false}
    allowEmpty
    key="firstName"
  >
    <TextField source="firstName" id={`Selenium-Trips-textField-withoutStatus-firstName-${id}`} />
  </ReferenceField>,
  <ReferenceField
    label="Last name"
    source="userId"
    reference="user"
    sortable={false}
    linkType={false}
    allowEmpty
    key="lastName"
  >
    <TextField source="lastName" id={`Selenium-Trips-textField-withoutStatus-lastName-${id}`} />
  </ReferenceField>,
  <DateField
    source="booking_date"
    key="registrationDate"
    showTime
    id={`Selenium-Trips-textField-withoutStatus-registrationDate-${id}`}
  />,
  <TextField
    source="billingStatus"
    key="billingStatus"
    id={`Selenium-Trips-textField-withoutStatus-billingStatus-${id}`}
  />,
];

class BusinessTrips extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      filter: 'ongoing',
      isShowTripDetails: false,
    };
    this.pagination = React.createRef();
  }

  handleChangeFilter = (event, value) => {
    return Promise.resolve(this.handleChangePage(1)).then(() => {
      this.setState({ filter: value });
    });
  };

  handleChangePage = value => {
    this.pagination.current.props.setPage(value);
  };

  handleShowTripDetails = (id, basePath, record) => {
    const { tripId, userId } = record.trip;
    const { filter } = this.state;
    const { config } = getBusinessConf();
    if (config && config.isMapDisplayed !== false) {
      utils.setParameters({
        businessTrip: { tripId, tripDetailStatus: filter === 'ongoing' ? 'Ongoing' : 'Complete', userId },
      });
      this.setState({ isShowTripDetails: true });
    }
  };

  handleHideTripDetails = () => {
    this.setState({ isShowTripDetails: false });
  };

  render() {
    const { classes, translate, id, ...props } = this.props;
    const record = { id };
    const { filter, isShowTripDetails } = this.state;
    let fields;
    if (filter === 'ongoing') {
      fields = fieldsAll(id);
    } else {
      fields = fieldsWithoutStatus(id);
    }
    return (
      <>
        <TripDetails isShowDialog={isShowTripDetails} handleCloseDialog={this.handleHideTripDetails} />
        <Card className={classes.container}>
          <Responsive
            medium={
              <ReferenceManyField
                pagination={<Pagination ref={this.pagination} />}
                perPage={10}
                reference="businessTrip"
                record={record}
                target="business"
                {...props}
              >
                <Datagrid rowClick={this.handleShowTripDetails}>{fields}</Datagrid>
              </ReferenceManyField>
            }
          />
        </Card>
      </>
    );
  }
}

BusinessTrips.propTypes = {
  id: PropTypes.string,
  translate: PropTypes.func,
  classes: PropTypes.shape({}),
};
export default withStyles(styles)(translateHOC(BusinessTrips));
// For Tests
export const BusinessTripsTest = BusinessTrips;
