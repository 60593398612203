const grantReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case 'SAVE_GRANTS':
      if (payload) return { permissions: payload };
      break;
    default:
      return { ...state };
  }
  return { ...state };
};

export default grantReducer;
