import * as PropTypes from 'prop-types';
import React from 'react';
import { DateField, NumberField, TextField } from 'react-admin';
import MultiTextField from '../../shared/multi-text-field';
import SimpleMultiTextField from '../../shared/multi-text-field/SimpleMultiTextField';

export const BillingTripFields = () => [
  <TextField key="userToBillFullName" source="userToBill.fullName" />,
  <DateField key="tripDate" source="trip.date" showTime />,
  <SimpleMultiTextField
    key="duration"
    sources={['trip.duration', 'min']}
    sortBy="trip.duration"
    label="resources.billingTrips.fields.trip.duration"
  />,
  <SimpleMultiTextField
    key="distance"
    sources={['trip.length', 'distanceUnit']}
    sortBy="trip.length"
    label="resources.billingTrips.fields.distance"
  />,
  <SimpleMultiTextField
    key="amountWithoutTaxOfBill"
    sources={['amountWithoutTaxOfBill', 'currency']}
    sortBy="amountWithoutTaxOfBill"
    label="resources.billingTrips.fields.amountWithoutTaxOfBill"
  />,
  <SimpleMultiTextField
    key="taxOfBill"
    sources={['taxOfBill', 'currency']}
    sortBy="taxOfBill"
    label="resources.billingTrips.fields.taxOfBill"
  />,
  <TextField key="costCenter" source="userToBill.costCenter.name" label="resources.billingTrips.fields.costCenter" />,
  <NumberField
    key="taxRate"
    label="resources.billingTrips.fields.taxRate"
    source="taxRate"
    options={{ style: 'percent' }}
  />,
  <SimpleMultiTextField
    key="amountOfBill"
    sources={['amountOfBill', 'currency']}
    sortBy="amountOfBill"
    label="resources.billingTrips.fields.amountOfBill"
  />,
  <TextField key="userName" source="userToBill.userName" />,
  <TextField key="fleetId" source="fleetId" />,
  <TextField key="tripId" source="trip.tripId" />,
  <NumberField key="creditUsed" source="creditUsed" label="resources.billingTrips.fields.creditsUsed" />,
];

export const BillingOtherChargesFields = ({ currency }) => [
  <TextField key="productName" source="productName" sortable={false} />,
  <MultiTextField
    key="amountWithoutTaxOfBill"
    label="resources.billingTrips.fields.amountWithoutTaxOfBill"
    sources={['totalWithoutTax', currency]}
    sortable={false}
  />,
  <MultiTextField
    key="taxOfBill"
    label="resources.billingTrips.fields.taxOfBill"
    sources={['totalTaxAmount', currency]}
    sortable={false}
  />,
  <MultiTextField
    key="amountOfBill"
    label="resources.billingTrips.fields.amountOfBill"
    sources={['totalWithTax', currency]}
    sortable={false}
  />,
  <TextField key="productId" source="productId" label="resources.billingTrips.fields.productID" />,
];

BillingOtherChargesFields.propTypes = {
  currency: PropTypes.string.isRequired,
};

export const BillingProductFieldsTest = BillingOtherChargesFields;
