import { CREATE, GET_MANY_REFERENCE, UPDATE } from 'react-admin';
import utils from '../../shared/utils';

const dataCredits = (instance, type, params, config) => {
  const { id } = utils.getParameters().business;
  const opt = {};
  switch (type) {
    case CREATE:
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${id}/credit`;
      opt.method = 'POST';
      opt.data = params.data;
      break;
    case GET_MANY_REFERENCE: {
      const query = new URLSearchParams();
      if (params.pagination) {
        query.append('page', params.pagination.page - 1);
        query.append('size', params.pagination.perPage);
      }
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${id}/wallet`;
      opt.method = 'GET';
      opt.params = query;
      break;
    }
    case UPDATE: {
      if (params && params.data && params.data.id && params.data.availableAmount) {
        const walletId = params.data.id;
        const amount = -parseFloat(params.data.availableAmount);
        opt.url = `/billing/fleets/${localStorage.getItem('fleetID')}/wallet/${walletId}/systemCredits/${amount}`;
        opt.method = 'PUT';
      }
      break;
    }

    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
  return instance(opt);
};

export default dataCredits;
