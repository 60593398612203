const login2FAReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case 'LOGIN_SAVE_2FA_DATA':
      if (payload) return { payload };
      break;
    case 'CHANGE_ACCOUNT_2FA':
      return {};
    default:
      return { ...state };
  }
  return { ...state };
};

export default login2FAReducer;
