import { GET_FLEET_CONFIG, SET_FLEET_CONFIG } from './constants';

export const getFleetConfig = () => ({
  type: GET_FLEET_CONFIG,
});

export const setFleetConfig = payload => ({
  type: SET_FLEET_CONFIG,
  payload,
});
