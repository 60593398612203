/**
 * Import users from a CVS file
 * Sample file format:
 *   email,firstName,lastName,costCenterValue
 *   email1@test.me, First Name 1, Last Name 1, Cost Center Value 1
 *   email2@test.me, First Name 2, Last Name 2, Cost Center Value 2
 */

import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { translate as translateHOC, Button, SaveButton, Create, SimpleForm, TextInput } from 'react-admin';
import { submit, reset, isSubmitting as isSubmittingAction } from 'redux-form';
import { connect } from 'react-redux';

import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  MenuItem,
  withStyles,
} from '@material-ui/core';
import IconSend from '@material-ui/icons/Send';
import IconCancel from '@material-ui/icons/Cancel';
import { parseInviteCSV } from '../../../shared/utils/load-file';

const CustomTypography = ({ translate, userCount }) => (
  <Typography variant="subheading" id="Selenium-Users-inviteImportBulk-customTypography-userCount">
    {translate('notification.bulkInvite.confirm', { smart_count: userCount })}
  </Typography>
);

const styles = {
  elevation1: {
    boxShadow: 'none',
  },
  simpleForm: {
    '& > div': {
      padding: '0 !important',
    },
  },
  dialogActionsRoot: {
    marginRight: 20,
  },
};

class InviteImportBulk extends React.PureComponent {
  state = {
    errorMessage: null,
    userCount: 0,
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(reset('invite-import-bulk'));
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { errorMessageSubmit } = this.props;
    if (errorMessageSubmit !== null && prevProps.errorMessageSubmit !== errorMessageSubmit) {
      this.setState({ errorMessage: errorMessageSubmit });
    }
  }

  handleSaveClick = () => {
    const { dispatch } = this.props;
    dispatch(submit('invite-import-bulk'));
  };

  validateBulkInvite = (resultList, errorMessage) => {
    return resultList && resultList.length > 0 && errorMessage === false;
  };

  handleBulkInvite = async e => {
    const { onOpen, handleResultList, handleFile } = this.props;

    const reader = new FileReader();
    const file = e.target.files[0];
    // Forbid to upload file with size > 10MB
    if (file.size / 1024 / 1024 > 10) {
      await this.setState({
        errorMessage: 'notification.bulkInvite.invalidSize',
      });
      return onOpen();
    }
    reader.onloadend = async () => {
      const dataParsed = parseInviteCSV(reader.result);
      if (dataParsed) {
        const { resultList, errorMessage, userCount } = dataParsed;
        this.validateBulkInvite(resultList, errorMessage);
        handleResultList(resultList);
        handleFile(file);
        this.setState({ userCount, errorMessage });
      }
      onOpen();
    };
    reader.readAsText(file);
  };

  render() {
    const {
      open,
      onClose,
      handleIsShowDetails,
      inputOpenFileRef,
      isShowDetails,
      classes,
      translate,
      isSubmitting,
      dispatch,
      resultList,
      onSubmit,
      errorMessageSubmit,
      handleFile,
      handleResultList,
      crudUpdateMany,
      dataProvider,
      checkPermission,
      onOpen,
      lang,
      onSelectLanguage,
      locales,
      ...props
    } = this.props;
    const { userCount, errorMessage } = this.state;
    const dialogSize = errorMessage ? 'md' : 'sm';

    return (
      <React.Fragment>
        <Dialog maxWidth={dialogSize} fullWidth open={open} onClose={onClose}>
          <DialogTitle id="Selenium-Users-inviteImportBulk-dialogTitle-Title">
            {translate('resources.invite.create.importTitle')}
          </DialogTitle>
          <DialogContent>
            {!errorMessage ? (
              <Create title=" " {...props} classes={{ card: classes.elevation1 }} resource="invite">
                <SimpleForm form="invite-import-bulk" className={classes.simpleForm} onSubmit={onSubmit} toolbar={null}>
                  <CustomTypography translate={translate} userCount={userCount} />
                  <TextInput
                    id="lang"
                    select
                    source={translate('languages.language')}
                    defaultValue={lang}
                    onChange={e => onSelectLanguage(e.target.value)}
                  >
                    {locales.map(option => (
                      <MenuItem key={option.code} value={option.code}>
                        {translate(`languages.${option.label}`)}
                      </MenuItem>
                    ))}
                  </TextInput>
                </SimpleForm>
              </Create>
            ) : null}
            {errorMessage ? (
              <Typography id="Selenium-Users-inviteImportBulk-Typography-Error" variant="subheading">
                {translate(errorMessage)}
              </Typography>
            ) : null}
            {resultList.length > 0 && isShowDetails ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell id="Selenium-Users-inviteImportBulk-Table-Cell-Header-Email">
                      {translate('resources.business.show.table.email')}
                    </TableCell>
                    <TableCell id="Selenium-Users-inviteImportBulk-Table-Cell-Header-firstName">
                      {translate('resources.business.show.table.firstName')}
                    </TableCell>
                    <TableCell id="Selenium-Users-inviteImportBulk-Table-Cell-Header-lastName">
                      {translate('resources.business.show.table.lastName')}
                    </TableCell>
                    <TableCell id="Selenium-Users-inviteImportBulk-Table-Cell-Header-costCenter">
                      {translate('resources.business.show.table.costCenterValue')}
                    </TableCell>
                    <TableCell id="Selenium-Users-inviteImportBulk-Table-Cell-Header-Error">
                      {translate('resources.business.show.table.error')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resultList.map((user, index) => (
                    <TableRow key={index}>
                      <TableCell id={`Selenium-Users-inviteImportBulk-Table-Cell-Body-Email-${index}`}>
                        <Typography
                          variant="body1"
                          id={`Selenium-Users-inviteImportBulk-Typography-Body-Email-${index}`}
                        >
                          {user.email}
                        </Typography>
                      </TableCell>
                      <TableCell id={`Selenium-Users-inviteImportBulk-Table-Cell-Body-firstName-${index}`}>
                        <Typography
                          variant="body1"
                          id={`Selenium-Users-inviteImportBulk-Typography-Body-firstName-${index}`}
                        >
                          {user.firstName}
                        </Typography>
                      </TableCell>
                      <TableCell id={`Selenium-Users-inviteImportBulk-Table-Cell-Body-lastName-${index}`}>
                        <Typography
                          variant="body1"
                          id={`Selenium-Users-inviteImportBulk-Typography-Body-lastName-${index}`}
                        >
                          {user.lastName}
                        </Typography>
                      </TableCell>
                      <TableCell id={`Selenium-Users-inviteImportBulk-Table-Cell-Body-costCenter-${index}`}>
                        <Typography
                          variant="body1"
                          id={`Selenium-Users-inviteImportBulk-Typography-Body-costCenter-${index}`}
                        >
                          {user.costCenterValue}
                        </Typography>
                      </TableCell>
                      <TableCell id={`Selenium-Users-inviteImportBulk-Table-Cell-Body-Error-${index}`}>
                        <Typography
                          variant="body1"
                          id={`Selenium-Users-inviteImportBulk-Typography-Body-Error-${index}`}
                        >
                          {translate(user.errorMessage)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : null}
          </DialogContent>
          <DialogActions classes={{ root: classes.dialogActionsRoot }}>
            <Button
              label="common.cancel"
              onClick={onClose}
              size="medium"
              id="Selenium-Users-inviteImportBulk-Button-Cancel"
            >
              <IconCancel />
            </Button>
            {!errorMessage ? (
              <SaveButton
                saving={isSubmitting}
                onClick={this.handleSaveClick}
                label="common.send"
                icon={<IconSend />}
                id="Selenium-Users-inviteImportBulk-Button-Save"
              />
            ) : (
              <Button
                label={translate(isShowDetails ? 'common.hideDetails' : 'common.showDetails')}
                onClick={() => handleIsShowDetails(!isShowDetails)}
                size="medium"
              />
            )}
          </DialogActions>
        </Dialog>
        <input
          type="file"
          ref={inputOpenFileRef}
          style={{ display: 'none' }}
          onChange={this.handleBulkInvite}
          accept=".csv"
          id="Selenium-Users-inviteImportBulk-Input-File"
        />
      </React.Fragment>
    );
  }
}

CustomTypography.propTypes = {
  translate: PropTypes.func,
  userCount: PropTypes.number,
};

InviteImportBulk.propTypes = {
  translate: PropTypes.func,
  classes: PropTypes.shape({}),
  dispatch: PropTypes.func,
  isSubmitting: PropTypes.bool,
  errorMessageSubmit: PropTypes.string,
  inputOpenFileRef: PropTypes.shape({}),
  open: PropTypes.bool,
  isShowDetails: PropTypes.bool,
  handleIsShowDetails: PropTypes.func,
  resultList: PropTypes.arrayOf(PropTypes.shape({})),
  handleResultList: PropTypes.func,
  file: PropTypes.shape({}),
  handleFile: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  lang: PropTypes.string,
  onSelectLanguage: PropTypes.func,
  locales: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = state => ({
  isSubmitting: isSubmittingAction('invite-import-bulk')(state),
});

export default compose(
  translateHOC,
  connect(mapStateToProps),
  withStyles(styles)
)(InviteImportBulk);

// For Tests
export const InviteImportBulkTest = InviteImportBulk;
