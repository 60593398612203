import { takeEvery, select, put } from 'redux-saga/effects';
import axios from 'axios';
import { getBusinessConf } from '../config';
import { decodeToken, userFetchPromise } from '../auth-provider';

function* checkPinCode(params) {
  // from here call the correct url
  const { config } = yield getBusinessConf();
  const { payload } = params;
  const { pincode } = payload;
  const state = yield select();
  const { sessionId } = state.login2FA.payload;
  const checkPinCodeUrl = state.login2FA.payload.pincodeCheckUrl;
  const instance = axios.create({
    baseURL: config.login.baseUrl,
    timeout: config.timeout || 30000,
  });
  const bodyFormData = new URLSearchParams();
  bodyFormData.append('code', pincode);
  bodyFormData.append('session_id', sessionId);

  const request = {
    url: checkPinCodeUrl,
    method: 'POST',
    data: bodyFormData,
  };

  try {
    const token = yield instance(request);
    const [userId, fleetId] = decodeToken(token.data);

    if (userId) {
      yield userFetchPromise(userId, fleetId, config);
      window.location.href = '/';
    }
  } catch (err) {
    yield put({ type: 'CHANGE_ACCOUNT_2FA' });
    console.error('2FA Token retrieve error', err);
  }
}

export default function* login2FASaga() {
  yield takeEvery('CHECK_PIN_CODE_2FA', checkPinCode);
}
