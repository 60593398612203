import React from 'react';
import PropTypes from 'prop-types';
import {
  CREATE,
  withDataProvider,
  fetchStart,
  fetchEnd,
  showNotification,
  translate as translateHOC,
  Button,
  ReferenceManyField,
  Pagination,
  Datagrid,
  TextField,
  DateField,
} from 'react-admin';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { withStyles } from '@material-ui/core';

import Divider from '../../../../shared/divider';
import AddCredits from '../AddCredits';

const styles = {
  field: {
    float: 'left',
    width: '28%',
    marginRight: '5%',
    marginBottom: '2rem',
  },
  fieldContent: {
    width: '100%',
    marginRight: '0',
  },
  wrapper: {
    width: '100%',
    position: 'relative',
  },
  button: {
    float: 'left',
    marginTop: 20,
  },
};

// TODO: Handle redeem section
const CreditsListOwnerRedeem = ({ classes, translate, promoteCode, handleRedeemSubmit, handleRedeemInput }) => {
  return (
    <React.Fragment>
      <div className={classes.wrapper}>
        <div className={classes.field}>
          <InputLabel className={classes.fieldContent} htmlFor="Selenium-Business-Credits-Redeem-Input">
            {translate('credits.list.label.redeem')}
          </InputLabel>
          <Input
            className={classes.fieldContent}
            value={promoteCode}
            onChange={handleRedeemInput}
            inputProps={{
              id: 'Selenium-Business-Credits-Redeem-Input',
            }}
          />
        </div>
        <Button
          className={classes.button}
          label="credits.list.button.redeem"
          onClick={handleRedeemSubmit}
          size="medium"
          id="Selenium-Business-Credits-Redeem-Submit-Button"
        />
      </div>
    </React.Fragment>
  );
};

CreditsListOwnerRedeem.propTypes = {
  classes: PropTypes.shape({}),
  translate: PropTypes.func,
  promoteCode: PropTypes.string,
  handleRedeemSubmit: PropTypes.func,
  handleRedeemInput: PropTypes.func,
};

const enhance = compose(
  withDataProvider,
  withRouter,
  withStyles(styles),
  translateHOC
);

const CreditsListOwnerFields = ({ ...props }) => {
  return (
    <Datagrid {...props} id="Selenium-Business-Credits-Owner-Table">
      <TextField source="promoCodeReference" sortable={false} label="credits.list.fields.reference" />
      <DateField source="validityEndDate" sortable={false} label="credits.list.fields.expirationDate" />
      <TextField source="availableAmount" sortable={false} label="credits.list.fields.creditsLeft" />
    </Datagrid>
  );
};

class CreditsListOwner extends React.PureComponent {
  state = {
    promoteCode: '',
  };

  handleRedeemInput = event => {
    const { value } = event.target;
    this.setState({ promoteCode: value });
  };

  handleRedeemSubmit = () => {
    const { history, id, basePath, dispatch, dataProvider } = this.props;
    const { promoteCode } = this.state;
    dispatch(fetchStart());
    return dataProvider(CREATE, 'promoteCodes', { data: { promoteCode } })
      .then(() => {
        dispatch(showNotification('notification.redeemCodeSuccess'));
        history.push(`${basePath}/${id}`);
        setTimeout(() => history.push(`${basePath}/${id}/credits`));
      })
      .catch(err => {
        const errObject = JSON.parse(err.message);

        const { errorCode, httpStatusCode, httpStatus } = errObject;

        console.error(`Error: ${httpStatusCode}`, {
          httpStatusCode,
          httpStatus,
          errorCode,
        });
        dispatch(showNotification(`error.${errorCode}`, 'warning'));
      })
      .finally(dispatch(fetchEnd()));
  };

  render() {
    const { id } = this.props;
    const record = { id };
    const { promoteCode } = this.state;
    return (
      <>
        <AddCredits {...this.props} />
        <Divider hasNoBorder label="credits.list.title.owner" />
        <ReferenceManyField
          {...this.props}
          pagination={<Pagination />}
          perPage={10}
          reference="credits"
          target="business"
          record={record}
        >
          <CreditsListOwnerFields />
        </ReferenceManyField>
        <CreditsListOwnerRedeem
          {...this.props}
          promoteCode={promoteCode}
          handleRedeemInput={this.handleRedeemInput}
          handleRedeemSubmit={this.handleRedeemSubmit}
        />
      </>
    );
  }
}

CreditsListOwner.propTypes = {
  id: PropTypes.string,
  history: PropTypes.shape({}),
  basePath: PropTypes.string,
  dataProvider: PropTypes.func,
  classes: PropTypes.shape({}),
  translate: PropTypes.func,
  promoteCode: PropTypes.string,
  dispatch: PropTypes.func,
};

export default enhance(CreditsListOwner);
// For Tests
export const CreditsListOwnerTest = CreditsListOwner;
export const CreditsListOwnerRedeemTest = CreditsListOwnerRedeem;
