import React from 'react';
import PropTypes from 'prop-types';
import {
  showNotification,
  withDataProvider,
  translate as translateHOC,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  Button,
  fetchStart,
  fetchEnd,
  CREATE,
  SaveButton,
  NumberInput,
  FormDataConsumer,
} from 'react-admin';
import { submit, reset, isSubmitting as isSubmittingAction, change } from 'redux-form';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconSend from '@material-ui/icons/Send';
import IconCancel from '@material-ui/icons/Cancel';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core';
import { refreshView } from 'ra-core';

const styles = {
  selectProduct: {
    '& > div': {
      width: '100%',
      marginTop: 0,
    },
  },
  formField: {
    display: 'inline-block',
    width: '28%',
    marginRight: '5%',
    marginBottom: 15,
    marginTop: 20,
    '& > div': {
      width: '100%',
      margin: 0,
    },
  },
  fullWidth: {
    width: '100%',
    margin: 0,
  },
  labelTotal: {
    color: 'black',
    marginBottom: 10,
    display: 'block',
  },
  totalInfos: {
    padding: '5px 24px',
  },
  formContainer: {
    width: '100%',
  },
  dialogContent: {
    padding: '10px 24px 0',
  },
  dialogTitle: {
    paddingBottom: 0,
  },
  labelType: {
    fontSize: '0.75rem',
  },
};

class ChargeProduct extends React.PureComponent {
  state = {
    selectedProduct: null,
    selectedAmount: null,
  };

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(reset('charge-create'));
  }

  handleSaveClick = () => {
    const { dispatch } = this.props;
    dispatch(submit('charge-create'));
  };

  handleSubmit = record => {
    const { dispatch, handleCloseDialog, dataProvider, entityId } = this.props;
    dispatch(fetchStart());
    const dataToSend = { amount: record.amount, productId: record.productId, entityId };
    return dataProvider(CREATE, 'charge', { id: dataToSend.id, data: dataToSend })
      .then(() => {
        dispatch(reset('charge-create'));
        dispatch(showNotification('notification.chargeProductSucces'));
        handleCloseDialog();
        dispatch(refreshView());
      })
      .catch(err => {
        const errObject = JSON.parse(err.message);

        const { errorCode, message, httpStatusCode, httpStatus } = errObject;

        console.error(`Error: ${httpStatusCode}`, {
          httpStatusCode,
          httpStatus,
          errorCode,
        });

        const matchMessage = message.match(/"message":"([a-z ]+)"/i);
        if (matchMessage && matchMessage[1]) {
          dispatch(showNotification(matchMessage[1], 'warning'));
        } else {
          dispatch(showNotification(`error.${errorCode}`, 'warning'));
        }
      })
      .finally(() => {
        dispatch(fetchEnd());
      });
  };

  computeNetAmount = ({ taxIncluded, price, taxRate }, selectedAmount) => {
    const amount = selectedAmount || price;
    let netAmountDisplay = 0;
    if (taxIncluded) {
      netAmountDisplay = amount / (1 + taxRate);
    } else {
      netAmountDisplay = parseFloat(amount);
    }
    return netAmountDisplay.toFixed(2);
  };

  computeTaxAmount = ({ taxIncluded, price, taxRate }, selectedAmount) => {
    const amount = selectedAmount || price;
    let taxAmountDisplay = 0;
    if (taxIncluded) {
      const withoutTaxPrice = amount / (1 + taxRate);
      taxAmountDisplay = withoutTaxPrice * taxRate;
    } else {
      taxAmountDisplay = amount * taxRate;
    }
    return taxAmountDisplay.toFixed(2);
  };

  render() {
    const {
      translate,
      handleCloseDialog,
      classes,
      isSubmitting,
      dispatch,
      currency,
      products,
      services: servicesState,
    } = this.props;
    const { selectedProduct, selectedAmount } = this.state;

    return (
      <React.Fragment>
        <Dialog maxWidth="sm" fullWidth open onClose={handleCloseDialog}>
          <DialogTitle className={classes.dialogTitle}>{translate('resources.billing.charge.title')}</DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <div className={classes.formContainer}>
              <SimpleForm
                basePath=""
                resource="charge"
                form="charge-create"
                onSubmit={this.handleSubmit}
                toolbar={null}
              >
                <FormDataConsumer>
                  {({ formData, entityId, ...rest }) => (
                    <>
                      <div className={classes.selectProduct}>
                        <ReferenceInput
                          inputProps={{ id: 'Selenium-Business-Billing-ChargeProduct-Product-Select' }}
                          label="resources.billing.charge.fields.selectProduct"
                          source="productId"
                          reference="product"
                          filter={{ hideSubscription: true, services: servicesState }}
                          className={classes.fullWidth}
                          onChange={(event, value) => {
                            this.setState({ selectedProduct: products[value] });
                            dispatch(change('charge-create', 'amount', products[value].price || selectedAmount));
                            dispatch(change('charge-create', 'tax', products[value].taxRate || 0));
                            if (products[value].price) {
                              this.setState({ selectedAmount: products[value].price });
                            }
                          }}
                          {...rest}
                        >
                          <SelectInput optionText="name" className={classes.fullWidth} />
                        </ReferenceInput>
                      </div>
                      <div className={classes.formField}>
                        <InputLabel className={classes.labelType}>
                          {translate('resources.billing.charge.fields.type')}
                        </InputLabel>
                        <Input
                          id="Selenium-Business-Billing-ChargeProduct-Type-Input"
                          source="type"
                          className={classes.fullWidth}
                          disabled
                          value={
                            selectedProduct && selectedProduct.taxIncluded
                              ? translate('resources.billing.charge.fields.totalAmount')
                              : translate('resources.billing.charge.fields.netAmount')
                          }
                        />
                      </div>
                      <div className={classes.formField}>
                        <NumberInput
                          id="Selenium-Business-Billing-ChargeProduct-Amount-Input"
                          source="amount"
                          inputProps={{
                            min: 0,
                          }}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          InputProps={{
                            endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                          }}
                          className={classes.fullWidth}
                          onChange={(event, value) => {
                            this.setState({ selectedAmount: value });
                          }}
                          disabled={!selectedProduct}
                          {...rest}
                          error={selectedProduct && !selectedAmount}
                          required
                        />
                      </div>
                      <div className={classes.formField}>
                        <TextInput
                          id="Selenium-Business-Billing-ChargeProduct-Tax-Input"
                          source="tax"
                          className={classes.fullWidth}
                          disabled
                          format={v => {
                            if (v) {
                              return v * 100;
                            }
                            if (v === 0) {
                              return 0;
                            }
                            return '';
                          }}
                          parse={v => (v ? v / 100 : 0)}
                          InputProps={{
                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                          }}
                          {...rest}
                        />
                      </div>
                    </>
                  )}
                </FormDataConsumer>
              </SimpleForm>
            </div>
            {selectedProduct && selectedAmount && (
              <div className={classes.totalInfos}>
                <InputLabel id="Selenium-Business-Billing-ChargeProduct-totalNet" className={classes.labelTotal}>
                  {translate('resources.billing.charge.fields.net')}:{' '}
                  {this.computeNetAmount(selectedProduct, selectedAmount)}
                  {` ${currency}`}
                </InputLabel>
                <InputLabel id="Selenium-Business-Billing-ChargeProduct-totalTax" className={classes.labelTotal}>
                  {translate('resources.billing.charge.fields.tax')}:{' '}
                  {this.computeTaxAmount(selectedProduct, selectedAmount)}
                  {` ${currency}`}
                </InputLabel>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <SaveButton
              id="Selenium-Business-Billing-ChargeProduct-Save-Button"
              saving={isSubmitting}
              onClick={this.handleSaveClick}
              label="common.send"
              icon={<IconSend />}
              disabled={!selectedProduct || !selectedAmount}
            />
            <Button
              label="common.cancel"
              onClick={handleCloseDialog}
              size="medium"
              id="Selenium-Business-Billing-ChargeProduct-Cancel-Button"
            >
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
}

ChargeProduct.propTypes = {
  id: PropTypes.string,
  dataProvider: PropTypes.func,
  classes: PropTypes.shape({}),
  record: PropTypes.shape({}),
  translate: PropTypes.func,
  promoteCode: PropTypes.string,
  dispatch: PropTypes.func,
  isSubmitting: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
  currency: PropTypes.string,
  entityId: PropTypes.string,
  products: PropTypes.shape({}),
  services: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = state => ({
  isSubmitting: isSubmittingAction('product')(state),
  products: state.admin.resources.product.data,
  services: state.services,
});

export default withDataProvider(translateHOC(withStyles(styles)(connect(mapStateToProps)(ChargeProduct))));
// For Tests
export const ChargeProductTest = ChargeProduct;
