import { CREATE } from 'react-admin';
import utils from '../../shared/utils';

const dataCredits = (instance, type, params) => {
  const { id } = utils.getParameters().business;
  const opt = {};
  switch (type) {
    case CREATE: {
      if (params.data.file) {
        opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${id}/user/bulk`;
        const data = new FormData();
        data.append('file', params.data.file);
        data.append('locale', params.data.locale);
        opt.data = data;
      } else {
        opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${id}/user/invite`;
        const query = new URLSearchParams();
        if (params.data.firstName) {
          query.append('firstName', params.data.firstName);
        }
        if (params.data.lastName) {
          query.append('lastName', params.data.lastName);
        }
        if (params.data.costCenterId) {
          query.append('costCenterId', params.data.costCenterId);
        }
        if (params.data.locale) {
          query.append('locale', params.data.locale);
        }
        query.append('email', params.data.email);
        opt.params = query;
      }
      opt.method = 'POST';
      break;
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
  return instance(opt);
};

export default dataCredits;
