import { combineReducers } from 'redux';

import menu from './menu';
import grants from './grants';
import login2FA from './login2FA';
import currentUser from './user';
import services from './services';
import fleet from '../redux/config/reducer';
import stripeReducer from '../redux/stripe/reducer';
import paymentDetails from '../redux/paymentDetails/reducer';
import adyenReducer from '../redux/adyen/reducer';

export default {
  menu,
  grants,
  login2FA,
  currentUser,
  services,
  configuration: combineReducers({
    fleet,
    stripe: stripeReducer,
    adyen: adyenReducer,
  }),
  application: combineReducers({
    paymentDetails,
  }),
};
