import React from 'react';
import PropTypes from 'prop-types';
import {
  ReferenceManyField,
  Pagination,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  UPDATE,
  refreshView,
  showNotification,
  fetchEnd,
  withDataProvider,
  translate as translateHOC,
} from 'react-admin';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, withStyles } from '@material-ui/core';
import ActionCheck from '@material-ui/icons/CheckCircle';
import withGrants from '../../../WithGrants';
import AddCredits from '../AddCredits';

const CreditsListAdminFields = ({ translate, openRevokeDialog, ...props }) => {
  return (
    <Datagrid {...props} id="Selenium-Business-Credits-Admin-Table">
      <TextField source="initialAmount" sortable={false} label="credits.list.fields.initialAmount" />
      <TextField source="usedAmount" sortable={false} label="credits.list.fields.usedAmount" />
      <DateField source="validityEndDate" sortable={false} label="credits.list.fields.expirationDate" />
      <DateField source="validityStartDate" sortable={false} label="credits.list.fields.startDate" />
      <FunctionField
        render={ref =>
          ref && ref.availableAmount && ref.availableAmount > 0 ? (
            <Button
              id={`Selenium-Business-Credits-list-openRevokeDialog-${ref.id}`}
              variant="outlined"
              size="small"
              color="primary"
              onClick={() => openRevokeDialog(ref)}
            >
              {translate('credits.list.button.revoke')}
            </Button>
          ) : null
        }
      />
    </Datagrid>
  );
};
CreditsListAdminFields.propTypes = {
  translate: PropTypes.func.isRequired,
  openRevokeDialog: PropTypes.func.isRequired,
};

const DialogRevokeStyles = theme => ({
  leftIcon: {
    marginRight: theme.spacing.unit,
  },
});

const DialogRevokeSimple = ({ classes, translate, open, onClose, onSubmit }) => (
  <Dialog open={open} onClose={onClose} maxWidth="md">
    <DialogTitle id="revoke-dialog-title">{translate('credits.list.dialog.title')}</DialogTitle>
    <DialogContent id="revoke-dialog-content">
      <Typography>{translate('credits.list.dialog.content')}</Typography>
    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={onClose} size="medium" id="Selenium-Business-Credits-Revoke-Close-Button">
        {translate('common.cancel')}
      </Button>
      <Button color="primary" onClick={onSubmit} size="medium" id="Selenium-Business-Credits-Revoke-Confirm-Button">
        <ActionCheck className={classes.leftIcon} />
        {translate('common.confirm')}
      </Button>
    </DialogActions>
  </Dialog>
);

DialogRevokeSimple.propTypes = {
  translate: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  classes: PropTypes.shape({
    leftIcon: PropTypes.string,
  }),
};

const DialogRevoke = withStyles(DialogRevokeStyles)(DialogRevokeSimple);

class CreditsListAdmin extends React.PureComponent {
  state = {
    // True: Show the Add Credits dialog
    isShowDialogRevoke: false,
    creditSelected: null,
  };

  onRevoke = () => {
    const { cancelRevokeDialog } = this;
    const { creditSelected } = this.state;
    const { dataProvider, dispatch } = this.props;

    return dataProvider(UPDATE, 'credits', { data: creditSelected })
      .then(() => {
        dispatch(showNotification('notification.credits.revokeSuccess'));
        dispatch(refreshView());
        cancelRevokeDialog();
      })
      .catch(err => {
        const errObject = JSON.parse(err.message);

        const { errorCode, httpStatusCode, httpStatus } = errObject;

        console.error(`Error: ${httpStatusCode}`, {
          httpStatusCode,
          httpStatus,
          errorCode,
        });
        dispatch(showNotification(`error.${errorCode}`, 'warning'));
        cancelRevokeDialog();
      })
      .finally(() => {
        dispatch(fetchEnd());
        cancelRevokeDialog();
      });
  };

  openRevokeDialog = creditSelected => this.setState({ isShowDialogRevoke: true, creditSelected });

  cancelRevokeDialog = () => this.setState({ isShowDialogRevoke: false, creditSelected: null });

  render() {
    const { onRevoke, openRevokeDialog, cancelRevokeDialog } = this;
    const { id, translate } = this.props;
    const record = { id };
    const { isShowDialogRevoke } = this.state;
    return (
      <>
        <AddCredits {...this.props} />
        <ReferenceManyField
          {...this.props}
          pagination={<Pagination />}
          perPage={10}
          reference="credits"
          target="business"
          record={record}
        >
          <CreditsListAdminFields openRevokeDialog={openRevokeDialog} translate={translate} />
        </ReferenceManyField>
        {isShowDialogRevoke && (
          <DialogRevoke
            translate={translate}
            open={isShowDialogRevoke}
            onClose={cancelRevokeDialog}
            onSubmit={onRevoke}
          />
        )}
      </>
    );
  }
}

CreditsListAdmin.propTypes = {
  id: PropTypes.string,
  classes: PropTypes.shape({}),
  translate: PropTypes.func,
  promoteCode: PropTypes.string,
  dispatch: PropTypes.func,
  handleCloseDialog: PropTypes.func,
  checkPermission: PropTypes.func,
  openRevokeDialog: PropTypes.func,
  dataProvider: PropTypes.func,
};

export default withGrants(withDataProvider(translateHOC(CreditsListAdmin)));

// For Tests
export const CreditsListAdminTest = CreditsListAdmin;
export const CreditsListAdminFieldsTest = CreditsListAdminFields;
