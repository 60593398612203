import React from 'react';
import { Switch as MuiSwitch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const styles = ({ palette }) => ({
  checked: {
    '&$disabled': {
      color: palette.secondary.main,
      opacity: 0.35,
    },
    '&$disabled + $bar': {
      backgroundColor: palette.secondary.main,
    },
  },
  bar: {},
  disabled: {},
});

/**
 * Returns a MUI Switch component with disabled+checked faded color
 * @returns React component
 */
const Switch = ({ classes, ...other }) => (
  <MuiSwitch
    classes={{
      bar: classes.bar,
      disabled: classes.disabled,
      checked: classes.checked,
    }}
    {...other}
  />
);
Switch.propTypes = {
  color: PropTypes.string,
  classes: PropTypes.shape({
    checked: PropTypes.string.isRequired,
    bar: PropTypes.string.isRequired,
    disabled: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(Switch);
