import { takeLatest, put } from 'redux-saga/effects';
import axios from 'axios';
import { getBusinessConf } from '../config';
import { grantLoadAction } from '../actions/grantLoadAction';

function* grantLoad(params) {
  const { payload } = params;
  const resourceName = payload.name;
  if (resourceName === 'grant') {
    const { config } = yield getBusinessConf();
    const instance = axios.create({
      baseURL: config.login.baseUrl,
      timeout: config.timeout || 30000,
    });
    let accessToken = sessionStorage.getItem('accessToken') || null;
    if (accessToken) {
      accessToken = JSON.parse(accessToken).token;
    }

    let refreshToken = localStorage.getItem('refreshToken') || null;
    if (refreshToken) {
      // eslint-disable-next-line prefer-destructuring
      refreshToken = JSON.parse(refreshToken).refreshToken;
    }
    if (accessToken === null) {
      // try to refresh
      const bodyFormData = new URLSearchParams();
      bodyFormData.append('refresh_token', refreshToken);
      bodyFormData.append('client_id', config.login.client_id);
      bodyFormData.append('client_secret', config.login.client_secret);
      bodyFormData.append('securityOptions', config.login.securityOptions);
      bodyFormData.append('grant_type', 'refresh_token');
      const request = {
        url: `/auth/realms/${config.business_ws.fleetId}/protocol/openid-connect/token`,
        method: 'POST',
        data: bodyFormData,
      };
      let refreshTokenResult = null;
      try {
        refreshTokenResult = yield instance(request);
        accessToken = refreshTokenResult.data.access_token;
        sessionStorage.setItem('accessToken', accessToken);
      } catch (err) {
        console.error('Refresh token error', err);
      }
    }
    if (accessToken !== null) {
      const fleetId = config && config.business_ws && config.business_ws.fleetId;
      const instance2 = axios.create({
        baseURL: config.business_ws.baseUrl,
        timeout: config.timeout || 30000,
        headers: {
          Accept: 'application/json',
          'X-API-KEY': config.login.x_api_key,
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const opt = {
        url: `/business/fleets/${fleetId}/setting/role/grant`,
        method: 'GET',
      };
      try {
        const res = yield instance2(opt);
        yield put(grantLoadAction(res.data));
      } catch (e) {
        console.error(e);
      }
    }
  }
}

export default function* grantSaga() {
  yield takeLatest('RA/REGISTER_RESOURCE', grantLoad);
  // yield takeEvery('RA/USER_CHECK', grantLoad); // doesn't work well
}
