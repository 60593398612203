import React from 'react';
import PropTypes from 'prop-types';
import { InputLabel, MenuItem, Select, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import AutocompleteInput from '../../../shared/autocomplete-input';
import MultipleSelect from '../../MultipleSelect';

const BillingFilters = ({
  classes,
  translate,
  month,
  year,
  monthList,
  yearList,
  costCenterList,
  costCenterSelection,
  userInput,
  handleUserInputChange,
  handleUserChange,
  handleMonthChange,
  handleYearChange,
  handleCostCenterChange,
}) => (
  <div className={classes.wrapper}>
    <div>
      <InputLabel className={classes.fieldContent} htmlFor="Selenium-Business-Billing-Month-Select">
        {translate('common.month')}
      </InputLabel>
      <Select
        className={classes.fieldContent}
        value={month}
        onChange={handleMonthChange}
        inputProps={{
          id: 'Selenium-Business-Billing-Month-Select',
        }}
      >
        {monthList.map(value => (
          <MenuItem key={value} value={value} id={`Selenium-Business-Billing-Month-${value}-Option`}>
            {translate(`common.monthNames.${value}`)}
          </MenuItem>
        ))}
      </Select>
    </div>
    <div className={classes.filterDivider} />
    <div>
      <InputLabel className={classes.fieldContent} htmlFor="Selenium-Business-Billing-Year-Select">
        {translate('common.year')}
      </InputLabel>
      <Select
        className={classes.fieldContent}
        value={year}
        onChange={handleYearChange}
        inputProps={{
          id: 'Selenium-Business-Billing-Year-Select',
        }}
      >
        {yearList.map(value => (
          <MenuItem key={value} value={value} id={`Selenium-Business-Billing-Year-${value}-Option`}>
            {value}
          </MenuItem>
        ))}
      </Select>
    </div>
    <div className={classes.filterDivider} />
    <div className={classes.multipleSelectRow}>
      <span className={classes.multipleSelect}>
        <InputLabel className={classes.fieldContent} htmlFor="Selenium-Business-Billing-User-Input">
          {translate('common.user')}
        </InputLabel>
        <AutocompleteInput
          inputProps={{
            id: 'Selenium-Business-Billing-User-Input',
            value: userInput,
            onChange: handleUserInputChange,
          }}
          resource="businessOwner"
          optionText="fullName"
          onChange={handleUserChange}
        />
      </span>
      <Tooltip title={translate('resources.billing.filterDrawer.costCenterInfoIconTooltip')} placement="bottom">
        <InfoIcon className={classes.infoIcon} color="primary" />
      </Tooltip>
    </div>
    <div className={classes.filterDivider} />
    <div className={classes.multipleSelectRow}>
      <MultipleSelect
        classes={{ container: classes.multipleSelect }}
        id="Selenium-Business-Billing-Cost-Center-MultipleSelect"
        value={costCenterSelection}
        allOptions={costCenterList}
        onChange={event => handleCostCenterChange(event.target.value)}
        inputLabel={translate('resources.billingTrips.fields.costCenter')}
      />
      <Tooltip title={translate('resources.billing.filterDrawer.costCenterInfoIconTooltip')} placement="bottom">
        <InfoIcon className={classes.infoIcon} color="primary" />
      </Tooltip>
    </div>
  </div>
);

BillingFilters.propTypes = {
  classes: PropTypes.shape({}),
  translate: PropTypes.func,
  month: PropTypes.number,
  year: PropTypes.number,
  costCenterList: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  costCenterSelection: PropTypes.arrayOf(PropTypes.string).isRequired,
  monthList: PropTypes.arrayOf(PropTypes.number),
  yearList: PropTypes.arrayOf(PropTypes.number),
  userInput: PropTypes.string.isRequired,
  handleUserInputChange: PropTypes.func.isRequired,
  handleUserChange: PropTypes.func,
  handleMonthChange: PropTypes.func,
  handleYearChange: PropTypes.func,
  handleCostCenterChange: PropTypes.func.isRequired,
};

export default BillingFilters;
