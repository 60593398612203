const loginUserReducer = (state = {}, { type, payload }) => {
  switch (type) {
    case 'LOGIN_SAVE_USER_DATA':
      if (payload) return { payload };
      break;
    default:
      return { ...state };
  }
  return { ...state };
};

export default loginUserReducer;
