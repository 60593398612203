import { CREATE, DELETE, UPDATE, GET_LIST, GET_MANY, GET_MANY_REFERENCE, GET_ONE } from 'react-admin';
import utils from '../../shared/utils';

const dataCostCenter = (instance, type, params, config) => {
  const opt = {};
  const { id } = utils.getParameters().business;
  switch (type) {
    case GET_MANY_REFERENCE:
    case GET_MANY:
    case GET_LIST: {
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${id}/costcenter`;
      opt.method = 'GET';
      break;
    }
    case GET_ONE: {
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${id}/costcenter/${params.id}`;
      opt.method = 'GET';
      break;
    }
    case CREATE: {
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${id}/costcenter`;
      opt.method = 'POST';
      opt.data = params.data;
      break;
    }
    case UPDATE: {
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${id}/costcenter/${params.data.id}`;
      opt.method = 'PUT';
      opt.data = Object.assign({}, params.data);
      break;
    }
    case DELETE: {
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${id}/costcenter/${params.id}`;
      opt.method = 'DELETE';
      break;
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
  return instance(opt);
};

export default dataCostCenter;
