import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import utils from '../shared/utils';
import settings from '../core/settings';

const WithGrants = WrappedComponent => {
  const Sub = ({ dispatch, ...props }) => {
    const { grants } = props;
    if (!grants) {
      settings.load();
    }

    const roles = {
      admin: 'BUSINESS_ADMIN',
      customer: 'BUSINESS_CUSTOMER',
      owner: 'BUSINESS_OWNER',
      'delegated admin': 'BUSINESS_DELEGATED_ADMIN',
    };
    const checkPermission = job => {
      // If the user is admin, we just give the permission
      const isAdmin = localStorage.getItem('role') === 'admin';
      if (isAdmin) {
        return true;
      }

      if (!utils.getParameters().business) {
        return false;
      }
      if (!grants) {
        // there is an issue! tryng to rerender before having correct Grants
        // actually you just need to exit from this function if there are no permissions.. it will be called again...
        return null;
      }

      const businessId = utils.getParameters().business.id;
      const role = roles[utils.getRoles(businessId, isAdmin)];
      if (role === roles.admin) {
        return true;
      }
      if (role === roles.customer) {
        return false;
      }

      for (let i = 0; i < grants.length; i += 1) {
        const permission = grants[i];
        if (role === permission.roleName) {
          for (let j = 0; j < permission.grants.length; j += 1) {
            const grant = permission.grants[j];
            if (grant.name === job) {
              return true;
            }
          }
        }
      }
      return false;
    };
    return <WrappedComponent {...props} checkPermission={checkPermission} />;
  };
  Sub.propTypes = {
    grants: PropTypes.arrayOf(PropTypes.shape({})),
    dispatch: PropTypes.func,
  };
  return Sub;
};

const mapStateToProps = state => ({
  grants: state.grants.permissions,
});

const composedWithGrantsWrapper = compose(
  connect(mapStateToProps),
  WithGrants
);

WithGrants.propTypes = {
  grants: PropTypes.arrayOf(PropTypes.shape({})),
  checkPermission: PropTypes.func.isRequired,
};

export default composedWithGrantsWrapper;
