import React from 'react';
import PropTypes from 'prop-types';
import { Button as RaButton, translate as translateHOC } from 'react-admin';
import { Button as MuiButton, DialogActions } from '@material-ui/core';
import IconCancel from '@material-ui/icons/Cancel';
import { CARD, SEPA, START } from './stripe/Constants';

const PaymentMethodPopupActions = ({
  isPaymentSubmitted,
  closeDialog,
  cancelDialog,
  step,
  setStep,
  selectedPaymentMethod,
  translate,
  paymentProvider,
}) => {
  const handleNext = () => setStep(selectedPaymentMethod);

  const handlePrevious = () => setStep(START);

  const ExitButton = () =>
    isPaymentSubmitted ? (
      <RaButton
        label="common.close"
        onClick={closeDialog}
        size="medium"
        id="Selenium-paymentDetails-Edit-paymentServer-Button-Close"
      >
        <IconCancel />
      </RaButton>
    ) : (
      <RaButton
        label="common.cancel"
        onClick={cancelDialog}
        size="medium"
        id="Selenium-paymentDetails-Edit-paymentServer-Button-Cancel"
      >
        <IconCancel />
      </RaButton>
    );

  return (
    <DialogActions>
      {(step === CARD || step === SEPA) && paymentProvider === 'stripe' && (
        <MuiButton onClick={handlePrevious} color="primary">
          {translate('common.previous')}
        </MuiButton>
      )}
      <ExitButton />
      {step === START && paymentProvider === 'stripe' && (
        <MuiButton onClick={handleNext} disabled={!selectedPaymentMethod} variant="contained" color="primary">
          {translate('resources.paymentDetails.editPopup.next')}
        </MuiButton>
      )}
    </DialogActions>
  );
};
PaymentMethodPopupActions.propTypes = {
  isPaymentSubmitted: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  cancelDialog: PropTypes.func.isRequired,
  step: PropTypes.string.isRequired,
  setStep: PropTypes.func.isRequired,
  selectedPaymentMethod: PropTypes.string,
  translate: PropTypes.func.isRequired,
  paymentProvider: PropTypes.string,
};

export default translateHOC(PaymentMethodPopupActions);
