export const SAVE_SERVICES = 'SAVE_SERVICES';

const servicesReducer = (state = [], { type, payload }) => {
  switch (type) {
    case SAVE_SERVICES:
      if (payload) {
        return payload;
      }
      return state;
    default:
      return state;
  }
};

export default servicesReducer;
