import { GET_ONE } from 'react-admin';

const dataStripe = (instance, type, { field, businessId }) => {
  const opt = {};
  const fleetId = localStorage.getItem('fleetID');

  switch (type) {
    case GET_ONE:
      switch (field) {
        case 'publishableKey':
          opt.url = `/business/fleets/${fleetId}/stripe/config/key`;
          opt.method = 'GET';
          break;
        case 'getStripeClientSecret':
          opt.url = `/business/fleets/${fleetId}/stripe/businesses/${businessId}/add`;
          opt.method = 'GET';
          break;

        default:
          throw new Error(`Need provide a field to get`);
      }
      break;
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
  return instance(opt);
};

export default dataStripe;
