import { GET_MANY } from 'react-admin';

export default (instance, type, params, config) => {
  const opt = {};
  switch (type) {
    case GET_MANY:
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/users/list`;
      opt.method = 'POST';
      opt.data = params.ids;
      break;
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
  return instance(opt);
};
