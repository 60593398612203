import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Sidebar, Layout, Logout } from 'react-admin';
import { withStyles, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Menu from './menu';
import SearchControl from '../components/search/SearchControl';
import VulogUserMenu from './VulogUserMenu';
import { GET_CURRENT_USER } from './actions/getCurrentUser';
import { userLogoutAction } from './actions/user';
import AppBar from './AppBar';
import { getFleetConfig } from './redux/config/actions';

const styles = {
  root: {
    zIndex: 'unset',
  },
  title: {
    fontSize: '1.3125rem',
    fontWeight: 500,
    flex: 10,
  },
};

const SearchAppBar = props => {
  return (
    <AppBar {...props} userMenu={<VulogUserMenu />}>
      <Typography color="inherit" style={styles.title} id="react-admin-title" />
      <SearchControl {...props} />
    </AppBar>
  );
};

const SimpleAppBar = props => {
  return <AppBar {...props} userMenu={<VulogUserMenu />} />;
};

const mapDispatchToProps = dispatch => ({
  userLogout: () => dispatch(userLogoutAction()),
});

/**
 * I'm extending the react-admin <Logout /> to include the removal of
 * 'selectedHost' from the sessionStorage. The dispatch of userLogout is
 * taken from react-admin source code.
 */
const BboLogout = connect(
  null,
  mapDispatchToProps
)(({ userLogout }) => {
  const handleClick = () => {
    // If there is a host (development mode) then remove it.
    sessionStorage.removeItem('selectedHost');
    userLogout();
  };

  return <Logout onClick={handleClick} />;
});

const VulogLayout = ({ classes, dispatch, user, getCurrentUser, getFleetConfigAction, ...other }) => {
  const isAdmin = localStorage.getItem('role') === 'admin';

  useEffect(() => {
    if (!user || !Object.keys(user).length) {
      getCurrentUser();
    }

    getFleetConfigAction();
  }, []);

  return (
    <Layout
      {...other}
      title="Business FO"
      appBar={isAdmin ? SearchAppBar : SimpleAppBar}
      sidebar={Sidebar}
      menu={Menu}
      logout={<BboLogout />}
      classes={{ root: classes.root }}
    />
  );
};

VulogLayout.propTypes = {
  classes: PropTypes.shape({}),
  pathname: PropTypes.string,
  dispatch: PropTypes.func,
  user: PropTypes.shape({}),
  getCurrentUser: PropTypes.func.isRequired,
  getFleetConfigAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  pathname: (state.router.location && state.router.location.pathname) || null,
  user: state.currentUser,
});

const mapActionToProps = dispatch => ({
  getCurrentUser: () => dispatch({ type: GET_CURRENT_USER }),
  getFleetConfigAction: () => dispatch(getFleetConfig()),
});

export default connect(
  mapStateToProps,
  mapActionToProps
)(withStyles(styles)(VulogLayout));
