import IconButton from '@material-ui/core/IconButton';
import TableConfigurationsIcon from '@material-ui/icons/Settings';
import React from 'react';
import * as PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import withGrants from '../../components/WithGrants';

const styles = () => ({
  cardActions: {
    float: 'right',
  },
});

const DatagridActions = ({ classes, openDrawer, checkPermission }) => (
  <div className={classes.cardActions}>
    {checkPermission('CONFIGURE_TABLE_COLUMNS') && (
      <div>
        <IconButton onClick={openDrawer} className="Selenium-Table-DataActions-ColumnConfigurations-Button">
          <TableConfigurationsIcon />
        </IconButton>
      </div>
    )}
  </div>
);

DatagridActions.propTypes = {
  classes: PropTypes.shape({
    cardActions: PropTypes.string,
  }),
  openDrawer: PropTypes.func,
  checkPermission: PropTypes.func,
};

export default withGrants(withStyles(styles)(DatagridActions));
