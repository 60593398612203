import React from 'react';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { Button, withStyles } from '@material-ui/core';
import { translate as translateHOC } from 'react-admin';
import withGrants from '../WithGrants';

const styles = {
  button: {
    float: 'right',
    margin: '0 20px 10px 0',
  },
};

const CustomButton = ({ classes, label, onClick }) => (
  <Button type="submit" size="medium" color="secondary" onClick={onClick} className={classes.button}>
    {label}
  </Button>
);

CustomButton.propTypes = {
  onClick: PropTypes.func,
  classes: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  label: PropTypes.string,
};

const UpdateBusinessPaymentButtonBar = ({
  classes,
  translate,
  onChangePaymentClick,
  onSetupWireTransferClick,
  checkPermission,
}) => {
  if (!checkPermission('UPDATE_BUSINESS_PAYMENT')) {
    return null;
  }

  return (
    <>
      <CustomButton
        classes={classes}
        label={translate('resources.paymentDetails.buttons.changepayment')}
        onClick={onChangePaymentClick}
      />
      <CustomButton
        classes={classes}
        label={translate('resources.paymentDetails.buttons.setupIban')}
        onClick={onSetupWireTransferClick}
      />
    </>
  );
};
UpdateBusinessPaymentButtonBar.propTypes = {
  classes: PropTypes.shape({
    button: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
  onChangePaymentClick: PropTypes.func.isRequired,
  onSetupWireTransferClick: PropTypes.func.isRequired,
  checkPermission: PropTypes.func.isRequired,
};
const enhance = compose(
  withGrants,
  translateHOC,
  withStyles(styles)
);
export default enhance(UpdateBusinessPaymentButtonBar);
