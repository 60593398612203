import React from 'react';
import * as PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  Typography,
} from '@material-ui/core';

const RefundDialog = ({ isOpen, toggleDialog, setAmount, amount, invoice, onRefund, translate, refundableAmount }) => (
  <Dialog
    open={isOpen}
    onClose={toggleDialog}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth
    maxWidth="xs"
  >
    <DialogTitle id="alert-dialog-title">{translate('resources.invoice.refund')}</DialogTitle>
    <DialogContent>
      <Typography style={{ marginBottom: 16 }}>
        {translate('resources.invoice.refundDialog.refundBetween', { maxAmount: refundableAmount })}
      </Typography>
      <FormControl fullWidth>
        <InputLabel htmlFor="standard-refund-amount">{translate('resources.invoice.refundDialog.amount')}</InputLabel>
        <Input
          id="standard-refund-amount"
          type="number"
          inputProps={{ min: 0, max: refundableAmount }}
          value={amount}
          placeholder={refundableAmount ? refundableAmount.toString() : ''}
          onChange={e => setAmount(e.target.value)}
          startAdornment={<InputAdornment position="start">{invoice.currency}</InputAdornment>}
        />
      </FormControl>
    </DialogContent>
    <DialogActions>
      <Button onClick={toggleDialog} color="primary" id="refundDialog-cancel-button">
        {translate('common.cancel')}
      </Button>
      <Button
        onClick={onRefund}
        color="primary"
        variant="contained"
        id="refundDialog-refund-button"
        autoFocus
        disabled={amount > refundableAmount}
      >
        {translate('resources.invoice.refund')}
      </Button>
    </DialogActions>
  </Dialog>
);

RefundDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  setAmount: PropTypes.func.isRequired,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  invoice: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]).isRequired,
  onRefund: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  refundableAmount: PropTypes.number,
};

export default RefundDialog;
