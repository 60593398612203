import { GET_MANY } from 'react-admin';

export default (res, params, type) => {
  const data = res;
  switch (type) {
    case GET_MANY:
      return { data };

    default:
      break;
  }
  return data;
};
