import { GET_MANY, GET_LIST } from 'react-admin';
import utils from '../../shared/utils';

const dataBusinessOwner = (instance, type, params, config) => {
  const opt = {};
  switch (type) {
    case GET_LIST:
    case GET_MANY: {
      // Convert parameters
      const { id } = utils.getParameters().business;
      const query = new URLSearchParams();
      query.append('page', 0);
      query.append('size', 10);
      if (params.filter) {
        query.append('fullname', params.filter.q ? params.filter.q : '');
      }
      //
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${id}/users/name`;
      opt.method = 'GET';
      opt.params = query;
      break;
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
  return instance(opt);
};

export default dataBusinessOwner;
