import React, { useEffect, useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PropTypes from 'prop-types';
import { FormControlLabel, Radio, RadioGroup, Typography, withStyles } from '@material-ui/core';
import { compose } from 'redux';
import { connect } from 'react-redux';
import CardSetupForm from './CardSetupForm';
import { CARD, SEPA, START } from './Constants';
import SepaForm from './SepaForm';
import { getClientSecret } from '../../../core/redux/stripe/actions';
import { pubKeySelector } from '../../../core/redux/stripe/selectors';

const styles = {
  radioGroup: {
    marginTop: 10,
  },
  radio: {
    height: 36,
  },
};

const Stripe = ({
  pubKey,
  isPostalCodeMandatory,
  handleCloseDialog,
  translate,
  dispatch,
  setMopUpdating,
  step,
  selectedPaymentMethod,
  setSelectedPaymentMethod,
  classes = {},
  onSubmit,
  startSetupIntentStripe,
  synchronizeMOP,
  resetStripeFlow,
}) => {
  const stripePromise = useMemo(() => (pubKey && loadStripe(pubKey)) || null, [pubKey]);

  useEffect(() => {
    startSetupIntentStripe();
  }, []);

  useEffect(() => {
    return () => {
      resetStripeFlow();
    };
  }, []);

  return (
    <Elements stripe={stripePromise}>
      {step === START && (
        <>
          <Typography>{translate('resources.paymentDetails.editPopup.selectPaymentMethod')}</Typography>
          <RadioGroup
            classes={{ root: classes.radioGroup }}
            name="paymentMethods"
            value={selectedPaymentMethod}
            onChange={event => setSelectedPaymentMethod(event.target.value)}
          >
            <FormControlLabel
              classes={{ root: classes.radio }}
              value={CARD}
              control={<Radio />}
              label={translate('resources.paymentDetails.editPopup.card')}
            />
            <FormControlLabel
              classes={{ root: classes.radio }}
              value={SEPA}
              control={<Radio />}
              label={translate('resources.paymentDetails.editPopup.sepa')}
            />
          </RadioGroup>
        </>
      )}
      {step === CARD && (
        <div className="container-fluid">
          <CardSetupForm
            isPostalCodeMandatory={isPostalCodeMandatory}
            translate={translate}
            handleCloseDialog={handleCloseDialog}
            dispatch={dispatch}
            setMopUpdating={setMopUpdating}
            onSubmit={onSubmit}
            synchronizeMOP={synchronizeMOP}
            resetStripeFlow={resetStripeFlow}
          />
        </div>
      )}
      {step === SEPA && <SepaForm closeDialog={handleCloseDialog} dispatch={dispatch} />}
    </Elements>
  );
};

Stripe.propTypes = {
  pubKey: PropTypes.string,
  isPostalCodeMandatory: PropTypes.bool,
  translate: PropTypes.func,
  handleCloseDialog: PropTypes.func,
  dispatch: PropTypes.func,
  setMopUpdating: PropTypes.func.isRequired,
  step: PropTypes.string.isRequired,
  selectedPaymentMethod: PropTypes.string,
  setSelectedPaymentMethod: PropTypes.func.isRequired,
  classes: PropTypes.shape({}),
  onSubmit: PropTypes.func.isRequired,
  startSetupIntentStripe: PropTypes.func.isRequired,
  synchronizeMOP: PropTypes.func.isRequired,
  resetStripeFlow: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  pubKey: pubKeySelector(state),
});

const mapActionToProps = dispatch => ({
  startSetupIntentStripe: () => dispatch(getClientSecret({ paymentMethodType: 'CREDIT_CARD' })),
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapActionToProps
  ),
  withStyles(styles)
);

export default enhance(Stripe);
