import axios from 'axios';
import { all, put, takeEvery } from 'redux-saga/effects';
import { showNotification, fetchEnd } from 'react-admin';
import { getBusinessConf } from '../config';
import { SETUP_ADYEN_INTENT } from '../redux/adyen/constants';
import { updateAdyenMopResultAction } from '../redux/adyen/actions';
import utils from '../../shared/utils';
import { refreshFlow } from '../auth-provider';

/**
 * Creates an instance of axios with custom configuration options and handles authentication and token refreshing.
 *
 * @async
 * @function
 * @param {object} config - The configuration options for the axios instance.
 * @param {string} config.url - The URL for the network request.
 * @param {string} config.method - The HTTP method for the network request.
 * @param {object} [config.data] - The request payload data.
 * @param {string} config.api - The API to use for the request.
 * @param {number} [n=0] - The number of times the function has retried the request due to a 401 response.
 * @returns {Promise<object>} - A Promise that resolves to the response data if successful or rejects with an error if not.
 * @throws {Error} - An error is thrown if the function has retried the request more than three times.
 */
const customAxiosRequestWithAuth = async ({ url, method, data, api }, n = 0) => {
  if (n > 3) {
    return Promise.reject();
  }
  const {
    config,
    config: {
      business_ws: { baseUrl: businessBaseUrl, timeout },
      login: { baseUrl: userBaseUrl, x_api_key: xAPIKey },
    },
  } = getBusinessConf();

  let token = '';

  const isRefreshToken = n > 0;

  try {
    if (isRefreshToken) {
      const refreshInstance = axios.create({
        baseURL: config.login.baseUrl,
        timeout: config.timeout || 30000,
      });
      await refreshFlow(config, refreshInstance);

      const refreshedToken = sessionStorage.getItem('accessToken');

      ({ token } = JSON.parse(refreshedToken));
    } else {
      const accessToken = sessionStorage.getItem('accessToken');

      ({ token } = JSON.parse(accessToken));
    }
  } catch (error) {
    console.error(error);
  }

  const instance = axios.create({
    baseURL: api === 'user' ? userBaseUrl : businessBaseUrl,
    timeout: timeout || 30000,
    headers: {
      Accept: 'application/json',
      'X-API-KEY': xAPIKey,
      Authorization: `Bearer ${token}`,
    },
  });

  const opt = {
    url,
    method,
    ...(data && { data }),
  };

  try {
    const { data: responseData } = await instance(opt);
    return Promise.resolve(responseData);
  } catch (err) {
    if (err.response.status === 401) {
      const responseData = await customAxiosRequestWithAuth({ url, method, data, api, isRefreshToken }, n + 1);

      return Promise.resolve(responseData);
    }
    return Promise.reject(err);
  }
};

export const getPublishableKey = async () => {
  const instanceConfig = {
    url: '/apiv5/user/mop/key',
    method: 'GET',
    api: 'user',
  };

  try {
    const data = await customAxiosRequestWithAuth(instanceConfig);

    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getPaymentDetails = async () => {
  const fleetId = localStorage.getItem('fleetID');
  const businessId = utils.getParameters().business.id;

  const instanceConfig = {
    url: `business/fleets/${fleetId}/business/${businessId}/paymentDetails`,
    method: 'GET',
    api: 'business',
  };

  try {
    const data = await customAxiosRequestWithAuth(instanceConfig);

    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const adyenSetupIntentsSynchronize = async (setupIntentId, redirectResult) => {
  const fleetId = localStorage.getItem('fleetID');
  const businessId = utils.getParameters().business.id;
  const instanceConfig = {
    url: `/business/fleets/${fleetId}/entities/${businessId}/setup_intents/${setupIntentId}/synchronize`,
    method: 'POST',
    api: 'business',
    data: { rawData: { redirectResult } },
  };

  try {
    const data = await customAxiosRequestWithAuth(instanceConfig);

    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

const adyenSetupIntent = async (payload, businessId) => {
  const fleetId = localStorage.getItem('fleetID');
  const {
    paymentMethod: {
      holderName: name,
      encryptedCardNumber: number,
      encryptedExpiryMonth: expiryMonth,
      encryptedExpiryYear: expiryYear,
      encryptedSecurityCode: cvc,
    },
    browserInfo,
  } = payload;

  const instanceConfig = {
    url: `/business/fleets/${fleetId}/entities/${businessId}/setup_intents`,
    method: 'POST',
    data: {
      paymentMethodType: 'CREDIT_CARD',
      returnURL: `${window.location.origin}/#/business/${businessId}/paymentDetails`,
      paymentMethod: {
        card: {
          name,
          number,
          expiryMonth,
          expiryYear,
          cvc,
        },
        browserInfo: {
          ...browserInfo,
          origin,
        },
      },
    },
    api: 'business',
  };

  try {
    const data = await customAxiosRequestWithAuth(instanceConfig);

    return Promise.resolve(data);
  } catch (err) {
    return Promise.reject(err);
  }
};

function* adyenSetupIntentSaga({ payload }) {
  const businessId = utils.getParameters().business.id;

  try {
    const { nextAction, id: setupIntentId, status, reason, declineCode } = yield adyenSetupIntent(payload, businessId);

    if (nextAction) {
      sessionStorage.setItem('setupIntentId', setupIntentId);
      sessionStorage.setItem('entityId', businessId);

      if (nextAction && nextAction.nextActionRedirectUrl && nextAction.nextActionRedirectUrl.url) {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = nextAction.nextActionRedirectUrl.url;
        document.body.appendChild(form);
        Object.entries(nextAction.nextActionRedirectUrl.data).forEach(([key, value]) => {
          const field = document.createElement('input');
          field.type = 'hidden';
          field.key = key;
          field.name = key;
          field.value = value;
          form.append(field);
        });
        form.submit();
      }
    } else if (status === 'SUCCEEDED') {
      yield adyenSetupIntentsSynchronize(setupIntentId);
      const newMop = yield getPaymentDetails();
      yield put(updateAdyenMopResultAction({ finishedUpdating: true, newMop, status }));
      yield put(showNotification('stripe.paymentSuccess', 'success'));
    } else {
      yield put(
        updateAdyenMopResultAction({
          finishedUpdating: true,
          newMop: null,
          status,
          reason,
          declineCode,
        })
      );
      yield put(showNotification('resources.paymentDetails.status.ERROR', 'error'));
    }
    yield put(fetchEnd());
  } catch (error) {
    yield put(showNotification('resources.paymentDetails.status.ERROR', 'error'));
    yield put(
      updateAdyenMopResultAction({
        finishedUpdating: true,
        newMop: null,
        status: 'FAILED',
      })
    );
    yield put(fetchEnd());
    console.error('PaymentError', error);
  }
}

export default function* adyenSaga() {
  yield all([takeEvery(SETUP_ADYEN_INTENT, adyenSetupIntentSaga)]);
}
