import React, { useContext, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Paper, DialogContentText, withStyles } from '@material-ui/core';
import { fetchStart, fetchEnd, translate as translateHOC } from 'react-admin';
import { getLocales } from '../../core/translations';
import utils from '../../shared/utils';
import LanguageButton from './LanguageButton';
import { LocaleContext } from '../../core/contexts/Locale';

const styles = {
  paper: {
    marginTop: '20px',
    padding: '20px',
  },
};

const Preferences = ({ classes, dispatchFetchStart, dispatchFetchEnd, translate }) => {
  const [locales, setLocales] = useState([]);

  useEffect(() => {
    dispatchFetchStart(); // Starts the spinner
    utils.showLoadingScreen(true); // Shows the overlay

    getLocales().then(newLocales => {
      setLocales(newLocales);
      dispatchFetchEnd(); // Stops the spinner
      utils.showLoadingScreen(false); // Hides the overlay
    });
  }, []);

  const { availableLocales } = useContext(LocaleContext);

  const languageButtons = locales.reduce((buttons, localeObject) => {
    if (!availableLocales.includes(localeObject.lang_iso)) {
      return buttons;
    }
    buttons.push(
      <LanguageButton
        key={localeObject.lang_iso}
        languageLocale={localeObject.lang_iso}
        languageName={localeObject.lang_name}
      />
    );
    return buttons;
  }, []);

  return (
    <Paper classes={{ root: classes.paper }}>
      <DialogContentText>{translate('languages.language')}:</DialogContentText>
      {languageButtons}
    </Paper>
  );
};
Preferences.propTypes = {
  translate: PropTypes.func.isRequired,
  dispatchFetchStart: PropTypes.func.isRequired,
  dispatchFetchEnd: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    paper: PropTypes.string.isRequired,
  }).isRequired,
};

const mapDispatchToProps = dispatch => ({
  dispatchFetchStart: () => dispatch(fetchStart()),
  dispatchFetchEnd: () => dispatch(fetchEnd()),
});

export default compose(
  translateHOC,
  connect(
    null,
    mapDispatchToProps
  ),
  withStyles(styles)
)(Preferences);
