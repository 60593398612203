import { GET_MANY_REFERENCE } from 'react-admin';

const dataBusinessInvoice = (res, params, type) => {
  switch (type) {
    case GET_MANY_REFERENCE:
      break;
    default:
      break;
  }
  return res;
};

export default dataBusinessInvoice;
