import { email, name, required } from "../../core/validators";

export const parseInviteCSV = source => {
    let emailCol = 0;
    let firstNameCol = 1;
    let lastNameCol = 2;
    let costCenterValueCol = 3;
    let errorMessage = null;
    let userCount = 0;
    let headerCount = 0;
    const resultList = [];
    // Split source into arrays
    const dataRows = source.split('\n');
    if (dataRows.length < 2) {
        errorMessage = 'notification.bulkInvite.invalidFormat';
        return { resultList: [], errorMessage, userCount };
    }
    // Get column name and its position
    const dataRowNames = dataRows[0].split(',');
    if (dataRowNames.length !== 4) {
        errorMessage = 'notification.bulkInvite.invalidFormat';
        return { resultList: [], errorMessage, userCount };
    }
    dataRowNames.forEach((rowName, index) => {
        switch (rowName.trim()) {
            case 'email':
                emailCol = index;
                headerCount += 1;
                break;
            case 'firstName':
                firstNameCol = index;
                headerCount += 1;
                break;
            case 'lastName':
                lastNameCol = index;
                headerCount += 1;
                break;
            case 'costCenterValue':
                costCenterValueCol = index;
                headerCount += 1;
                break;
            default:
                break;
        }
    });
    // Validate header (require to have email, firstName, lastName, costCenterValue)
    if (headerCount !== 4) {
        errorMessage = 'notification.bulkInvite.invalidHeader';
        return { resultList: [], errorMessage, userCount };
    }
    // Now we validate data
    // We make use of validators to validate name and email
    const props = {
        translate: e => e,
    };
    dataRows.splice(0, 1);
    for (let i = 0; i < dataRows.length; i += 1) {
        if (dataRows[i] !== '') {
            const data = dataRows[i].split(',');
            if (data.length !== 4) {
                errorMessage = 'notification.bulkInvite.invalidFormat';
                return { resultList: [], errorMessage, userCount };
            }
            // Check to see if name is valid or not
            if (
                name(data[firstNameCol], null, props) !== undefined ||
                name(data[lastNameCol], null, props) !== undefined ||
                required(data[firstNameCol], null, props) !== undefined ||
                required(data[lastNameCol], null, props) !== undefined
            ) {
                errorMessage = 'notification.bulkInvite.errorOccurs';
                resultList.push({
                    email: data[emailCol],
                    firstName: data[firstNameCol],
                    lastName: data[lastNameCol],
                    costCenterValue: data[costCenterValueCol],
                    errorMessage: 'notification.bulkInvite.invalidName',
                });
            }
            // Check to see if email is valid or not
            if (email(data[emailCol], null, props) !== undefined || required(data[emailCol], null, props) !== undefined) {
                errorMessage = 'notification.bulkInvite.errorOccurs';
                resultList.push({
                    email: data[emailCol],
                    firstName: data[firstNameCol],
                    lastName: data[lastNameCol],
                    costCenterValue: data[costCenterValueCol],
                    errorMessage: 'notification.bulkInvite.invalidEmail',
                });
            }
            userCount += 1;
        }
    }
    return { resultList, errorMessage, userCount };
};
