// Distance
const distance = (start, end) => {
  return Math.sqrt((start[0] - end[0]) ** 2 + (start[1] - end[1]) ** 2);
};

// Linear interpolation
const linear = (start, stop, t) => {
  return start + (stop - start) * t;
};

// Find the min and max of bounding box
const bbox = points => {
  const min = points[0].slice();
  const max = points[0].slice();
  for (let i = 0; i < points.length; i += 1) {
    const point = points[i];
    const x = point[0];
    const y = point[1];
    if (min[0] > x) {
      min[0] = x;
    }
    if (min[1] > y) {
      min[1] = y;
    }
    if (max[0] < x) {
      max[0] = x;
    }
    if (max[1] < y) {
      max[1] = y;
    }
  }
  return { min, max };
};

export default {
  distance,
  linear,
  bbox,
};
