import React from 'react';
import { Redirect } from 'react-router';
import utils from '../../shared/utils';

export default () => {
  if (utils.getRoles() === 'customer') {
    return <Redirect to="/access-denied" />;
  }

  return <Redirect to="/business" />;
};
