import moment from 'moment-timezone';

/**
 * @param {*} date
 * @param {String} timezone Temporary until we get the fleet's timezone e.g. 'Europe/Paris'
 */
export const formatDateMultiTextField = (date, timezone) => {
  const momentDate = moment(date);
  const dateWithTimezone = timezone ? momentDate.tz(timezone) : momentDate;
  return dateWithTimezone.format('Do MMMM YYYY / HH:mm');
};

/**
 * Converts the input time directly to the input timezone (default: at midnight)
 * and then returns the UTC
 * @param {string} date Input date e.g. '2022-05-20'
 * @param {string} timezone Input timezone e.g. 'Europe/Paris'
 * @param {string} when either start of the day (optional:null = midnight = 0:00:00) or end of the day (='eod' = 23:59:59)
 * @returns {string} Formatted date and time
 */
export const convertToTimezoneToUtc = (date, timezone, when = null) => {
  let momentObj = moment.tz(date, timezone);
  if (when === 'eod') {
    momentObj = momentObj.endOf('day');
  }
  return momentObj.utc().format('YYYY-MM-DD[T]HH:mm:ss[Z]');
};

/**
Calculates the time difference in days between two dates.
@param {string} startDate - The starting date in string format.
@param {string} endDate - The ending date in string format.
@returns {number | null} The time difference in days, or null if the input is invalid.
*/
export const calculateTimeDifferenceInDays = (startDate, endDate) => {
  const startDateTime = Date.parse(startDate);
  const endDateTime = Date.parse(endDate);

  if (!Number.isNaN(startDateTime) && !Number.isNaN(endDateTime)) {
    const timeDifferenceInMs = endDateTime - startDateTime;
    const timeDifferenceInDays = Math.floor(timeDifferenceInMs / (1000 * 60 * 60 * 24));
    return timeDifferenceInDays;
  }
  console.error('Invalid Date Input');
  return null;
};
