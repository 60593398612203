import { withStyles } from '@material-ui/core/styles';
import { Button, Drawer, Checkbox, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import * as PropTypes from 'prop-types';
import React from 'react';
import DnDIcon from '@material-ui/icons/Menu';
import update from 'immutability-helper';
import { compose } from 'redux';
import { FieldTitle, translate as translateHOC } from 'react-admin';
import { DragDropHOC, DraggableTableBodyRow as DraggableRow } from './DraggableTableBodyRow';

const styles = ({ spacing: { unit } }) => ({
  form: {
    marginLeft: 'auto',
  },
  cardActions: {
    alignItems: 'center',
  },
  cell: {
    padding: unit,
  },
  cellCheck: {
    padding: unit,
    '&:last-child': {
      padding: unit,
    },
    textAlign: 'center',
  },
  dropDown: {
    borderBottom: '2px dashed red',
  },
  dropUp: {
    borderTop: '2px dashed red',
  },
  resetGrid: {
    position: 'fixed',
    bottom: 0,
    padding: '0 24px',
    height: '64px',
    alignContent: 'center',
    backgroundColor: '#f5f5f5',
  },
  table: {
    overflowY: 'scroll',
    display: 'block',
    marginBottom: '64px',
  },
});

const SelectionDrawer = ({
  columns,
  defaultColumns,
  onClose,
  resource,
  onColumnChange,
  translate,
  classes: { cell, cellCheck, dropDown, dropUp, table, resetGrid },
}) => {
  const onToggle = ({ target: { checked, value: columnName } }) => {
    const newColumns = columns.reduce((acc, column) => {
      if (column.key === columnName) {
        acc.push({
          ...column,
          show: checked,
        });
      } else {
        acc.push(column);
      }
      return acc;
    }, []);

    onColumnChange(newColumns);
  };

  const moveRow = (dragIndex, hoverIndex) => {
    const dragRow = columns[dragIndex];
    const newColumns = update(columns, {
      $splice: [[dragIndex, 1], [hoverIndex, 0, dragRow]],
    });

    onColumnChange(newColumns);
  };

  const isChecked = columnName => {
    const column = columns.find(c => c.key === columnName);
    return Boolean(column && column.show);
  };

  const handleReset = () => {
    onColumnChange(defaultColumns);
    onClose();
  };

  return (
    <Drawer anchor="right" onClose={onClose} open>
      <Table classes={{ root: table }}>
        <TableHead>
          <TableRow>
            <TableCell className={cell} />
            <TableCell className={cell} />
            <TableCell className={cell}>Active</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {columns.map(({ key, source, label }, index) => {
            return (
              <DraggableRow key={key} classes={{ dropDown, dropUp }} data={key} index={index} moveRow={moveRow}>
                <TableCell size="small" padding="checkbox">
                  <DnDIcon style={{ color: '#64727A' }} />
                </TableCell>
                <TableCell className={cell}>
                  <FieldTitle label={label} source={source} resource={resource} />
                </TableCell>
                <TableCell className={cellCheck}>
                  <Checkbox checked={isChecked(key)} onChange={onToggle} value={key} color="primary" />
                </TableCell>
              </DraggableRow>
            );
          })}
        </TableBody>
      </Table>
      <Grid container className={resetGrid}>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleReset}>
            {translate('common.reset')}
          </Button>
        </Grid>
      </Grid>
    </Drawer>
  );
};

SelectionDrawer.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      source: PropTypes.string,
      key: PropTypes.string.isRequired,
    })
  ).isRequired,
  defaultColumns: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      source: PropTypes.string,
      key: PropTypes.string.isRequired,
    })
  ),
  resource: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onColumnChange: PropTypes.func.isRequired,
  classes: PropTypes.shape({
    cell: PropTypes.string.isRequired,
    cellCheck: PropTypes.string.isRequired,
  }).isRequired,
  translate: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  DragDropHOC,
  translateHOC
)(SelectionDrawer);
