import axios from 'axios';
import { getBusinessConf } from './config';
import utils from '../shared/utils';
import { SAVE_SERVICES } from './reducers/services';

// Load services from server and store in store
export async function loadAllServices() {
  const { config } = getBusinessConf();
  let token = '';
  const accessToken = sessionStorage.getItem('accessToken');
  const fleetId = localStorage.getItem('fleetID');
  if (accessToken) {
    ({ token } = JSON.parse(accessToken));
  } else {
    return Promise.reject();
  }
  const instance = axios.create({
    baseURL: config.business_ws.baseUrl,
    timeout: config.timeout || 30000,
    headers: {
      Accept: 'application/json',
      'X-API-KEY': config.login.x_api_key,
      Authorization: `Bearer ${token}`,
    },
  });
  const opt = {
    url: `/user/fleets/${fleetId}/services`,
    method: 'GET',
  };

  try {
    const res = await instance(opt);

    const { dispatch } = utils.getStore();
    dispatch({ type: SAVE_SERVICES, payload: res.data });

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
}

/**
 * Calls the endpoint once for each service ID
 * @param {string} entityId The business ID
 * @param {Array<string>} serviceIdsToAdd An array of service IDs to add to the fleet
 * @param {Array<string>} serviceIdsToRemove An array of service IDs to remove to the fleet
 * @returns {void} void
 */
export async function savePrivateFleets(entityId, serviceIdsToAdd = [], serviceIdsToRemove = []) {
  const { config } = getBusinessConf();
  let token = '';
  const accessToken = sessionStorage.getItem('accessToken');
  const fleetId = localStorage.getItem('fleetID');
  if (accessToken) {
    ({ token } = JSON.parse(accessToken));
  } else {
    return Promise.reject();
  }
  const instance = axios.create({
    baseURL: config.business_ws.baseUrl,
    timeout: config.timeout || 30000,
    headers: {
      Accept: 'application/json',
      'X-API-KEY': config.login.x_api_key,
      Authorization: `Bearer ${token}`,
    },
  });

  const axiosCalls = (serviceIds, method) =>
    serviceIds.map(serviceId => {
      const opt = {
        url: `/user/fleets/${fleetId}/entities/${entityId}/services/${serviceId}`,
        method,
      };
      return instance(opt);
    });

  try {
    const addPromises = axiosCalls(serviceIdsToAdd, 'PUT');
    const removePromises = axiosCalls(serviceIdsToRemove, 'DELETE');

    return Promise.all([...addPromises, ...removePromises]);
  } catch (err) {
    return Promise.reject(err);
  }
}
