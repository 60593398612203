import React, { Fragment } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { Grid, InputLabel } from '@material-ui/core';
import '../../../shared/styles/stripe.css';
import PropTypes from 'prop-types';

// https://stripe.com/docs/js/appendix/style?type=card
const CARD_OPTIONS = {
  style: {
    base: {
      backgroundColor: 'white',
      fontSize: '16px',
      padding: '10px 12px',
      height: '32px',
      fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
      '::placeholder': {
        color: '#a6a6a6',
      },
    },
    invalid: {
      iconColor: '#ffc7ee',
      color: '#ffc7ee',
    },
  },
};

function CardSection({ onCardChange, classes, translate }) {
  return (
    <Fragment>
      <Grid container spacing={16} className={classes.containerGrid}>
        <Grid item xs={12} sm={8} md={6}>
          <InputLabel shrink>{translate('stripe.cardNumber')}</InputLabel>
          <CardNumberElement
            options={{ showIcon: true, ...CARD_OPTIONS }}
            onChange={event => onCardChange(event, 'number')}
          />
        </Grid>
      </Grid>
      <Grid container spacing={16} className={classes.containerGrid}>
        <Grid item xs={6} sm={4} md={3}>
          <InputLabel shrink>{translate('stripe.expiryDate')}</InputLabel>
          <CardExpiryElement options={CARD_OPTIONS} onChange={event => onCardChange(event, 'expiryDate')} />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <InputLabel shrink>{translate('stripe.CVC')}</InputLabel>
          <CardCvcElement options={CARD_OPTIONS} onChange={event => onCardChange(event, 'cvc')} />
        </Grid>
      </Grid>
    </Fragment>
  );
}

CardSection.propTypes = {
  onCardChange: PropTypes.func,
  translate: PropTypes.func,
  classes: PropTypes.shape({}),
};

export default CardSection;
