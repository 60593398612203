import React from 'react';
import PropTypes from 'prop-types';
import {
  withDataProvider,
  SimpleForm,
  LongTextInput,
  Create,
  Button,
  SimpleShowLayout,
  Toolbar,
  SaveButton,
  translate as translateHOC,
} from 'react-admin';
import { isSubmitting as isSubmittingAction, reset } from 'redux-form';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { withStyles, Typography } from '@material-ui/core';
import IconCancel from '@material-ui/icons/Cancel';
import Divider from '../../../shared/divider';
import { BusinessDetails } from './business-details';
import { BusinessAddress } from './business-address';
import { maxLength } from '../../../core/validators';

const createStyles = ({ spacing }) => ({
  suggestionsContainerOpen: {
    zIndex: 2100,
  },
  field: {
    float: 'left',
    width: '28%',
    minWidth: '200px',
    marginRight: '5%',
    height: '90px',
  },
  fieldContent: {
    width: '100%',
    marginRight: '0',
  },
  block: {
    width: 'fit-content',
  },
  actions: {
    float: 'right',
  },
  largeButton: {
    float: 'left',
    width: '40%',
    marginRight: '5%',
  },
  fullWidth: {
    width: '100%',
  },
  cancelButton: {
    marginLeft: 'auto',
  },
  commentWidth: {
    width: '70%',
  },
  createCard: {
    marginBottom: '64px',
    paddingBottom: '17px',
  },
  toolbar: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    '& button:first-child': {
      marginRight: spacing.unit / 2,
    },
  },
});

const CancelButton = ({ handleCancelClick, className }) => (
  <Button
    className={className}
    onClick={handleCancelClick}
    label="common.cancel"
    size="medium"
    id="Selenium-Business-Create-Cancel-Button"
  >
    <IconCancel />
  </Button>
);

CancelButton.propTypes = {
  handleCancelClick: PropTypes.func,
  className: PropTypes.string,
};

const BusinessCreateToolbar = ({ isSubmitting, handleCancelClick, classes, invalid, ...props }) => (
  <Toolbar {...props}>
    <CancelButton className={classes.cancelButton} handleCancelClick={handleCancelClick} />
    <SaveButton saving={isSubmitting} disabled={invalid} id="Selenium-Business-Create-Save-Button" />
  </Toolbar>
);

BusinessCreateToolbar.propTypes = {
  isSubmitting: PropTypes.bool,
  handleCancelClick: PropTypes.func,
  classes: PropTypes.shape({}),
  invalid: PropTypes.bool,
};

const CustomDivider = ({ classes }) => <Divider className={classes.fullWidth} />;

CustomDivider.propTypes = {
  classes: PropTypes.shape({}),
};

class BusinessCreate extends React.PureComponent {
  useAsBillingAddress = false;

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(reset('business-create'));
  }

  handleCancelClick = () => {
    const { dispatch } = this.props;
    dispatch(push('/business'));
  };

  render() {
    const { dispatch, translate, classes, permissions, isSubmitting, dataProvider, ...props } = this.props;
    const { resource } = this.props;
    return (
      <Create classes={{ card: classes.createCard }} title=" " {...props}>
        <SimpleForm
          toolbar={
            <BusinessCreateToolbar
              className={classes.toolbar}
              handleCancelClick={this.handleCancelClick}
              classes={classes}
              isSubmitting={isSubmitting}
            />
          }
          form="business-create"
        >
          <BusinessDetails resource={resource} classes={classes} translate={translate} />
          <CustomDivider classes={{ fullWidth: classes.fullWidth }} />
          <BusinessAddress resource={resource} classes={classes} />
          <CustomDivider classes={{ fullWidth: classes.fullWidth }} />
          <SimpleShowLayout resource={resource} className={classes.fullWidth}>
            <LongTextInput
              source="comment"
              className={classes.commentWidth}
              validate={[
                maxLength(
                  255,
                  translate('validation.maxLength', {
                    max: 255,
                  })
                ),
              ]}
              id="Selenium-Business-Create-Comment-Input"
            />
          </SimpleShowLayout>
          <Typography style={{ width: '100%', marginLeft: '1rem' }}>{translate('validation.fillRequired')}</Typography>
        </SimpleForm>
      </Create>
    );
  }
}

BusinessCreate.propTypes = {
  dataProvider: PropTypes.func,
  isSubmitting: PropTypes.bool,
  dispatch: PropTypes.func,
  translate: PropTypes.func,
  isShowDialog: PropTypes.bool,
  permissions: PropTypes.string,
  resource: PropTypes.string,
  handleCloseDialog: PropTypes.func,
  record: PropTypes.shape({}),
  classes: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  isSubmitting: isSubmittingAction('business-create')(state),
});

export default withDataProvider(withStyles(createStyles)(translateHOC(connect(mapStateToProps)(BusinessCreate))));

// For tests
export const BusinessCreateTest = BusinessCreate;
