import { CREATE } from 'react-admin';

const dataCharge = (instance, type, params, config) => {
  const opt = {};
  switch (type) {
    case CREATE: {
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${params.data.entityId}/product`;
      opt.method = 'POST';
      opt.data = { amount: params.data.amount, productId: params.data.productId };
      break;
    }
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
  return instance(opt);
};

export default dataCharge;
