import React from 'react';
import PropTypes from 'prop-types';
import {
  showNotification,
  withDataProvider,
  translate as translateHOC,
  SimpleForm,
  Create,
  NumberInput,
  DateInput,
  Button,
  fetchStart,
  fetchEnd,
  CREATE,
  SaveButton,
} from 'react-admin';
import { compose } from 'redux';
import { submit, isSubmitting as isSubmittingAction, reset } from 'redux-form';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogTitle, withStyles } from '@material-ui/core';
import IconCancel from '@material-ui/icons/Cancel';
import { required, requiredNumber, future, positive } from '../../../core/validators';

const DummyToolbar = ({ invalid, forwardInvalid }) => {
  forwardInvalid(invalid);
  return null;
};

const styles = {
  elevation1: {
    boxShadow: 'none',
  },
  simpleForm: {
    '& > div': {
      padding: '0 !important',
    },
  },
  dialogActionsRoot: {
    marginRight: 20,
  },
};

class AddCreditsDialog extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isFormInvalid: true,
    };

    this.handleSaveClick = this.handleSaveClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(reset('credits-create'));
  }

  handleSaveClick() {
    const { dispatch } = this.props;
    dispatch(submit('credits-create'));
  }

  handleSubmit(record) {
    const { history, basePath, id, dispatch, handleCloseDialog, dataProvider } = this.props;
    dispatch(fetchStart());
    return dataProvider(CREATE, 'credits', { id: record.id, data: record })
      .then(() => {
        dispatch(showNotification('notification.addCreditsSuccess'));
        history.push(`${basePath}/${id}`);
        setTimeout(() => history.push(`${basePath}/${id}/credits`));
        handleCloseDialog();
      })
      .catch(err => {
        const errObject = JSON.parse(err.message);

        const { errorCode, httpStatusCode, httpStatus } = errObject;

        console.error(`Error: ${httpStatusCode}`, {
          httpStatusCode,
          httpStatus,
          errorCode,
        });
        dispatch(showNotification(`error.${errorCode}`, 'warning'));
      })
      .finally(dispatch(fetchEnd()));
  }

  render() {
    const {
      isSubmitting,
      translate,
      isShowDialog,
      handleCloseDialog,
      record,
      classes,
      dispatch,
      dataProvider,
      staticContext,
      ...props
    } = this.props;

    const { isFormInvalid } = this.state;

    return (
      <Dialog
        maxWidth="sm"
        fullWidth
        open={isShowDialog}
        onClose={() => {
          dispatch(reset('credits-create'));
          handleCloseDialog();
        }}
      >
        <DialogTitle>{translate('credits.create.title')}</DialogTitle>
        <DialogContent>
          <Create title=" " classes={{ card: classes.elevation1 }} resource="credits" {...props}>
            <SimpleForm
              className={classes.simpleForm}
              form="credits-create"
              onSubmit={this.handleSubmit}
              toolbar={<DummyToolbar forwardInvalid={s => this.setState({ isFormInvalid: s })} />}
            >
              <NumberInput
                step={1}
                label="credits.create.fields.amount"
                source="amount"
                required
                defaultValue="0"
                validate={[requiredNumber, positive]}
                id="Selenium-Business-Credits-Amount-Input"
              />
              <DateInput
                label="credits.create.fields.expiredDate"
                source="expirationDate"
                required
                validate={[required, future]}
                id="Selenium-Business-Credits-ExpirationDate-Input"
              />
            </SimpleForm>
          </Create>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActionsRoot }}>
          <Button
            label="common.cancel"
            onClick={() => {
              dispatch(reset('credits-create'));
              handleCloseDialog();
            }}
            size="medium"
            id="Selenium-Business-Credits-Cancel-Button"
          >
            <IconCancel />
          </Button>
          <SaveButton
            disabled={isFormInvalid}
            saving={isSubmitting}
            onClick={this.handleSaveClick}
            id="Selenium-Business-Credits-Save-Button"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

AddCreditsDialog.propTypes = {
  history: PropTypes.shape({}),
  basePath: PropTypes.string,
  id: PropTypes.string,
  dataProvider: PropTypes.func,
  classes: PropTypes.shape({}),
  record: PropTypes.shape({}),
  translate: PropTypes.func,
  promoteCode: PropTypes.string,
  dispatch: PropTypes.func,
  isSubmitting: PropTypes.bool,
  isShowDialog: PropTypes.bool,
  handleCloseDialog: PropTypes.func,
  staticContext: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  isSubmitting: isSubmittingAction('credits-create')(state),
});

export default compose(
  withDataProvider,
  withRouter,
  translateHOC,
  connect(mapStateToProps),
  withStyles(styles)
)(AddCreditsDialog);

// For Tests
export const AddCreditsDialogTest = AddCreditsDialog;
