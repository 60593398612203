import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import { changeLocale } from 'react-admin';

const LanguageButton = ({ languageLocale, languageName, currentLocale, dispatchChangeLocale }) => {
  return (
    <Button
      key={languageLocale}
      id={`Selenium-Preferences-SwitchLanguage-${languageLocale}-Button`}
      disabled={currentLocale === languageLocale}
      onClick={() => dispatchChangeLocale(languageLocale)}
    >
      {languageName}
    </Button>
  );
};
LanguageButton.propTypes = {
  languageLocale: PropTypes.string.isRequired,
  languageName: PropTypes.string.isRequired,
  currentLocale: PropTypes.string.isRequired,
  dispatchChangeLocale: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  currentLocale: state.i18n.locale,
});

const mapDispatchToProps = dispatch => ({
  dispatchChangeLocale: locale => dispatch(changeLocale(locale)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageButton);
