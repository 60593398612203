import React from 'react';
import PropTypes from 'prop-types';
import { translate as translateHOC } from 'react-admin';
import { BusinessFields } from '../../core/data-fields/data-business';
import CustomList from '../../shared/custom-list';

const BusinessList = ({ translate, ...others }) => (
  <CustomList
    {...others}
    defaultTab="Pending"
    sort={{ field: 'creationDate', order: 'DESC' }}
    fields={BusinessFields(translate)}
    filterColumn="status"
    defaultColumns={['name', 'legalName', 'businessOwner', 'creationDate', 'status']}
    filterTabs={[
      { id: 'Pending', name: 'resources.business.list.filterTabs.pending' },
      { id: 'Approved', name: 'resources.business.list.filterTabs.approved' },
      { id: 'Rejected', name: 'resources.business.list.filterTabs.rejected' },
      { id: 'Suspended', name: 'resources.business.list.filterTabs.suspended' },
      { id: 'Archived', name: 'resources.business.list.filterTabs.archived' },
      { id: 'All', name: 'resources.business.list.filterTabs.all' },
    ]}
  />
);
BusinessList.propTypes = {
  translate: PropTypes.func.isRequired,
};

export default translateHOC(BusinessList);
