import { CREATE } from 'react-admin';
import utils from '../../shared/utils';

const dataCredits = (instance, type, params, config) => {
  const { id } = utils.getParameters().business;
  const opt = {};
  switch (type) {
    case CREATE:
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/business/${id}/promoCodes/redeem/${
        params.data.promoteCode
      }`;
      opt.method = 'POST';
      break;
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
  return instance(opt);
};

export default dataCredits;
