import { GET_ONE } from 'react-admin';
import utils from '../../shared/utils';

const dataBillingBalance = (instance, type, params, config) => {
  const opt = {};
  switch (type) {
    case GET_ONE:
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/billing/entity/${params.id}/balance`;
      opt.method = 'GET';
      break;
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
  utils.hiddenLoadingScreen();
  return instance(opt);
};

export default dataBillingBalance;
