import { CREATE, GET_MANY, GET_LIST, GET_MANY_REFERENCE } from 'react-admin';

const dataCostCenter = (res, params, type) => {
  const data = res;
  switch (type) {
    case GET_MANY_REFERENCE: {
      data.data = res;
      data.total = res.length;
      break;
    }
    case GET_MANY:
    case GET_LIST:
      data.data = [{ id: null, name: ' ' }, ...res];
      data.total = res.length + 1;
      break;
    case CREATE:
      data.data = res.data;
      break;
    default:
      break;
  }
  return data;
};

export default dataCostCenter;
