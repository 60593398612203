import React, { useEffect, useState } from 'react';
import { Admin, Resource } from 'react-admin';
import { render } from 'react-dom';
import IconList from '@material-ui/icons/List';
import IconSettings from '@material-ui/icons/Settings';
import customRoutes from './core/custom-routes';
import authProvider from './core/auth-provider';
import dataProvider from './core/data-provider';
import businesses from './components/businesses';
import settings from './components/settings';
import Login from './components/login';
import reducers from './core/reducers';
import sagas from './core/sagas';
import VulogLayout from './core/layout';

// Icons
import { getBusinessConf } from './core/config';
import Dashboard from './components/dashboard';
import ChooseClient from './components/Popup/ChooseClient';
import utils from './shared/utils';
import { getTranslation } from './core/translations';
import Preferences from './components/preferences';
import { LocaleProvider } from './core/contexts/Locale';

import { addAlternateTranslations } from './shared/utils/translations';

require('dotenv').config();

window.document.addEventListener('DOMNodeInserted', ev => {
  if (ev && ev.srcElement && ev.srcElement.getElementsByTagName) {
    const allinput = ev.srcElement.getElementsByTagName('input');
    for (let i = 0; i < allinput.length; i += 1) {
      if (allinput[i].getAttribute('autocomplete') === 'off') {
        allinput[i].setAttribute('autocomplete', 'nope');
      }
    }
  }
});

const App = () => {
  const [clientUrl, setClientUrl] = useState('');
  const [config, setConfig] = useState(undefined);
  const [theme, setTheme] = useState(undefined);
  const [initialTranslation, setInitialTranslation] = useState(undefined);
  const [locale, setLocale] = useState(undefined);
  const [options, setOptions] = useState(undefined);

  useEffect(() => {
    if (!clientUrl) {
      setClientUrl(utils.getHostname());
      return;
    }

    // TODO: convert to saga
    getBusinessConf().then(async conf => {
      const defaultLocale = conf.config.translation.default;
      let alternateTranslations = [];
      try {
        alternateTranslations = JSON.parse(conf.config.alternateTranslations.overrides);
      } catch (e) {
        console.error('Error parsing alternate translations', e);
      }

      const sessionStorageLocale = sessionStorage.getItem('language');
      if (sessionStorageLocale) {
        setLocale(sessionStorageLocale);
      } else {
        sessionStorage.setItem('language', defaultLocale);
        setLocale(defaultLocale);
      }

      window.title = conf.config.companyName || 'Vulog';
      window.document.title = conf.config.companyName || 'Vulog';
      setConfig(conf.config);
      const defaultTranslations = await getTranslation(sessionStorageLocale || defaultLocale);

      if (Array.isArray(alternateTranslations) && alternateTranslations.length > 0) {
        addAlternateTranslations(defaultTranslations, alternateTranslations);
      }
      setInitialTranslation(defaultTranslations);

      setTheme(conf.theme);
      setOptions(true);
    });
  }, [clientUrl]);

  const handleClientSelect = newClientUrl => {
    setClientUrl(newClientUrl);
  };

  const changeTranslations = async newLocale => {
    let alternateTranslations = [];
    try {
      alternateTranslations = JSON.parse(config.alternateTranslations.overrides);
    } catch (e) {
      console.error('Error parsing alternate translations', e);
    }

    const newTranslations = await getTranslation(newLocale);
    if (Array.isArray(alternateTranslations) && alternateTranslations.length > 0) {
      addAlternateTranslations(newTranslations, alternateTranslations);
    }

    return newTranslations;
  };

  const i18nProvider = newLocale => (newLocale !== locale ? changeTranslations(newLocale) : initialTranslation);

  // TODO: Refactor <Resource /> to another file

  if (!clientUrl) {
    return <ChooseClient onClientSelect={handleClientSelect} />;
  }

  return (
    <>
      {config && theme && locale && initialTranslation && options ? (
        <LocaleProvider availableLocales={config.translation.availableLanguages}>
          <Admin
            authProvider={authProvider(config)}
            dataProvider={dataProvider(config)}
            i18nProvider={i18nProvider}
            customReducers={{ ...reducers }}
            customSagas={[...sagas]}
            appLayout={VulogLayout}
            loginPage={Login}
            locale={locale}
            theme={theme}
            config={config}
            customRoutes={customRoutes}
            dashboard={Dashboard}
          >
            {permissions => [
              permissions === 'admin' || permissions === 'user' ? (
                <Resource name="business" icon={IconList} options={{ label: 'menu.businesses' }} {...businesses} />
              ) : null,
              permissions === 'admin' || permissions === 'user' ? (
                <Resource name="paymentDetails" options={{ label: 'menu.paymentDetails' }} />
              ) : null,
              permissions === 'admin' || permissions === 'user' ? (
                <Resource name="adyen" options={{ label: 'menu.adyen' }} />
              ) : null,
              permissions === 'admin' || permissions === 'user' ? (
                <Resource name="billingGroup" options={{ label: 'menu.billingGroup' }} />
              ) : null,
              permissions === 'admin' || permissions === 'user' ? (
                <Resource name="businessOwner" options={{ label: 'menu.businessOwner' }} />
              ) : null,
              permissions === 'admin' || permissions === 'user' ? (
                <Resource name="documents" options={{ label: 'menu.documents' }} />
              ) : null,
              permissions === 'admin' || permissions === 'user' ? (
                <Resource name="businessUser" options={{ label: 'menu.users' }} />
              ) : null,
              permissions === 'admin' || permissions === 'user' ? (
                <Resource name="credits" options={{ label: 'menu.credits' }} />
              ) : null,
              permissions === 'admin' || permissions === 'user' ? (
                <Resource name="billing" options={{ label: 'menu.billing' }} />
              ) : null,
              permissions === 'admin' || permissions === 'user' ? (
                <Resource name="businessTrip" options={{ label: 'menu.businessTrip' }} />
              ) : null,
              permissions === 'admin' || permissions === 'user' ? (
                <Resource name="billingProducts" options={{ label: 'menu.billingProducts' }} />
              ) : null,
              permissions === 'admin' || permissions === 'user' ? (
                <Resource name="billingTrips" options={{ label: 'menu.billingTrips' }} />
              ) : null,
              permissions === 'admin' || permissions === 'user' ? (
                <Resource name="billingBalance" options={{ label: 'menu.billingBalance' }} />
              ) : null,
              permissions === 'admin' || permissions === 'user' ? (
                <Resource name="costCenter" options={{ label: 'menu.costCenter' }} />
              ) : null,
              <Resource name="product" />,
              <Resource name="charge" />,
              <Resource name="businessInvoice" />,
              permissions === 'admin' || permissions === 'user' ? <Resource name="invite" /> : null,
              permissions === 'admin' ? (
                <Resource name="settings" icon={IconSettings} options={{ label: 'menu.settings' }} {...settings} />
              ) : null,
              <Resource name="registration" />,
              <Resource name="user" />,
              <Resource name="grant" />,
              <Resource name="preferences" list={Preferences} />,
            ]}
          </Admin>
        </LocaleProvider>
      ) : null}
    </>
  );
};

render(<App />, document.getElementById('root'));
