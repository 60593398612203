import React from 'react';
import * as PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@material-ui/core';

const CancelDialog = ({ isOpen, toggleDialog, invoice, onCancel, translate }) => (
  <Dialog
    open={isOpen}
    onClose={toggleDialog}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth
    maxWidth="xs"
  >
    <DialogTitle id="alert-dialog-title">{translate('resources.invoice.cancel')}</DialogTitle>
    <DialogContent>
      <Typography>{translate('resources.invoice.cancelDialog.warning')}</Typography>
      <Typography style={{ marginBottom: 16, fontWeight: 'bold' }}>{`${invoice.amount} ${
        invoice.currency
      }?`}</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={toggleDialog} color="primary" id="cancelDialog-cancel-button">
        {translate('common.cancel')}
      </Button>
      <Button onClick={onCancel} color="primary" variant="contained" id="cancelDialog-refund-button" autoFocus>
        {translate('resources.invoice.confirm')}
      </Button>
    </DialogActions>
  </Dialog>
);

CancelDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleDialog: PropTypes.func.isRequired,
  invoice: PropTypes.shape({}).isRequired,
  onCancel: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
};

export default CancelDialog;
