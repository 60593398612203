import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
    maxWidth: 300,
  },
  input: {
    width: '100%',
  },
  emptyStatus: {
    position: 'relative',
    top: 20,
  },
  fullStatus: {
    top: 0,
    fontSize: '0.8rem',
    lineHeight: 1,
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MultipleSelect = ({ classes, inputLabel, value, onChange, onBlur, allOptions, id, renderValue }) => (
  <div className={classes.container} id={id}>
    <InputLabel
      className={typeof value === 'undefined' || value.length === 0 ? classes.emptyStatus : classes.fullStatus}
    >
      {inputLabel}
    </InputLabel>
    <Select
      multiple
      value={[...value]}
      onChange={onChange}
      onBlur={onBlur}
      input={<Input className={classes.input} />}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
          },
        },
      }}
      renderValue={renderValue}
    >
      {allOptions &&
        allOptions.map(({ value: val, label }, index) => {
          const isValueSelected = value.length > 0 && value.indexOf(val) > -1;

          return (
            <MenuItem
              id={`MenuItemListMultiple${index}`}
              key={val}
              value={val}
              onMouseEnter={() => {
                if (!isValueSelected) {
                  document.getElementById(`MenuItemListMultiple${index}`).style.backgroundColor = 'rgba(0, 0, 0, 0.09)';
                }
              }}
              onMouseLeave={() => {
                if (!isValueSelected) {
                  document.getElementById(`MenuItemListMultiple${index}`).style.backgroundColor = 'rgba(0, 0, 0, 0)';
                }
              }}
              style={
                isValueSelected ? { backgroundColor: 'rgba(0, 0, 0, 0.14)' } : { backgroundColor: 'rgba(0, 0, 0, 0)' }
              }
            >
              {label}
            </MenuItem>
          );
        })}
    </Select>
  </div>
);

MultipleSelect.defaultProps = {
  value: [],
};

MultipleSelect.propTypes = {
  inputLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  allOptions: PropTypes.arrayOf(PropTypes.shape({})),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
  ]),
  classes: PropTypes.shape({
    container: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
    emptyStatus: PropTypes.string.isRequired,
    fullStatus: PropTypes.string.isRequired,
  }).isRequired,
  theme: PropTypes.shape({}).isRequired,
  id: PropTypes.string,
  renderValue: PropTypes.func,
};

export default withStyles(styles, { withTheme: true })(MultipleSelect);
