import React from 'react';
import { fetchEnd, fetchStart, translate as translateHOC, withDataProvider } from 'react-admin';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles, Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';
import { getBusinessConf } from '../../core/config';
import Stripe from './stripe';
import { START } from './stripe/Constants';
import PaymentMethodPopupActions from './PaymentMethodPopupActions';
import { paymentDetailsSelector } from '../../core/redux/paymentDetails/selectors';
import { setupAdyenIntentAction, updateAdyenMopResultAction } from '../../core/redux/adyen/actions';
import { updateAdyenMopResultSelector } from '../../core/redux/adyen/selectors';
import { adyenSetupIntentsSynchronize, getPublishableKey } from '../../core/sagas/adyenSaga';
import PaymentMethodResult from './PaymentMethodResult';
import { getClientSecret, stripeSynchronize } from '../../core/redux/stripe/actions';

const AdyenInstanceProps = {
  finishedUpdating: PropTypes.bool,
  newMop: PropTypes.shape({}),
  status: PropTypes.string,
  declineCode: PropTypes.string,
  reason: PropTypes.string,
  isOpen: PropTypes.bool,
  triggered3DS: PropTypes.bool,
};

const AdyenInstance = ({ finishedUpdating, newMop, status, declineCode, reason, isOpen, triggered3DS }) => {
  const PaymentMethodResultProps = {
    finishedUpdating,
    newMop,
    status,
    declineCode,
    reason,
    isOpen,
    triggered3DS,
  };

  if (isOpen) {
    return <PaymentMethodResult {...PaymentMethodResultProps} />;
  }

  return <div id="Selenium-paymentDetails-Edit-paymentServer-Div" />;
};

AdyenInstance.propTypes = AdyenInstanceProps;

const styles = {
  resultHeaderFailed: {
    color: '#C92429',
    fontWeight: '500',
  },
  resultHeaderSuccess: {
    color: '#30C94A',
    fontWeight: '500',
  },
};

class PaymentDetailsEdit extends React.PureComponent {
  constructor(props) {
    super(props);
    this.calledOnce = false;

    this.state = {
      isPaymentSubmitted: false,
      step: START,
      selectedPaymentMethod: null,
      isAdyenPaymentResultOpen: false,
    };

    const { config } = getBusinessConf();
    this.paymentProvider = config && config.paymentProvider;
  }

  componentDidMount() {
    if (this.paymentProvider !== 'stripe') {
      const setupIntentId = sessionStorage.getItem('setupIntentId');

      if (setupIntentId) {
        const { location } = this.props;

        const params = new URLSearchParams(location.search);
        const redirectResult = params.get('redirectResult');

        this.onAdyen3DsReturn(setupIntentId, redirectResult);
      }
    }
  }

  componentDidUpdate() {
    const { isPaymentSubmitted } = this.state;
    const { isShowDialog } = this.props;

    if (isShowDialog && !isPaymentSubmitted) {
      if (this.paymentProvider !== 'stripe' && !this.calledOnce) {
        this.calledOnce = true;
        this.initAdyen();
      }
    }

    if (!isShowDialog && this.calledOnce) {
      this.calledOnce = false;
    }
  }

  setStep = value => {
    this.setState({ step: value });
  };

  setSelectedPaymentMethod = value => {
    this.setState({ selectedPaymentMethod: value });
  };

  getPaymentProvider = () => {
    const { config } = getBusinessConf();
    return config && config.paymentProvider;
  };

  getStripeConf = () => getBusinessConf() && getBusinessConf().config && getBusinessConf().config.stripe;

  openAdyenPaymentResult = () => this.setState(() => ({ isAdyenPaymentResultOpen: true }));

  closeAdyenPaymentResult = () => this.setState(() => ({ isAdyenPaymentResultOpen: false }));

  onSubmit = async ({ isValid: isCardValid, data: payload }) => {
    const { onSetupIntentAdyen } = this.props;
    try {
      if (isCardValid) {
        await onSetupIntentAdyen(payload);
        this.openAdyenPaymentResult();
      }
      this.setState({ isPaymentSubmitted: true });
    } catch (error) {
      console.error(error);
    }
  };

  initAdyen = async () => {
    const { locale, dispatch } = this.props;
    const { onSubmit } = this;

    dispatch(fetchStart());

    try {
      const publishableKey = await getPublishableKey();

      const config = {
        locale,
        environment: getBusinessConf().config.adyen.context,
        analytics: {
          enabled: false,
        },
        clientKey: publishableKey,
        showPayButton: true,
        onError: (error, component) => {
          console.error(error.name, error.message, error.stack, component);
        },
        onSubmit,
        onPaymentCompleted: (result, component) => {
          console.log(result, component);
        },
        paymentMethodsConfiguration: {
          card: {
            hasHolderName: true,
            holderNameRequired: true,
          },
        },
      };

      const checkout = await AdyenCheckout(config);

      checkout.create('card').mount('#Selenium-paymentDetails-Edit-paymentServer-Div');
      dispatch(fetchEnd());
    } catch (error) {
      dispatch(fetchEnd());
      console.error(error);
    }
  };

  onAdyen3DsReturn = async (setupIntentId, redirectResult) => {
    const { dispatch, paymentDetails } = this.props;

    this.openAdyenPaymentResult();
    try {
      const { status } = await adyenSetupIntentsSynchronize(setupIntentId, redirectResult);
      sessionStorage.removeItem('setupIntentId');

      dispatch(
        updateAdyenMopResultAction({
          finishedUpdating: true,
          newMop: paymentDetails,
          status,
          triggered3DS: true,
        })
      );
      this.setState({ isPaymentSubmitted: true });
    } catch (error) {
      console.error(error);
    }
  };

  onSubmitStripe = async () => {
    const { onSetupIntentStripe } = this.props;
    try {
      await onSetupIntentStripe();
    } catch (error) {
      console.error(error);
    }
  };

  resetStripeFlow = () => {
    this.setState({ selectedPaymentMethod: null, step: START });
  };

  render() {
    const {
      handleCancelDialog,
      handleCloseDialog,
      isShowDialog,
      translate,
      dispatch,
      setMopUpdating,
      updateAdyenMopResult,
      onSynchronizeStripe,
    } = this.props;
    const { isPaymentSubmitted, step, selectedPaymentMethod, isAdyenPaymentResultOpen } = this.state;
    const { finishedUpdating } = updateAdyenMopResult;

    return (
      <Dialog
        maxWidth="md"
        fullWidth
        open={isShowDialog || isAdyenPaymentResultOpen}
        onBackdropClick={handleCloseDialog}
      >
        <DialogTitle id="Selenium-paymentDetails-Edit-dialogTitle">
          {translate(
            finishedUpdating
              ? 'resources.paymentDetails.paymentResultTitle'
              : 'resources.paymentDetails.edit.titlePaymentDetailsDialog'
          )}
        </DialogTitle>
        <DialogContent id="Selenium-paymentDetails-Edit-dialogContent">
          {this.paymentProvider === 'stripe' ? (
            <Stripe
              isPostalCodeMandatory={this.getStripeConf().isPostalCodeMandatory}
              translate={translate}
              handleCloseDialog={handleCloseDialog}
              dispatch={dispatch}
              setMopUpdating={setMopUpdating}
              step={step}
              setStep={this.setStep}
              selectedPaymentMethod={selectedPaymentMethod}
              setSelectedPaymentMethod={this.setSelectedPaymentMethod}
              resetStripeFlow={this.resetStripeFlow}
              onSubmit={this.onSubmitStripe}
              synchronizeMOP={onSynchronizeStripe}
            />
          ) : (
            <AdyenInstance {...updateAdyenMopResult} isOpen={isAdyenPaymentResultOpen} />
          )}
        </DialogContent>
        <PaymentMethodPopupActions
          isPaymentSubmitted={isPaymentSubmitted}
          closeDialog={handleCloseDialog}
          cancelDialog={handleCancelDialog}
          paymentProvider={this.paymentProvider}
          step={step}
          setStep={this.setStep}
          selectedPaymentMethod={selectedPaymentMethod}
        />
      </Dialog>
    );
  }
}

const mapActionToProps = dispatch => ({
  onSetupIntentAdyen: payload => dispatch(setupAdyenIntentAction(payload)),
  onSetupIntentStripe: () => dispatch(getClientSecret({ paymentMethodType: 'CREDIT_CARD' })),
  onSynchronizeStripe: () => dispatch(stripeSynchronize()),
});

const mapStateToProps = state => ({
  paymentDetails: paymentDetailsSelector(state),
  locale: state.i18n.locale,
  updateAdyenMopResult: updateAdyenMopResultSelector(state),
});

PaymentDetailsEdit.propTypes = {
  classes: PropTypes.shape({}),
  dispatch: PropTypes.func,
  handleCancelDialog: PropTypes.func,
  handleCloseDialog: PropTypes.func,
  id: PropTypes.string,
  isShowDialog: PropTypes.bool,
  translate: PropTypes.func,
  setMopUpdating: PropTypes.func.isRequired,
  locale: PropTypes.string,
  onSetupIntentAdyen: PropTypes.func.isRequired,
  updateAdyenMopResult: PropTypes.shape({}),
  location: PropTypes.shape({}),
  paymentDetails: PropTypes.shape({}),
  onSetupIntentStripe: PropTypes.func.isRequired,
  onSynchronizeStripe: PropTypes.func.isRequired,
};

export default compose(
  withDataProvider,
  withRouter,
  connect(
    mapStateToProps,
    mapActionToProps
  ),
  withDataProvider,
  translateHOC,
  withStyles(styles)
)(PaymentDetailsEdit);
