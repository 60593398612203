import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, Grid } from '@material-ui/core';
import { AppBar as RaAppBar } from 'react-admin';
import SearchControl from '../components/search/SearchControl';
import VulogUserMenu from './VulogUserMenu';
import { getBusinessConf } from './config';

const searchAppBarStyles = {
  title: {
    fontSize: '1.3125rem',
    fontWeight: 500,
    flex: 10,
  },
  logoContainer: {
    flex: 1,
  },
  logo: {
    height: '31px',
    marginRight: '1rem',
  },
  button: {
    '& button': {
      marginLeft: '4px',
    },
  },
};

const AppBar = ({ classes, ...other }) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const { config } = getBusinessConf();

  useEffect(() => {
    setIsAdmin(localStorage.getItem('role') === 'admin');
  }, []);

  const gridClasses = isAdmin ? {} : { item: classes.logoContainer };

  return (
    <RaAppBar className={classes.button} {...other} userMenu={<VulogUserMenu />}>
      <Grid item classes={gridClasses}>
        <img className={classes.logo} src={config && config.logoUrl} alt="Logo" />
      </Grid>
      {isAdmin && (
        <>
          <Typography color="inherit" className={classes.title} id="react-admin-title" />
          <SearchControl {...other} />
        </>
      )}
    </RaAppBar>
  );
};
AppBar.propTypes = {
  classes: PropTypes.shape({
    title: PropTypes.string.isRequired,
    logo: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(searchAppBarStyles)(AppBar);
