import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import utils from '../utils';
import { formatDateMultiTextField } from '../utils/date';

const MultiTextField = ({
  className = '',
  classes = {},
  record = {},
  sources = [],
  separator = ' ',
  icon,
  customLabel,
  type = 'text',
  roundingRule = 2,
  id,
}) => {
  let combinedText = '';
  if (type === 'custom') {
    combinedText = sources;
  } else {
    for (let i = 0; i < sources.length; i += 1) {
      const field = sources[i];
      let text = utils.index(record, field);
      if (text || text === 0) {
        switch (type) {
          case 'datetime':
            text = formatDateMultiTextField(text);
            break;
          case 'number':
            // Only process the input if it's a number. Do nothing otherwise.
            if (!Number.isNaN(Number(text))) {
              text = parseFloat(text).toFixed(roundingRule);
            }
            break;
          default:
            break;
        }
        combinedText += text;
      } else {
        combinedText += field;
      }
      if (i < sources.length - 1) {
        combinedText += separator;
      }
    }
  }
  return (
    <div className={className} id={`Selenium-multiField-${id}`}>
      <Typography classes={classes} component="span">
        <div className={classes.text}>
          {icon ? <span className={classes.icon}>{icon}&nbsp;</span> : null}
          <span>{combinedText}</span>
        </div>
      </Typography>
      {customLabel ? <div className={classes.label}>{customLabel}</div> : null}
    </div>
  );
};

MultiTextField.propTypes = {
  type: PropTypes.string,
  roundingRule: PropTypes.number,
  customLabel: PropTypes.string,
  icon: PropTypes.shape({}),
  className: PropTypes.string,
  classes: PropTypes.shape({}),
  record: PropTypes.shape({}),
  sources: PropTypes.arrayOf(PropTypes.string),
  separator: PropTypes.string,
  id: PropTypes.string,
};

export default MultiTextField;
