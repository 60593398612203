import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import { MuiThemeProvider, withStyles, createStyles, createMuiTheme } from '@material-ui/core/styles';
import { withDataProvider } from 'react-admin';

import { getBusinessConf } from '../../core/config';
import Notification from '../../shared/notification';
import LoginForm from '../../shared/loginform';
import utils from '../../shared/utils';

const styles = theme =>
  createStyles({
    main: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      height: '1px',
      alignItems: 'center',
      justifyContent: 'flex-start',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      position: 'fixed',
      top: '0',
      left: '0',
      width: '100vw',
    },
    card: {
      minWidth: 300,
      marginTop: '6em',
    },
    avatar: {
      margin: '1em',
      display: 'flex',
      justifyContent: 'center',
    },
    logo: {
      height: '180px',
    },
    icon: {
      backgroundColor: theme.palette.secondary.main,
    },
  });

const sanitizeRestProps = ({
  array,
  backgroundImage,
  classes,
  className,
  location,
  staticContext,
  theme,
  title,
  ...rest
}) => rest;
class Login extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    // connecting with react admin store
    const { dispatch } = props;
    utils.setStore(dispatch);
    const { config } = getBusinessConf();
    const loginInfo = config.login;
    this.reCAPTCHAsiteKey = () => {
      if (loginInfo && loginInfo.reCAPTCHAsiteKey) {
        return process.env.NODE_ENV === 'development'
          ? '6LcDgOUUAAAAAMfJPNZV5fRhf5DsUU2KcV9TvXZH'
          : loginInfo.reCAPTCHAsiteKey;
      }
      return '';
    };
    this.pincodeLength = loginInfo && loginInfo.pincodeLength;
  }

  componentDidMount() {
    if (this.reCAPTCHAsiteKey()) {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${this.reCAPTCHAsiteKey()}`;
      script.async = true;
      document.body.appendChild(script);
    }
  }

  render() {
    const { classes, className, theme, ...rest } = this.props;
    const themeDefault = createMuiTheme(theme);
    return (
      <MuiThemeProvider theme={themeDefault}>
        <div className={classnames(classes.main, className)} {...sanitizeRestProps(rest)} ref={this.containerRef}>
          <Card className={classes.card}>
            <div className={classes.avatar}>
              <img
                src={getBusinessConf().config && getBusinessConf().config.logoUrl}
                className={classes.logo}
                alt="Logo"
              />
            </div>
            <LoginForm pincodeLength={this.pincodeLength} />
          </Card>
          <Notification />
        </div>
      </MuiThemeProvider>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.shape({}),
  className: PropTypes.string,
  input: PropTypes.shape({}),
  loginForm: PropTypes.element,
  meta: PropTypes.shape({}),
  theme: PropTypes.shape({}),
  previousRoute: PropTypes.string,
  dispatch: PropTypes.func,
};

export default withDataProvider(withStyles(styles)(Login));
