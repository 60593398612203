import React from 'react';
import { Button, translate as translateHOC, withDataProvider } from 'react-admin';
import { DialogContent, DialogActions, Typography } from '@material-ui/core';
import IconSave from '@material-ui/icons/Save';
import IconCancel from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';

const ConfirmArchiveDialog = ({ translate, onSave, onCancel }) => (
  <>
    <DialogContent id="Selenium-Users-Archive-form">
      <Typography>{translate('resources.businessUser.edit.confirmInactive')}</Typography>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} label="common.cancel" size="medium" id="Selenium-Users-Edit-Button-Cancel">
        <IconCancel />
      </Button>
      <Button
        variant="raised"
        onClick={onSave}
        label="common.confirm"
        size="medium"
        disabled={false}
        id="Selenium-Users-Archive-Button-Save"
      >
        {<IconSave />}
      </Button>
    </DialogActions>
  </>
);

ConfirmArchiveDialog.propTypes = {
  translate: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

export default withDataProvider(translateHOC(ConfirmArchiveDialog));
