import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { MenuItemLink, getResources, Responsive, translate as translateHOC } from 'react-admin';
import { withRouter } from 'react-router-dom';
import utils from '../shared/utils';

// TODO: Deprecated since we do not use the left menu for handling business details.
// This can be removed in the future in case we dont use it.
// The custom menu can still be useful in case we need to manually show/hide the menu.
class Menu extends React.PureComponent {
  constructor(props) {
    super(props);
    const { dispatch } = props;
    utils.setStore(dispatch);
  }

  render() {
    const { translate, resources, onMenuClick, logout } = this.props;
    return (
      <div>
        {resources.map((resource, index) =>
          resource.icon ? (
            <MenuItemLink
              id={`Selenium-MainPage-MenuItem-${resource.name}`}
              to={`/${resource.name}`}
              primaryText={resource.options.label ? translate(resource.options.label) : translate(resource.name)}
              onClick={onMenuClick}
              leftIcon={React.createElement(resource.icon)}
              key={index}
            />
          ) : null
        )}
        <Responsive small={logout} medium={null} />
      </div>
    );
  }
}

Menu.propTypes = {
  dispatch: PropTypes.func,
  resources: PropTypes.arrayOf(PropTypes.shape({})),
  onMenuClick: PropTypes.func,
  translate: PropTypes.func,
  logout: PropTypes.shape({}),
};

const mapStateToProps = state => ({
  menu: state.menu,
  resources: getResources(state),
});

const MenuHOC = translateHOC(withRouter(connect(mapStateToProps)(Menu)));

export default MenuHOC;
