import { takeLatest, put } from 'redux-saga/effects';
import { getFleetConf } from '../config';
import { setFleetConfig } from '../redux/config/actions';
import { GET_FLEET_CONFIG } from '../redux/config/constants';

function* fleetConfigRequest() {
  try {
    const fleetConf = yield getFleetConf();
    yield put(setFleetConfig(fleetConf));
  } catch (error) {
    console.error('[SAGA][fleetConfigRequest]', error);
  }
}

export default function* localeSaga() {
  yield takeLatest(GET_FLEET_CONFIG, fleetConfigRequest);
}
