import { GET_ONE } from 'react-admin';
import utils from '../../shared/utils';

const dataBusinessTrip = (res, params, type) => {
  switch (type) {
    case GET_ONE: {
      const { tripDetailStatus, distanceUnit } = utils.getParameters().businessTrip;
      res.data.id = res.data.tripId;
      res.data.status = tripDetailStatus;
      res.data.distanceUnit = distanceUnit;
      break;
    }
    default:
      break;
  }
  return res;
};

export default dataBusinessTrip;
