import { GET_LIST, GET_MANY } from 'react-admin';

const dataBillingGroup = (res, params, type) => {
  let data = res;
  switch (type) {
    case GET_MANY:
    case GET_LIST:
      data = {
        data: [{ id: null, name: ' ' }, ...res],
        total: res.length + 1,
      };
      break;
    default:
      break;
  }
  return data;
};

export default dataBillingGroup;
