import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Show, TabbedShowLayout, TabbedShowLayoutTabs, Tab, withDataProvider } from 'react-admin';
import IconBusinessDetails from '@material-ui/icons/Domain';
import IconUser from '@material-ui/icons/AccountBox';
import IconPaymentDetails from '@material-ui/icons/CreditCard';
import IconBilling from '@material-ui/icons/Receipt';
import IconTrip from '@material-ui/icons/TrendingUp';
import IconCredits from '@material-ui/icons/CardGiftcard';
import IconCostCenter from '@material-ui/icons/CenterFocusStrong';
import { withStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router';
import BusinessShow from './show';
import CreditsList from '../credits/list';
import { CashMultiple } from '../../shared/icons';
import BillingList from '../billing/list';
import InvoiceList from '../invoice/list';
import PaymentDetailsShow from '../payment-details/payment-details-show';
import TripsList from '../trips';
import UsersList from '../users/list';
import CostCenterList from '../cost-center/list';
import utils from '../../shared/utils';
import withGrants from '../WithGrants';
import BusinessTabIcon from './BusinessTabIcon';
import { paymentDetailsSelector } from '../../core/redux/paymentDetails/selectors';
import { getPaymentDetails } from '../../core/redux/paymentDetails/actions';

const showStyles = ({ spacing }) => ({
  suggestionsContainerOpen: {
    zIndex: 2100,
  },
  fieldWithoutHeight: {
    float: 'left',
    width: '28%',
    marginRight: '5%',
  },
  field: {
    float: 'left',
    width: '31%',
    minHeight: '60px',
    marginRight: '2%',
  },
  fieldTwoThree: {
    float: 'left',
    width: '64%',
    minHeight: '60px',
    marginRight: '2%',
  },
  fieldOverflow: {
    float: 'left',
    minWidth: '31%',
    overflow: 'hidden',
    wordWrap: 'break-word',
    minHeight: '60px',
    marginRight: '2%',
  },
  fieldEdit: {
    float: 'left',
    width: '28%',
    minWidth: '200px',
    minHeight: '65px',
    marginRight: '5%',
  },
  autoCompleteContainer: {
    position: 'fixed',
    zIndex: '100000',
  },
  fieldContent: {
    width: '100%',
    marginRight: '0',
  },
  block: {
    width: 'fit-content',
  },
  actions: {
    float: 'right',
  },
  largeButton: {
    float: 'left',
    width: '40%',
    marginRight: '5%',
  },
  commentWidth: {
    width: '88%',
  },
  fullWidth: {
    width: '100%',
  },
  rowContent: {
    width: '100%',
    float: 'left',
  },
  inviteButton: {
    width: '280px',
    float: 'left',
  },
  bulkInviteButton: {
    width: '280px',
    float: 'left',
  },
  sampleCSVButton: {
    width: '100px',
  },
  saveButton: {
    marginLeft: 'auto',
  },
  tabRoot: {
    minWidth: 10,
    textAlign: 'center',
  },
  mainSpan: {
    textAlign: 'left',
  },
  chipsArrayRoot: {
    width: 'auto',
    display: 'inline-flex',
    marginTop: spacing.unit * 2,
    marginBottom: spacing.unit,
  },
  chipsArrayContainer: {
    marginTop: spacing.unit,
    marginBottom: spacing.unit / 2,
    alignItems: 'baseline',
  },
  privateFleetsContainer: {
    marginTop: spacing.unit * 2,
    marginBottom: spacing.unit,
  },
});

const BusinessDetailsMenu = ({
  classes,
  hasCreate,
  hasEdit,
  hasShow,
  hasList,
  checkPermission,
  paymentDetails,
  dispatch,
  ...props
}) => {
  const {
    id,
    location: { pathname },
  } = props;

  const [isMOPValid, setIsMOPValid] = useState(true);

  const { mopStatus } = paymentDetails;

  const checkIfMOPIsValid = () => {
    if (mopStatus !== undefined && mopStatus === 'MOP_VALID') {
      setIsMOPValid(true);
      return true;
    }
    setIsMOPValid(false);
    return false;
  };

  useEffect(() => {
    dispatch(getPaymentDetails());
  }, []);

  useEffect(() => {
    checkIfMOPIsValid();
  }, [mopStatus]);
  const { basePath, ...rest } = props;

  const role = utils.getRoles(id);
  utils.setParameters({ business: { id } });

  if (role === 'customer') return <Redirect to="/access-denied" />;
  return (
    <Show {...props} title=" " actions={null}>
      <TabbedShowLayout {...props} tabs={<TabbedShowLayoutTabs fullWidth />}>
        <Tab className={classes.tabRoot} label="menu.businessDetails" icon={<IconBusinessDetails />}>
          <BusinessShow classes={classes} {...props} className={classes.mainSpan} />
        </Tab>
        {checkPermission('VIEW_PAYMENT_DETAILS') && (
          <Tab
            className={classes.tabRoot}
            label="menu.paymentDetails"
            path="paymentDetails"
            icon={<BusinessTabIcon icon={<IconPaymentDetails />} isMOPValid={isMOPValid} />}
          >
            <PaymentDetailsShow {...props} className={classes.mainSpan} checkIfMOPIsValid={checkIfMOPIsValid} />
          </Tab>
        )}
        <Tab className={classes.tabRoot} label="menu.users" path="users" icon={<IconUser />}>
          <UsersList
            basePath={pathname}
            hasCreate={false}
            hasEdit
            hasShow={false}
            hasList
            {...rest}
            className={classes.mainSpan}
          />
        </Tab>
        <Tab className={classes.tabRoot} label="menu.trips" path="trips" icon={<IconTrip />}>
          <TripsList {...props} className={classes.mainSpan} />
        </Tab>
        {checkPermission('VIEW_BUSINESS_BILLING') && (
          <Tab className={classes.tabRoot} label="menu.billing" path="billing" icon={<IconBilling />}>
            <BillingList {...props} className={classes.mainSpan} />
          </Tab>
        )}
        {checkPermission('VIEW_BUSINESS_BILLING') && (
          <Tab className={classes.tabRoot} label="menu.invoice" path="invoice" icon={<CashMultiple />}>
            <InvoiceList {...props} className={classes.mainSpan} />
          </Tab>
        )}
        {checkPermission('VIEW_BUSINESS_CREDIT') && (
          <Tab className={classes.tabRoot} label="menu.credits" path="credits" icon={<IconCredits />}>
            <CreditsList {...props} className={classes.mainSpan} />
          </Tab>
        )}
        <Tab className={classes.tabRoot} label="menu.costCenter" path="cost-center" icon={<IconCostCenter />}>
          <CostCenterList
            basePath={pathname}
            hasCreate
            hasEdit
            hasShow={false}
            hasList
            {...rest}
            className={classes.mainSpan}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

BusinessDetailsMenu.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.shape({}),
  location: PropTypes.shape({ pathname: PropTypes.string }),
  hasCreate: PropTypes.bool,
  hasEdit: PropTypes.bool,
  hasShow: PropTypes.bool,
  hasList: PropTypes.bool,
  id: PropTypes.string,
  checkPermission: PropTypes.func,
  paymentDetails: PropTypes.shape({}),
  dispatch: PropTypes.func,
};

class BusinessDetails extends React.PureComponent {
  render() {
    const { dataProvider, ...props } = this.props;

    return <BusinessDetailsMenu {...props} />;
  }
}

BusinessDetails.propTypes = {
  dataProvider: PropTypes.func,
};

const mapStateToProps = state => ({
  paymentDetails: paymentDetailsSelector(state),
});

export default connect(mapStateToProps)(withGrants(withDataProvider(withStyles(showStyles)(BusinessDetails))));
