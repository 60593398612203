import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import {
  translate as translateHOC,
  SimpleForm,
  Create,
  TextInput,
  ReferenceInput,
  SelectInput,
  Button,
  SaveButton,
} from 'react-admin';
import { submit, reset, isSubmitting as isSubmittingAction } from 'redux-form';
import { connect } from 'react-redux';
import { Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, withStyles } from '@material-ui/core';
import IconSend from '@material-ui/icons/Send';
import IconCancel from '@material-ui/icons/Cancel';
import { required, humanName, email } from '../../../core/validators';

const styles = {
  elevation1: {
    boxShadow: 'none',
  },
  simpleForm: {
    '& > div': {
      padding: '0 !important',
    },
  },
  dialogActionsRoot: {
    marginRight: 20,
  },
};

class InviteCreate extends React.PureComponent {
  componentWillMount() {
    const { dispatch } = this.props;
    dispatch(reset('invite-create'));
  }

  handleSaveClick = () => {
    const { dispatch } = this.props;
    dispatch(submit('invite-create'));
  };

  render() {
    const {
      translate,
      open,
      onClose,
      record,
      classes,
      isSubmitting,
      dataProvider,
      dispatch,
      onSubmit,
      checkPermission,
      crudUpdateMany,
      lang,
      locales,
      onSelectLanguage,
      ...props
    } = this.props;
    return (
      <>
        <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
          <DialogTitle id="Selenium-Users-inviteCreate-dialogTitle">
            {translate('resources.invite.create.title')}
          </DialogTitle>
          <DialogContent>
            <Create title=" " {...props} classes={{ card: classes.elevation1 }} resource="invite">
              <SimpleForm form="invite-create" className={classes.simpleForm} onSubmit={onSubmit} toolbar={null}>
                <TextInput
                  source="firstName"
                  validate={[required, humanName]}
                  required
                  className={classes.fullWidth}
                  id="Selenium-Users-inviteCreate-dialogContent-Input-firstName"
                />
                <TextInput
                  source="lastName"
                  validate={[required, humanName]}
                  required
                  className={classes.fullWidth}
                  id="Selenium-Users-inviteCreate-dialogContent-Input-lastName"
                />
                <TextInput
                  source="email"
                  validate={[required, email]}
                  required
                  className={classes.fullWidth}
                  id="Selenium-Users-inviteCreate-dialogContent-Input-Email"
                />
                <ReferenceInput
                  label="resources.costCenter.fields.costCenterName"
                  source="costCenterId"
                  reference="costCenter"
                  className={classes.fullWidth}
                  id="Selenium-Users-inviteCreate-dialogContent-Input-costCenterName"
                >
                  <SelectInput
                    optionText="name"
                    className={classes.fullWidth}
                    id="Selenium-Users-inviteCreate-dialogContent-Input-Name"
                  />
                </ReferenceInput>
                <TextInput
                  id="lang"
                  select
                  source={translate('languages.language')}
                  defaultValue={lang}
                  onChange={e => onSelectLanguage(e.target.value)}
                >
                  {locales.map(option => (
                    <MenuItem key={option.code} value={option.code}>
                      {translate(`languages.${option.label}`)}
                    </MenuItem>
                  ))}
                </TextInput>
              </SimpleForm>
            </Create>
          </DialogContent>
          <DialogActions classes={{ root: classes.dialogActionsRoot }}>
            <Button
              label="common.cancel"
              onClick={onClose}
              size="medium"
              id="Selenium-Users-inviteCreate-dialogContent-Button-Cancel"
            >
              <IconCancel />
            </Button>
            <SaveButton
              saving={isSubmitting}
              onClick={this.handleSaveClick}
              label="common.send"
              icon={<IconSend />}
              id="Selenium-Users-inviteCreate-dialogContent-Button-Save"
            />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

InviteCreate.propTypes = {
  id: PropTypes.string,
  dataProvider: PropTypes.func,
  classes: PropTypes.shape({
    fullWidth: PropTypes.string,
  }),
  record: PropTypes.shape({}),
  translate: PropTypes.func,
  promoteCode: PropTypes.string,
  dispatch: PropTypes.func,
  isSubmitting: PropTypes.bool,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  lang: PropTypes.string,
  locales: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  onSelectLanguage: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isSubmitting: isSubmittingAction('invite-create')(state),
});

export default compose(
  translateHOC,
  connect(mapStateToProps),
  withStyles(styles)
)(InviteCreate);
// For Tests
export const InviteCreateTest = InviteCreate;
