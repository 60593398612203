import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  redDotWrapper: {
    position: 'relative',
  },
  redDotAlert: {
    position: 'absolute',
    top: 0,
    right: '-1px',
    display: 'block',
    backgroundColor: '#d0021b',
    borderRadius: '50%',
    width: 8,
    height: 8,
  },
};

const BusinessTabIcon = ({ icon, classes: { redDotWrapper, redDotAlert }, isMOPValid }) => {
  const [showRedDot, setShowRedDot] = useState(false);

  useEffect(() => {
    const delayedCheck = setTimeout(() => (isMOPValid ? setShowRedDot(false) : setShowRedDot(true)), 500);

    return () => clearTimeout(delayedCheck);
  }, [isMOPValid]);

  if (showRedDot) {
    return (
      <div className={redDotWrapper}>
        <span className={redDotAlert} />
        {icon}
      </div>
    );
  }

  return <>{icon}</>;
};

BusinessTabIcon.propTypes = {
  icon: PropTypes.shape({}),
  isMOPValid: PropTypes.bool,
  classes: PropTypes.shape({
    redDotWrapper: PropTypes.string.isRequired,
    redDotAlert: PropTypes.string.isRequired,
  }),
};

export default withStyles(styles)(BusinessTabIcon);
