import { GET_LIST, UPDATE_MANY } from 'react-admin';

const dataAdminSettings = (instance, type, params, config) => {
  const opt = {};
  switch (type) {
    case UPDATE_MANY: {
      // This is used to set the role on or off for a particular grant
      switch (params.data.role.value) {
        case true:
          opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/setting/role/${params.data.role.id}/grant/${
            params.ids[0]
            }`;
          opt.method = 'POST';
          break;
        default:
          opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/setting/role/${params.data.role.id}/grant/${
            params.ids[0]
            }`;
          opt.method = 'DELETE';
          break;
      }
      return instance(opt);
    }
    case GET_LIST:
      // Get list of roles
      opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/setting/roles`;
      opt.method = 'GET';
      return instance(opt).then(roleResult => {
        // Get list of grants
        opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/setting/grants`;
        return instance(opt).then(grantResult => {
          // Get list of grants and roles
          opt.url = `/business/fleets/${localStorage.getItem('fleetID')}/setting/role/grant`;
          return instance(opt).then(res => {
            const data = {};
            data.roles = roleResult.data;
            data.grants = grantResult.data;
            data.result = res.data;
            return Object.assign(res, { data });
          });
        });
      });
    default:
      throw new Error(`Unsupported Data Provider request type ${type}`);
  }
};

export default dataAdminSettings;
